import React from "react";

const Section3 = () =>{

return(


  <div className="section3why" >

    <h2>We’re Just A Click Or A Call Away:</h2>
    <div className="section3info">
        <p>
        1-877-331-4255
        </p>
        <p>support@surecell.com</p>
    </div>
  </div>

)

}

export default Section3