import React, { useState, useEffect } from 'react';
import Footer from '../../footer/Footer';
import Navbar from '../../Navbar';
import "../plans/Plans.css";
import "./Devices.css";
import Group86 from "../../assets/Group86.svg";
// import tick from "../../assets/tick.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useSelector,useDispatch } from 'react-redux';
import {

    getDevices, ordergroup, postorder,closeordergroupapi

} from "../../../Service/Service";
import { useLocation } from 'react-router-dom';

import LoadingSpinner from '../../../../src/components/assets/loaderspinner.gif'
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";


import { addanything, addtocart, setLoading } from '../../../actions'
import { Button } from '@mui/material'





const Devices = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [planid, setPlanid] = useState();
    const [carrier, setCarrier] = useState();
    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [myDevice, setMyDevice] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        setPlanid(location.state.id);
        setCarrier(location.state.CarrierId);

    }, [location]);

    useEffect(() => {
        if (planid && carrier) {
            setIsLoading(true);
            getDevices(planid, carrier).then((result) => {
                console.log(result);
                const dataArray = Object.values(result);
                setDevices(dataArray);
                setIsLoading(false);
            });

        }
    }, [planid, carrier]);
    useEffect(() => {
        if (Swal.isVisible()) {
            // Call Swal.close() to close the modal
            Swal.close();
          }
    }, []);
    
    const style = {
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "90vw",
        bgcolor: "background.paper",
        boxShadow: 24,
        display: "flex",
        borderRadius: ".3rem",
        minHeight: 600,
        maxWidth: '1000px',
        flexDirection: 'column',
    };
    const mediaStyle = {
        "@media (max-width: 768px)": {
            ...style,
            width: "95vw",
            minHeight: 400,
            flexDirection: 'column',
        },
    };

    const handleClose = () => setOpen(false);
    const handleOpen = (id) => {
        const objectToSave = getObjectById(id);
        setMyDevice(objectToSave);
        setOpen(true);
        console.log(objectToSave)

    }
    const getObjectById = (id) => {
        return devices.find(obj => obj.id === id);
    }
    const cart = useSelector((state) => state.cart);
    const auth = useSelector((state) => state.authReducer);
    const buyDevice = (id) => {
        setIsLoading(true)
        
        postorder({ device_id: id, order_hash: cart.hash.order_hash }).then(()=>{

            ordergroup(1, cart.hash.order_hash).then(() => { 

                closeordergroupapi(cart.hash.order_hash, null).then((result) => {
           
                    dispatch(addtocart(result));
                    handleClose();
                    setIsLoading(false)
                    Swal.fire({
                        title: "Success",
                        text: `Your device has been added to your cart.`,
                        denyButtonText: `Checkout`,
                        showDenyButton: true,
                        denyButtonColor: "#7066e0",
                        icon: "success",
                        confirmButtonText: "Shop more",
                        allowOutsideClick: true,
                        allowEscapeKey: false,
                        preDeny: () => {
                        
                            if (cart.cart.business_verification) {
                                navigate("/checkout");
                            } else {
                                if (auth.userInfo.id) {
                                    navigate("/checkout");
                                } else {
                                    if (auth.userInfo != null) {
                                        if (auth.userInfo.id) {
                                            navigate("/checkout");
                                        }
                                    } else {
                                        if (cart.cart && cart.cart.company) {
                                            if (
                                                cart.cart.company.business_verification === 1 &&
                                                !auth.userInfo.id
                                            ) {
                                            } else {
                                                navigate("/checkout");
                                            }
                                        }
                                    }
                                }
                            }
    
                            navigate("/checkout");
                        },
                        preConfirm: () => {
                            navigate("/plans");
                          },
                    }).then((result) => {
                        // check if the modal was closed
                        if (result.isDismissed) {
                            // navigate to the checkout page
                            navigate("/checkout");
                        }
                    });
              
            });
            });


        })
        console.log(cart.hash.order_hash)
       
     

    }

    const checkout = () =>{
    
        Swal.fire({
          title: "Success",
          text: `Continue checkout without device`,
          denyButtonText: `Checkout`,
          showDenyButton: true,
          denyButtonColor: "#7066e0",
          icon: "success",
          confirmButtonText: "Shop more",
          allowOutsideClick: true,
          allowEscapeKey: false,
          preDeny: () => {
            // processCoupon();
            // getCartOrder();
    
            // if (props.cart.cart.business_verification) {
            //   navigate("/checkout");
            // } 
            // else {
            //   if (props.auth.userInfo.id) {
            //     navigate("/checkout");
            //   } else {
            //     if (props.auth.userInfo != null) {
            //       if (props.auth.userInfo.id) {
            //         navigate("/checkout");
            //       }
            //     } else {
            //       if (props.cart.cart && props.cart.cart.company) {
            //         if (
            //           props.cart.cart.company.business_verification === 1 &&
            //           !props.auth.userInfo.id
            //         ) {
            //         } else {
            //           navigate("/checkout");
            //         }
            //       }
            //     }
            //   }
            // }
    
            ordergroup(1, props.cart.hash.order_hash).then(() => {
                closeordergroupapi(props.cart.hash.order_hash, null).then((result)=>{
                  props.addtocart(result);
                  navigate("/checkout");
                })
    
    
            });
          
          },
          preConfirm: () => {},
        });
    
      }
    
    return (
        <>
        {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
        )}
            <Navbar />

            <div className="support-tetx">
                <div className="support-trans">
                    <p className="service-p">
                        The Service You Want. The Support You Need.
                    </p>
                    <h1 className="head-p">One Low Monthly Fee.</h1>
                    <img className="im86" src={Group86} />
                </div>
            </div>
            <div className='container'>
                <h1 className='content-title'>Start Your Business Package By Choosing Your Wireless Solution Below.</h1>
                <div className='device-wrap'>
                    {devices.map((obj) => {
                        return (
                            <div className='col-md-4 col-sm-12 item' key={obj.id}>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <div className='img-wrap'>
                                            <img src="https://teltik.pw/imgs/placeholders/default-phone.png" alt='Device image' />
                                        </div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className='px-3 my-3'>
                                            <div className='device-name'>
                                                {obj.name}
                                            </div>
                                            <div className='device-specs' dangerouslySetInnerHTML={{
                                                __html: obj?.description,
                                            }}>
                                            </div>
                                        </div>
                                        <div className='plan-price'>
                                            <div className='price1'>
                                                <span>${obj.amount_w_plan}</span> with Plan
                                            </div>
                                            <div className='price2'>
                                                <span>${obj.amount}</span> without Plan
                                            </div>
                                        </div>
                                        <div className='my-3'>
                                            <button onClick={() => handleOpen(obj.id)} className='cart-btn'>
                                                ADD TO CART
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )

                    })
                    }
                </div>
            </div>
            <Footer />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ overflowY: "scroll" }}
            >
                <Box sx={{ ...style, ...mediaStyle }}>
                    <div className="closeModalBtn" >
                        <span onClick={handleClose}>×</span>
                    </div>
                    <div className='upper-modal modal-section'>
                        <div className='img-section'>
                            <div className='img-wraper'>
                                <img src="https://teltik.pw/imgs/placeholders/default-phone.png" />
                            </div>
                        </div>
                        <div className='detail-section w-100'>
                            <h2 className='device-title'>{myDevice?.name}</h2>
                            {/* <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> iPhone X features a 5.8-inch Super Retina display with HDR </span>
                            </div> */}
                            <div className='device-description' dangerouslySetInnerHTML={{
                                __html: myDevice?.description,
                            }}></div>
                            <div className='add-cart-btn py-4 my-3'>
                                <div className='d-flex justify-content-between w-100'>
                                    <div className='price'>
                                        <span>${myDevice?.amount_w_plan}</span> with Plan
                                    </div>
                                    <div className='price'>
                                        <span>${myDevice?.amount}</span> without Plan
                                    </div>
                                </div>
                                <div className='mt-3 text-center'>
                                    <button className='cart-btn' onClick={() => buyDevice(myDevice?.id)}>
                                        ADD TO CART
                                    </button>
                                </div>
                            </div>
                            {/* <h4 className='device-title'>WHAT'S IN THE BOX</h4>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> iPhone X features a 5.8-inch Super Retina display with HDR </span>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className='lower-modal modal-section'>
                        <div className='specification-wrap'>
                            <h4 className='spec-title'>Specifications</h4>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> */}
                    <div className='lower-modal modal-section' dangerouslySetInnerHTML={{
                        __html: myDevice?.description_detail,
                    }}></div>
                </Box>
            </Modal>

            {props.cart.cart.activeGroupId && props.cart.cart.order_groups.find(
          (obj) => obj.id == props.cart.cart.activeGroupId
        ).plan &&<div className='btnfixed'><Button   variant="contained" onClick={()=>checkout()} >Continue Without Device</Button></div>}
   
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      cart: state.cart,
      auth: state.authReducer,
    };
  };
  
  
  export default connect(mapStateToProps, { addtocart, addanything, setLoading })(
    Devices
  );
  