import React, { useLayoutEffect } from 'react'
import Navbar from '../Navbar'
import FullWidthTabs from './Tabs'
import Footer from '../footer/Footer'
import { Modal } from 'react-bootstrap'
import { useEffect } from 'react'
import { connect } from "react-redux";

import { useNavigate } from 'react-router-dom'
import { addanything, addtocart, setLoading } from '../../actions'
import { Button } from '@mui/material'
import Swal from 'sweetalert2'
import { closeordergroupapi, ordergroup } from '../../Service/Service'


const Devicespage = (props) => {
  
  const navigate= useNavigate();

    useEffect(() => {
      // props.setLoading(true);
      console.log("plans associated ", props.cart.cart.plansAssociated);
      if (props.cart.cart.activeGroupId && props.cart.cart.order_groups.find(
        (obj) => obj.id == props.cart.cart.activeGroupId
      ).plan ) {
       
        console.log("devices naigads")
        var obj = props.cart.cart.order_groups.find(
          (obj) => obj.id == props.cart.cart.activeGroupId
        ).plan;
        navigate("/devices", { state: { id: obj.id, CarrierId:obj.carrier_id } });


      } else { 
       

      }
    }, []);


    const checkout = () =>{
    
      Swal.fire({
        title: "Success",
        text: `Continue checkout without plan`,
        denyButtonText: `Checkout`,
        showDenyButton: true,
        denyButtonColor: "#7066e0",
        icon: "success",
        confirmButtonText: "Shop more",
        allowOutsideClick: true,
        allowEscapeKey: false,
        preDeny: () => {
          // processCoupon();
          // getCartOrder();
  
          // if (props.cart.cart.business_verification) {
          //   navigate("/checkout");
          // } 
          // else {
          //   if (props.auth.userInfo.id) {
          //     navigate("/checkout");
          //   } else {
          //     if (props.auth.userInfo != null) {
          //       if (props.auth.userInfo.id) {
          //         navigate("/checkout");
          //       }
          //     } else {
          //       if (props.cart.cart && props.cart.cart.company) {
          //         if (
          //           props.cart.cart.company.business_verification === 1 &&
          //           !props.auth.userInfo.id
          //         ) {
          //         } else {
          //           navigate("/checkout");
          //         }
          //       }
          //     }
          //   }
          // }
  
          ordergroup(1, props.cart.hash.order_hash).then(() => {
              closeordergroupapi(props.cart.hash.order_hash, null).then((result)=>{
                props.addtocart(result);
                navigate("/checkout");
              })
  
  
          });
        
        },
        preConfirm: () => {},
      });
  
    }
  
  return (<>
    <div><Navbar/>
    <div className='container' style={{display:"flex",justifyContent:"center" ,flexDirection:"column",alignItems:"center"}}>
        <h2 style={{fontSize:"36px",fontWeight:700,textAlign:"center",marginBottom:40,marginTop:70}}>Start Your Business Package By Choosing Your Wireless Solution <br/>Below.</h2>
    <FullWidthTabs/>
    </div>
    </div>
    <Footer />


    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.authReducer,
  };
};


export default connect(mapStateToProps, { addtocart, addanything, setLoading })(
  Devicespage
);
