import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Billing from "./Billing";
import Customerinfo from "./Customerinfo";
import Payment from "./Payment";
import Shipping from "./Shipping";
import { useState , useRef} from 'react';
import { connect } from "react-redux";
import { addcoupon, closeordergroupapi, customer, getcustomercards, getCustomerCart, getStateTax, removecoupon, removeItem } from "../../Service/Service";
import Swal from "sweetalert2";
import { addanything, addcouponredux, addtocart, removecouponredux, updateState } from "../../actions";
import CloseIcon from "@mui/icons-material/Close";
import "./checkout2.css"
import Footer from "../footer/Footer";
import { useSearchParams } from "react-router-dom";
import updateReducer from "../../reducers/updateReducer";
const Checkout2 = (props) => {
  
  const [shippinginfo,setshippinginfo]= useState({

    firstname:"",
    lastname:"",
    address1:"",
    address2:"",
    city:"",
    state:"",
    zipcode:""
  
  })


  const [customerinfo,setcustomerinfo] = useState({

    firstname:"",
    lastname:"",
    email:"",
    phone:"",
    altphone:"",
    password:"",
    confirmpass:"",
    pin:""

})


const [billinginfo,setbillinginfo]= useState({

  firstname:"",
  lastname:"",
  address1:"",
  address2:"",
  city:"",
  state:"",
  zipcode:""

})


useEffect(()=>{

if(props.auth.isSignedIn)
{
    setcustomerinfo({

      firstname:props.auth.userInfo.fname,
      lastname:props.auth.userInfo.lname,
      email:props.auth.userInfo.email,
      phone:props.auth.userInfo.phone,
      altphone:props.auth.userInfo.alternate_phone,
      password:"*******",
      confirmpass:"*******",
      pin:props.auth.userInfo.pin
      

    })


    setshippinginfo({

      firstname:props.auth.userInfo.shipping_fname,
      lastname:props.auth.userInfo.shipping_lname,
      address1:props.auth.userInfo.shipping_address1,
      address2:props.auth.userInfo.shipping_address2,
      city:props.auth.userInfo.shipping_city,
      state:props.auth.userInfo.shipping_state_id,
      zipcode:props.auth.userInfo.shipping_zip
    })

    setbillinginfo({
      firstname:props.auth.userInfo.billing_fname,
      lastname:props.auth.userInfo.billing_lname,
      address1:props.auth.userInfo.billing_address1,
      address2:props.auth.userInfo.billing_address2,
      city:props.auth.userInfo.billing_city,
      state:props.auth.userInfo.billing_state_id,
      zipcode:props.auth.userInfo.billing_zip

    })

}  


},[])

const [total,settotal]=useState(0);
useEffect(()=>{
      if(props.cart.cart.order_groups.filter((obj)=>{
        if(obj.plan===null)
        return obj
      }).length==0)

      {
              settotal(props.cart.cart.order_groups.reduce((subtotal,obj)=>subtotal+=obj.plan.amount_recurring,0));
      }
   

})


const [coupon,setcoupon] = useState("");

const applycoupon = () =>{


      
  addcoupon(coupon,props.auth.userInfo.id,props.cart.hash.id,props.auth.userInfo.hash).then((couponAmount)=>{
   

  
    
      if (!couponAmount.error) {

        getCustomerCart(props.auth.userInfo.id).then((cart)=>{
          if(cart.hasOwnProperty("order_hash"))
          {
            props.addanything({hash:{id:cart.id,order_hash:cart.order_hash}});
          }
          props.addtocart(cart);
        
        })

        let couponCodes = props.cart.hasOwnProperty('couponCodes')?props.cart.couponCodes : [];
        let couponAmounts = props.cart.hasOwnProperty('couponAmount') ? props.cart.couponAmount : [];
        
        if(!couponCodes.includes(couponAmount.code)){
          couponCodes.push(couponAmount.code);
          props.addanything({couponCodes:couponCodes});
        }
    
        let couponAmountAlreadyExists = false;
        let tempCouponAmount = [];
        couponAmounts.forEach(coupon => {
          if(coupon.code === couponAmount.code){
            couponAmountAlreadyExists = true;
            tempCouponAmount.push(couponAmount);
          } else {
            tempCouponAmount.push(coupon);
          }
        });
    
        if(!couponAmountAlreadyExists){
          couponAmounts.push(couponAmount);
          props.addanything({couponAmount:couponAmounts});
        } else {
          props.addanything({couponAmount:tempCouponAmount});
        }
        if(couponAmount.code==coupon){
        
          Swal.fire({
            icon: 'success',
            title: 'Coupon Added Successfully',
           
            
          })
        }
        
        return couponAmount;
      } else {
        
          Swal.fire({
            icon: 'error',
            title: 'Not a valid coupon',
           
            
          })
    
      }

    
    
    
  
  })

}


const removeitemwrapper =(id)=>{
  
  sethold(true);

  removeItem(id,props.cart.hash.order_hash).then((result)=>{
      props.addtocart(result);
      props.updateState(props.updateReducer+1);
      sethold(false);

 })


}

const removecouponwrapper = (code)=>{
  removecoupon(code,props.cart.hash.id).then(()=>{
    getCustomerCart(props.auth.userInfo.id).then((cart)=>{
      props.addtocart(cart);
      props.updateState();
      props.addanything({hash:{id:cart.id,order_hash:cart.order_hash}});
    })
      props.removecouponredux(code);

  })
}

const [hold,sethold]=useState(false);

const [searchParams, setSearchParams] = useSearchParams();
useEffect(()=>{

  if (searchParams.get("order_hash"))
  { 
    console.log("hash");
    closeordergroupapi(searchParams.get("order_hash"), null).then((getOrder) => {
      if (!getOrder.order_groups.length > 0) {
        alert(
          "Oops! Something is incorrect, please check your mail again or contact us if the problem still persists."
        );
      } else {
        // props.addanything({hash:})\\
        console.log("order");
        console.log(getOrder);
        props.addtocart(getOrder);

        if (getOrder.couponDetails) {
          props.addanything({ couponAmount: getOrder.couponDetails });

          getOrder.couponDetails.forEach((coupon) => {
            var couponCodes = props.cart.couponCode
              ? props.cart.couponCode
              : [];
            if (coupon.error && !couponCodes.includes(coupon.code)) {
              props.addanything({ couponCodes: coupon.code });
            }
          });
        }

        if (props.cart.status == 1) {
          alert("you have already placed your order");
        }
      }
    });

  }
  else{

    window.history.replaceState(
      null,
      "New Page Title",
      window.location.pathname + "?order_hash=" + props.cart.hash.order_hash
    );
  }

},[])

useEffect(()=>{

getStateTax(props.auth.userInfo.billing_state_id).then((result)=>{
   
})

},[])

  return (
    <>

    <Navbar/>        
      <div className="maincontainer">
    
        <div class="container">
          <div class="py-5 text-center">
            
            <h2 className="pb-3 fw-bold">Checkout Form</h2>
            <p class="lead">You are almost done! fill the following information
and select/add your card for payment.</p>
          </div>
          <div class="row">
            <div class="col-md-4 order-md-2 mb-4">
              <h4 class="d-flex justify-content-between align-items-center mb-3">
                <span class="fw-bold" style={{color:'#152D79 '}}>Your cart</span>
                <span class="badge badge-secondary badge-pill">3</span>
              </h4>
              <ul class="list-group mb-3">
                <div className="checkout-list-group first-item">
                  <li className="list-group-item d-flex justify-content-between lh-condensed align-items-center fw-bold">
                  Selected Items
                  </li>
                </div>
              {props.cart&&props.cart.cart.order_groups.map((obj, index) => (
                
    <>
  <div className="checkout-list-group" style={{display:"flex",alignItems:"center"}}>
    <div style={{flex:"1"}}>
          {obj.plan&&
              <li class="list-group-item d-flex justify-content-between lh-condensed align-items-center">
                          <div>
                            <h6 class="my-0 fw-bold">{obj.plan.name}</h6>
                            {/* <small class="text-muted">Brief description</small> */}
                          </div>
                          <span class="text-muted" style={{position:'absolute',right:'90px'}}>${obj.plan_prorated_amt?obj.plan_prorated_amt:obj.plan.amount_recurring.toFixed(2)}</span>
                          
              </li>
          }
   {  obj.plan!=null && obj.plan.amount_onetime!=0&&
              <li class="list-group-item d-flex justify-content-between lh-condensed align-items-center">
                          <div>
                            <h6 class="my-0 fw-bold">Activation fee</h6>
                            {/* <small class="text-muted">Brief description</small> */}
                          </div>
                          <span class="text-muted" style={{position:'absolute',right:'90px'}}>${obj.plan.amount_onetime.toFixed(2)}</span>
              </li>
          }
          {obj.sim&&
              <li class="list-group-item d-flex justify-content-between lh-condensed align-items-center">
                <div>
                  <h6 class="my-0 fw-bold">{obj.sim.name}</h6>
                  {/* <small class="text-muted">Brief description</small> */}
                </div>
                <span class="text-muted" style={{position:'absolute',right:'90px'}}>${obj.sim.amount_w_plan.toFixed(2)}</span>
                <span style={{cursor:"pointer", width:"20px"}} ></span>
              </li>
          }
          {obj.device&&
              <li class="list-group-item d-flex justify-content-between lh-condensed align-items-center">
                <div>
                  <h6 class="my-0 fw-bold">{obj.device.name}</h6>
                  {/* <small class="text-muted">Brief description</small> */}
                </div>
                <span class="text-muted" style={{position:'absolute',right:'90px'}}>${obj.plan?obj.device.amount_w_plan.toFixed(2):obj.device.amount.toFixed(2)}</span>
                <span style={{cursor:"pointer", width:"20px"}} ></span>
              </li>
          }
          </div>
          <div>
          <span style={{cursor:"pointer", width:"20px",marginRight:20,position:"relative",bottom:3}} ><button className="crossbtn" disabled={hold} onClick={()=>removeitemwrapper(obj.id)}><CloseIcon /></button></span>
          </div>
  </div>
        </>
       ))}
                 {/* <li class="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 class="my-0">Monthly Charge</h6>
                    
                  </div>
                  <span class="text-muted">${props.cart.cart.monthlyCharge.toFixed(2)}</span>
                </li> */}
                <li class="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 class="my-0">SUBTOTAL</h6>
                    
                  </div>
                  <span class="text-muted">${props.cart.cart.subtotalPrice.toFixed(2)}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 class="my-0">Shipping:</h6>
                    
                  </div>
                  <span class="text-muted">+ ${props.cart.cart.shippingFee.toFixed(2)}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between bg-light">
                  <div class="text-success">
                    <h6 class="my-0">Coupons:</h6>
                    <small>{props.cart?.cart?.couponDetails &&props.cart.cart.couponDetails.map((obj)=><><small>{obj.code}</small><span style={{marginLeft:10}}>-{obj.total}$</span><span style={{marginLeft:10,cursor:"pointer"}} onClick={()=>removecouponwrapper(obj.code)}>X</span><br></br></>)}</small>
                    
                  </div>
                  <span class="text-success"> - ${Math.abs(props.cart.cart.coupons).toFixed(2)}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <span>State Tax:</span>
                  <strong>+ ${props.cart.cart.taxes.toFixed(2)}</strong>
                </li>
                <li class="list-group-item d-flex justify-content-between">
                  <span>Regulatory Fee:</span>
                  <strong>+ ${props.cart.cart.regulatory.toFixed(2)}</strong>
                </li>
                <li class="list-group-item d-flex justify-content-between border-bottom">
                  <span className="fw-bold">Total due today:</span>
                  <strong>${props.cart.cart.totalPrice.toFixed(2)}</strong>
                </li>
              </ul>
              {/* <form class="card p-2">
                <div class="input-group">
                  <input type="text" value={coupon}  onChange={(e)=>setcoupon(e.target.value)} class="form-control" placeholder="Coupon"/>
                  <div class="input-group-append">
                    <button type="button" className="btnbtn btn-secondary" style={{background:'#152D79 '}} onClick={applycoupon}>Apply Coupon</button>
                  </div>
                </div>
              </form> */}
            </div>
            <div class="col-md-8 order-md-1">
           
      <Customerinfo customerinfo={customerinfo}  setcustomerinfo={setcustomerinfo} shippinginfo={shippinginfo} setshippinginfo={setshippinginfo}/>
      <Billing billinginfo={billinginfo} setbillinginfo={setbillinginfo} shippinginfo={shippinginfo}/>
      <Payment customerinfo={customerinfo}  billinginfo={billinginfo} shippinginfo={shippinginfo}/>
            
            </div>
          </div>
          {/* <footer class="my-5 pt-5 text-muted text-center text-small">
            <p class="mb-1">&copy; 2020-2021 therichpost.com</p>
            <ul class="list-inline">
              <li class="list-inline-item"><a href="#">Privacy</a></li>
              <li class="list-inline-item"><a href="#">Terms</a></li>
              <li class="list-inline-item"><a href="#">Support</a></li>
            </ul>
          </footer> */}
        </div>
     
      </div>
      <div className="pt-5">

      <Footer/>
      </div>
    </>
  );
};
const mapStateToProps = (state) =>{

  return{
  cart : state.cart,
  auth: state.authReducer,
  updateReducer:state.updateReducer
      }

}


export default connect(mapStateToProps,{addcouponredux,addtocart,removecouponredux,addanything,updateState})(Checkout2);

