import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  LinearProgress,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { IMaskInput, useIMask } from "react-imask";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import { AiFillPlusCircle } from "react-icons/ai";
import { MdExpandMore } from "react-icons/md";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { connect, useDispatch } from "react-redux";
import visaCard from "../../components/assets/visa.jpg";
import masterCard from "../../components/assets/master-card.jpg";
import dinersCard from "../../components/assets/diners-card.png";
import LoadingSpinner from "../../components/assets/loaderspinner.gif";
import states from '../../myData/states.json';
import {
  addbillingcard,
  MakePrimaryCardService,
  RemoveCustomerCardService,
  UpdateCustomerInformation,
} from "../../Service/Service";
import Swal from "sweetalert2";
import { Button, Spinner } from "react-bootstrap";
import {
  UpdateUserInfoAction,
  RemoveCard,
  AddNewCreditCard,
  MakeCardPrimary,
} from "../../actions";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000 0000 0000 0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
const PrimaryPhoneNoMask = React.forwardRef(function PrimaryPhoneNoMask(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const SecondryPhoneNoMask = React.forwardRef(function SecondryPhoneNoMask(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

PrimaryPhoneNoMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MMYY = React.forwardRef(function MMYY(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

MMYY.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Accountinfo = ({ UserInfo, Refresh, setRefresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [CardDetails, setCardDetails] = useState([]);
  const [rederror, setrederror] = useState({});
  const [showEditState, setShowEditState] = useState();
  const [cvv, setcvv] = useState("");
  const [creditCardCity, setCreditCardCity] = useState();
  const [creditCardAddress, setCreditCardAddress] = useState();
  const [creditCardZip, setCreditCardZip] = useState();
  const [creditCardState, setCreditCardState] = useState();
  const [customerFirstName, setcustomerFirstName] = useState(
    UserInfo && UserInfo?.fname
  );
  const [customerLastName, setcustomerLastName] = useState(
    UserInfo && UserInfo.lname
  );
  const [customerEmail, setCustomerEmail] = useState(
    UserInfo && UserInfo.email
  );
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [primaryPhoneNumber, setprimaryPhoneNumber] = useState(
    UserInfo && UserInfo.phone
  );
  const [secondaryPhoneNumber, setsecondaryPhoneNumber] = useState();
  // UserInfo && props.auth.alternate_phone
  const [shippingFirstName, setShippingFirstName] = useState(
    UserInfo && UserInfo.shipping_fname
  );
  const [shippingLastName, setShippingLastName] = useState(
    UserInfo && UserInfo.shipping_lname
  );

  const [shippingAddress1, setshippingAddress1] = useState();
  // UserInfo && props.auth.shipping_address1
  const [shippingAddress2, setshippingAddress2] = useState();
  // props.auth.userInfo && props.auth.shipping_address2
  const [shippingState, setshippingState] = useState();
  // props.auth.userInfo && props.auth.shipping_state_id
  const [shippingCity, setshippingCity] = useState("");
  const [shippingPostalCode, setshippingPostalCode] = useState();
  // props.auth.userInfo && props.auth.userInfo && props.auth.shipping_zip
  const [billingFirstName, setbillingFirstName] = useState();
  // props.auth.userInfo && props.auth.userInfo.billing_fname
  const [billingLastName, setbillingLastName] = useState();
  // props.auth.userInfo && props.auth.userInfo.billing_lname
  const [billingAddress1, setbillingAddress1] = useState();
  // props.auth.userInfo && props.auth.billing_address1
  const [billingAddress2, setbillingAddress2] = useState();
  // props.auth.userInfo && props.auth.billing_address2
  const [billingState, setbillingState] = useState();
  // props.auth.billing_state_id
  const [billingCity, setbillingCity] = useState();
  // props.auth.billing_city
  const [billingPostalCode, setbillingPostalCode] = useState();
  // props.auth.userInfo && props.auth.billing_zip
  const [show, setshow] = useState(false);
  const [isAutoPay, setIsAutoPay] = useState();
  // props.auth.userInfo && props.auth.userInfo.auto_pay == 1 ? true : false
  const [cardholdername, setcardholdername] = useState("");
  const AutopayHandler = (key1, val1) => {
    setIsLoading(true);
    UpdateCustomerInformation(key1, val1).then((res) => {
      if (res.message === "sucessfully Updated") {
        // props.UpdateUserInfoAction({ key1, val1 });
        setRefresh(Refresh + 1);

        setIsAutoPay(!isAutoPay);
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text:"Auto pay changes Done"
        });

      }
    });
  };

  const [values, setValues] = useState({
    textmask: "",
    mmyy: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const OnChangeShippingState = (e) => {
    if (e.target.value.length < 3) {
      setshippingState(e.target.value);
    }
  };
  const OnChangeBillingState = (e) => {
    if (e.target.value.length < 3) {
      setbillingState(e.target.value);
    }
  };
  const OnChangeCreditCardState = (e) => {
    // if (e.target.value.length < 3) {
      setCreditCardState(e.target.value);
    // }
  };
 
  const OnChangeCreditCardZip = (e) => {
    if (e.target.value.length < 6) {
      setCreditCardZip(e.target.value);
    }
    
  };

  //Use Effect
  useEffect(() => {
    // let Data = JSON.stringify(JSON.parse(props.UserInfo));
    setcustomerFirstName(UserInfo?.fname);
    setcustomerLastName(UserInfo?.lname);
    setprimaryPhoneNumber(UserInfo?.phone);
    setsecondaryPhoneNumber(UserInfo?.alternate_phone);
    setCustomerEmail(UserInfo?.email);
    setshippingAddress1(UserInfo?.shipping_address1);
    setshippingAddress2(UserInfo?.shipping_address2);
    setshippingCity(UserInfo?.shipping_city);
    setshippingState(UserInfo?.shipping_state_id);
    setshippingPostalCode(UserInfo?.shipping_zip);
    setShippingFirstName(UserInfo?.shipping_fname);
    setShippingLastName(UserInfo?.shipping_lname);
    setbillingAddress1(UserInfo?.billing_address1);
    setbillingAddress2(UserInfo?.billing_address2);
    setbillingFirstName(UserInfo?.billing_fname);
    setbillingLastName(UserInfo?.billing_lname);
    setbillingCity(UserInfo?.billing_city);
    setbillingState(UserInfo?.billing_state_id);
    setbillingPostalCode(UserInfo?.billing_zip);
    // setIsAutoPay(UserInfo?.auto_pay == 1 ? true : false);
    // setCustomerEmail(UserInfo?.email);
    // setCustomerEmail(UserInfo?.email);
    getcustomercards();
    let data = JSON.stringify(UserInfo);
    // console.log(UserInfo);

    // console.log(JSON.parse(data)?.fname);
  }, [UserInfo]);

  const getcustomercards = () => {
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      var user = JSON.parse(localStorage.getItem("user_token"));
      myHeaders.append("AccessToken", "key");
      myHeaders.append("Authorization", "alar324r23423");

      var formdata = new FormData();
      formdata.append("customer_id", user.id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch("https://britex.pw/api/customer-cards", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          setCardDetails(result);
        })
        .catch((error) => {
          reject(error);
          console.log("error");
        });
    });
  };

  const dispatch = useDispatch();
  function isValidExpiry(expiry) {
    const parts = expiry.split('/');
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[1], 10);
    const currentYear = new Date().getFullYear() % 100;
  
    // Check if the year is in the future or the current year
    if (year < currentYear || year > currentYear + 10) {
      return false;
    }
  
    // Check if the month is between 1 and 12
    if (month < 1 || month > 12) {
      return false;
    }
  
    // Check if the date is in the future
    const today = new Date();
    const expiryDate = new Date(2000 + year, month - 1, 1);
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    expiryDate.setDate(expiryDate.getDate() - 1);
    if (expiryDate < today) {
      return false;
    }
  
    return true;
  }
  
  const addcard = () => {
    let errors = {};
    if (cardholdername.length < 3) {
      // setrederror(0);
      // return false;
      errors.cardHolderName=0;
    }
    if (values.textmask.length < 19) {
      errors.cardNumb=1;
      // setrederror(1);
      // return false;
    }
    if (cvv.length != 3) {
      errors.cardCvv=2;
      // setrederror(2);
      // return false;
    }
    if (values.mmyy.length != 5) {
      errors.cardMonthYear=3;
      // setrederror(3);
      // return false;
    }
    if (!creditCardCity) {
      errors.cardCity=4;
      // setrederror(4);
      // return false;
    }
    if (!creditCardState) {
      errors.cardState=5;
      // setrederror(5);
      // return false;
    }
    if (!creditCardAddress) {
      errors.cardAddress=6;
      // setrederror(6);
      // return false;
    }
    if (!creditCardZip) {
      errors.cardZip=7;
      // setrederror(7);
      // return false;
    } 
    if(Object.keys(errors).length !== 0){
      setrederror(errors);
      return false;
    }
    else {
      setIsLoading(true);
      const newDate = values.mmyy.split("/");
      // console.log(newDate[0]);
      addbillingcard(
        cardholdername,
        values.textmask,
        values.mmyy,
        cvv,

        // newDate[0],
        // newDate[1],
        UserInfo,

        creditCardCity,
        creditCardState,
        creditCardAddress,
        creditCardZip,
        newDate
      ).then((result) => {
        if(!isValidExpiry(values.mmyy)){
          errors.cardMonthYear=3;
          setrederror(errors);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error Adding Card",
            text: "Please enter correct card expiry",
          });
        }
        else if (result.success == true) {
          setcardholdername("");
          setValues({
            ...values,
            textmask: "",
            mmyy: "",
          });
          setcvv("");
          setCreditCardAddress();
          setCreditCardCity();
          setCreditCardZip();
          setCreditCardState();
          setshow(false);
          setrederror({});
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Card Added Successfully",
          });
          getcustomercards();
          // props.AddNewCreditCard(result?.card.card);
        } else {

          if(result.message.includes('The billing zip must be 5 digits.')){
            errors.cardZip=7;
            setrederror(errors);
            setIsLoading(false);
            Swal.fire({
              icon: 'Error',
              title: 'Error Adding Card',
              text: "The billing zip must be 5 digits",
            })
          }
          else{
            errors.cardNumb=1;
          setrederror(errors);
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error Adding Card",
            text: "Please enter correct card number",
          });
          }
          
        }
      });
    }
  };
  const handleNumType=(evt)=>{
    if (evt.key !== 'Backspace' && evt.key !== 'Delete' && (evt.key.length !== 1 || evt.key < '0' || evt.key > '9')) {
      evt.preventDefault();
    }
  }

  const UpdateCustomerInfo = (key1, val1, key2, value2, key3, value3) => {
    setIsLoading(true);
    UpdateCustomerInformation(key1, val1, key2, value2, key3, value3).then(
      (res) => {
        // if(res.statusCode == 500) {
        // setIsLoading(false);
        // }
        if (res.message === "sucessfully Updated") setRefresh(Refresh + 1);
        // props.UpdateUserInfoAction({ key1, val1, key2, value2, key3, value3 });
        setIsLoading(false);
        setShowEditState();
      }
    );
  };
  const RemoveCustomerCard = (id) => {
    setIsLoading(true);
    RemoveCustomerCardService(id).then((res) => {
      if (res.details == "Card Successfully Deleted") {
        // dispatch(RemoveCard(id));
        getcustomercards();
      }
      setIsLoading(false);
    });
  };
  const MakePrimaryCardHandler = (id) => {
    setIsLoading(true);
    MakePrimaryCardService(id).then((res) => {
      if (res.details == "Card Sucessfully Updated") {
        // alert('hahaha')
        // dispatch(MakeCardPrimary(id));
        getcustomercards();

        setIsLoading(false);
      }
    });
  };
  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div className="accountinfo">
        <h1>Account Info</h1>
        <Divider />
        {/* first name */}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={"10px"}
        >
          <div className="fields">
            <h4 className="input-label">First Name</h4>
            {showEditState && showEditState === "fName" ? (
              <TextField
                size="small"
                id="demo-helper-text-aligned"
                defaultValue={customerFirstName}
                onChange={(e) => setcustomerFirstName(e.target.value)}
              />
            ) : (
              <h4>{customerFirstName}</h4>
            )}
          </div>
          {showEditState && showEditState === "fName" ? (
            <Stack direction="row" spacing={2}>
              <Button
                className="cancel-button"
                onClick={() => setShowEditState()}
              >
                Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() => UpdateCustomerInfo("fname", customerFirstName)}
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              className="edit-button"
              onClick={() => setShowEditState("fName")}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Divider />
        {/* last name */}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={"10px"}
        >
          <div className="fields">
            <h4 className="input-label">Last Name</h4>
            {showEditState && showEditState === "lName" ? (
              <TextField
                size="small"
                id="demo-helper-text-aligned"
                defaultValue={customerLastName}
                onChange={(e) => setcustomerLastName(e.target.value)}
              />
            ) : (
              <h4>{customerLastName}</h4>
            )}
          </div>
          {showEditState && showEditState === "lName" ? (
            <Stack direction="row" spacing={2}>
              <Button
                className="cancel-button"
                onClick={() => setShowEditState()}
              >
                Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() =>
                  UpdateCustomerInfo(
                    "lname",
                    customerLastName
                    // || props.auth.userInfo.lname
                  )
                }
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              className="edit-button"
              onClick={() => setShowEditState("lName")}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Divider />
        {/* email */}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={"10px"}
        >
          <div className="fields">
            <h4 className="input-label">Email</h4>
            {showEditState && showEditState === "email" ? (
              <TextField
                size="small"
                id="demo-helper-text-aligned"
                defaultValue={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
              />
            ) : (
              <h4>{customerEmail}</h4>
            )}
          </div>
          {showEditState && showEditState === "email" ? (
            <Stack direction="row" spacing={2}>
              <Button
                className="cancel-button"
                onClick={() => setShowEditState()}
              >
                Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() =>
                  UpdateCustomerInfo(
                    "email",
                    customerEmail
                    // || props.auth.userInfo.email
                  )
                }
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              className="edit-button"
              onClick={() => setShowEditState("email")}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Divider />
        {/* password */}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={"10px"}
        >
          <div className="fields">
            <h4 className="input-label">Current Password</h4>
            {showEditState && showEditState === "password" ? (
              <TextField
                size="small"
                id="demo-helper-text-aligned"
                placeholder="******"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            ) : (
              <h4>******</h4>
            )}
            <h4 className="input-label">New Password</h4>
            {showEditState && showEditState === "password" ? (
              <TextField
                size="small"
                id="demo-helper-text-aligned"
                placeholder="******"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            ) : (
              <h4>******</h4>
            )}
            <h4 className="input-label">Retype New Password</h4>
            {showEditState && showEditState === "password" ? (
              <TextField
                size="small"
                id="demo-helper-text-aligned"
                placeholder="******"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            ) : (
              <h4>******</h4>
            )}
          </div>
          {showEditState && showEditState === "password" ? (
            <Stack direction="row" spacing={2}>
              <Button
                className="cancel-button"
                onClick={() => setShowEditState()}
              >
                Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() =>
                  UpdateCustomerInfo(
                    "old_password",
                    oldPassword,
                    "password",
                    newPassword,
                    "password_confirmation",
                    confirmNewPassword
                  )
                }
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              className="edit-button"
              onClick={() => setShowEditState("password")}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Divider />
        {/*primary phone number */}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={"10px"}
        >
          <div className="fields">
            <h4 className="input-label">Primary Phone Number</h4>
            {showEditState && showEditState === "primaryPhoneNumber" ? (
              <TextField
                InputProps={{
                  inputComponent: PrimaryPhoneNoMask,
                }}
                size="small"
                id="demo-helper-text-aligned"
                placeholder="000-000-0000"
                value={primaryPhoneNumber}
                onChange={(e) => setprimaryPhoneNumber(e.target.value)}
              />
            ) : (
              <h4>{primaryPhoneNumber}</h4>
            )}
          </div>
          {showEditState && showEditState === "primaryPhoneNumber" ? (
            <Stack direction="row" spacing={2}>
              <Button
                className="cancel-button"
                onClick={() => setShowEditState()}
              >
                Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() =>
                  UpdateCustomerInfo(
                    "phone",
                    primaryPhoneNumber.replace(/\s/g, "")
                    // ||
                    //   props.auth.userInfo.phone
                  )
                }
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              className="edit-button"
              onClick={() => setShowEditState("primaryPhoneNumber")}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Divider />
        {/*secondary phone number */}
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          gap={"10px"}
        >
          <div className="fields">
            <h4 className="input-label">Secondary Phone Number</h4>
            {showEditState && showEditState === "secondaryPhoneNumber" ? (
              <TextField
                TextField
                InputProps={{
                  inputComponent: SecondryPhoneNoMask,
                }}
                size="small"
                id="demo-helper-text-aligned"
                placeholder="000-000-0000"
                value={secondaryPhoneNumber}
                onChange={(e) => setsecondaryPhoneNumber(e.target.value)}
              />
            ) : (
              <h4>{secondaryPhoneNumber}</h4>
            )}
          </div>
          {showEditState && showEditState === "secondaryPhoneNumber" ? (
            <Stack direction="row" spacing={2}>
              <Button
                className="cancel-button"
                onClick={() => setShowEditState()}
              >
                Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() =>
                  UpdateCustomerInfo("alternate_phone", secondaryPhoneNumber)
                }
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button
              className="edit-button"
              onClick={() => setShowEditState("secondaryPhoneNumber")}
            >
              Edit
            </Button>
          )}
        </Stack>
      </div>
      <Divider />
      <div className="accountinfo">
        <div>
          <h1 style={{ fontSize: 24, paddingBlock: 14 }}>Shipping</h1>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Shipping First Name</h4>
              {showEditState && showEditState === "shippingFirstName" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={shippingFirstName}
                  onChange={(e) => setShippingFirstName(e.target.value)}
                />
              ) : (
                <h4>{shippingFirstName}</h4>
              )}
            </div>
            {showEditState && showEditState === "shippingFirstName" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("shipping_fname", shippingFirstName)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("shippingFirstName")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Shipping Last Name</h4>
              {showEditState && showEditState === "shippingLastName" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={shippingLastName}
                  onChange={(e) => setShippingLastName(e.target.value)}
                />
              ) : (
                <h4>{shippingLastName}</h4>
              )}
            </div>
            {showEditState && showEditState === "shippingLastName" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("shipping_lname", shippingLastName)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("shippingLastName")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Address 1</h4>
              {showEditState && showEditState === "shippingAddress1" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={shippingAddress1}
                  onChange={(e) => setshippingAddress1(e.target.value)}
                />
              ) : (
                <h4>{shippingAddress1}</h4>
              )}
            </div>
            {showEditState && showEditState === "shippingAddress1" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("shipping_address1", shippingAddress1)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("shippingAddress1")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Address 2</h4>
              {showEditState && showEditState === "shippingAddress2" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={shippingAddress2}
                  onChange={(e) => setshippingAddress2(e.target.value)}
                />
              ) : (
                <h4>{shippingAddress2}</h4>
              )}
            </div>
            {showEditState && showEditState === "shippingAddress2" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("shipping_address2", shippingAddress2)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("shippingAddress2")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">City</h4>
              {showEditState && showEditState === "shippingCity" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={shippingCity}
                  onChange={(e) => setshippingCity(e.target.value)}
                />
              ) : (
                <h4>{shippingCity}</h4>
              )}
            </div>
            {showEditState && showEditState === "shippingCity" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("shipping_city", shippingCity)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("shippingCity")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">State</h4>
              {showEditState && showEditState === "shippingState" ? (
                   <Select
                   fullWidth
                   size="small"
                   error={rederror.cardState === 5}
                   name="state"
                   value={shippingState?shippingState:""}
                   onChange={OnChangeShippingState}
                   id="outlined-required"
                   label="State"
                 >
                         {states.map((option)=>{
                           return(
                           <MenuItem value={option.code}>{option.name}</MenuItem>
                           )
                         })
                         }
              
              </Select>
                
              ) : (
                <h4>{shippingState}</h4>
              )}
            </div>
            {showEditState && showEditState === "shippingState" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("shipping_state_id", shippingState)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("shippingState")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Postal/Zip code</h4>
              {showEditState && showEditState === "shippingPostalCode" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  value={shippingPostalCode}
                  onChange={(e) => {
                    e.target.value.length < 6 &&
                      setshippingPostalCode(e.target.value);
                  }}
                />
              ) : (
                <h4>{shippingPostalCode}</h4>
              )}
            </div>
            {showEditState && showEditState === "shippingPostalCode" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("shipping_zip", shippingPostalCode)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("shippingPostalCode")}
              >
                Edit
              </Button>
            )}
          </Stack>
        </div>
      </div>
      <Divider />
      <div className="accountinfo">
        <div>
          <h1 style={{ fontSize: 24, paddingBlock: 14 }}>Billing</h1>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Billing First Name</h4>
              {showEditState && showEditState === "billingFirstName" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={billingFirstName}
                  onChange={(e) => setbillingFirstName(e.target.value)}
                />
              ) : (
                <h4>{billingFirstName}</h4>
              )}
            </div>
            {showEditState && showEditState === "billingFirstName" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("billing_fname", billingFirstName)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("billingFirstName")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Billing Last Name</h4>
              {showEditState && showEditState === "billingLastName" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={billingLastName}
                  onChange={(e) => setbillingLastName(e.target.value)}
                />
              ) : (
                <h4>{billingLastName}</h4>
              )}
            </div>
            {showEditState && showEditState === "billingLastName" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("billing_lname", billingLastName)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("billingLastName")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Address 1</h4>
              {showEditState && showEditState === "billingAddress1" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={billingAddress1}
                  onChange={(e) => setbillingAddress1(e.target.value)}
                />
              ) : (
                <h4>{billingAddress1}</h4>
              )}
            </div>
            {showEditState && showEditState === "billingAddress1" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("billing_address1", billingAddress1)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("billingAddress1")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Address 2</h4>
              {showEditState && showEditState === "billingAddress2" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={billingAddress2}
                  onChange={(e) => setbillingAddress2(e.target.value)}
                />
              ) : (
                <h4>{billingAddress2}</h4>
              )}
            </div>
            {showEditState && showEditState === "billingAddress2" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("billing_address2", billingAddress2)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("billingAddress2")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">City</h4>
              {showEditState && showEditState === "billingCity" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={billingCity}
                  onChange={(e) => setbillingCity(e.target.value)}
                />
              ) : (
                <h4>{billingCity}</h4>
              )}
            </div>
            {showEditState && showEditState === "billingCity" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("billing_city", billingCity)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("billingCity")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">State</h4>
              {showEditState && showEditState === "billingState" ? (
                         <Select
                         fullWidth
                         size="small"
                         error={rederror.cardState === 5}
                         name="state"
                         value={billingState?billingState:""}
                         onChange={OnChangeBillingState}
                         id="outlined-required"
                         label="State"
                       >
                               {states.map((option)=>{
                                 return(
                                 <MenuItem value={option.code}>{option.name}</MenuItem>
                                 )
                               })
                               }
                    
                    </Select>
            
              ) : (
                <h4>{billingState}</h4>
              )}
            </div>
            {showEditState && showEditState === "billingState" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("billing_state_id", billingState)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("billingState")}
              >
                Edit
              </Button>
            )}
          </Stack>
          <Divider />
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            gap={"10px"}
          >
            <div className="fields">
              <h4 className="input-label">Postal/Zip code</h4>
              {showEditState && showEditState === "billingPostalCode" ? (
                <TextField
                  size="small"
                  id="demo-helper-text-aligned"
                  defaultValue={billingPostalCode}
                  onChange={(e) => {
                    e.target.value.length < 6 &&
                      setbillingPostalCode(e.target.value);
                  }}
                />
              ) : (
                <h4>{billingPostalCode}</h4>
              )}
            </div>
            {showEditState && showEditState === "billingPostalCode" ? (
              <Stack direction="row" spacing={2}>
                <Button
                  className="cancel-button"
                  onClick={() => setShowEditState()}
                >
                  Cancel
                </Button>
                <Button
                  className="save-button"
                  onClick={() =>
                    UpdateCustomerInfo("billing_zip", billingPostalCode)
                  }
                >
                  Save
                </Button>
              </Stack>
            ) : (
              <Button
                className="edit-button"
                onClick={() => setShowEditState("billingPostalCode")}
              >
                Edit
              </Button>
            )}
          </Stack>
        </div>
      </div>
      <Divider />
      <div id="payment" style={{ marginBottom: 40 }} className="accountinfo">
        <h1 style={{ fontSize: 24, paddingBlock: 14 }}>Payment</h1>
        <div className="autopay">
          <Checkbox
            onChange={() =>
              AutopayHandler(
                "auto_pay",
                UserInfo?.auto_pay == 0 ? 1 : 0
                // props.auth.userInfo.auto_pay == 0 ? 1 : 0
              )
            }
            checked={UserInfo?.auto_pay == 0 ? false : true}
          />

          <label htmlFor="autopay">
            Enroll in Auto-Pay{" "}
            <span style={{ color: "#aab8bd" }}>
              Uncheck to Opt-Out of Auto-Pay
            </span>
          </label>
          {/* <input onChange={()=>AutopayHandler(props.auth.userInfo.auto_pay)} name="auto_pay" id="auto-pay" type="hidden" value={isAutoPay} /> */}
        </div>
      </div>
      {/* <Divider style={{marginBottom:10}}/> */}
      {/* card details */}
      <div className="card-details">
        {CardDetails &&
          CardDetails.map((data) => (
            <div key={data.id}>
              <Accordion style={{ marginBottom: 20 }}>
                <AccordionSummary
                  expandIcon={<MdExpandMore fontSize="25px" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack
                    width="60%"
                    direction="row"
                    justifyContent={"space-between"}
                    gap={"20px"}
                  >
                    <Stack alignItems={"center"} direction="row" spacing={2.5}>
                      <img
                        style={{ height: "25px", width: "40px" }}
                        src={
                          data?.card_type == "Visa"
                            ? visaCard
                            : data?.card_type == "Diners"
                            ? dinersCard
                            : masterCard
                        }
                      />
                      <Stack spacing={0.5}>
                        {data && data.default == 0 ? (
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                              MakePrimaryCardHandler(data?.id);
                            }}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 14,
                              color: "#33cccc",
                              fontStyle: "italic",
                            }}
                          >
                            {data?.default === 0 ? "Make Primary" : "Primary"}
                          </a>
                        ) : (
                          <p
                            className="primary-card-text"
                            style={{
                              textTransform: "capitalize",
                              fontSize: 14,
                              color: "#8dd43f",
                              fontStyle: "italic",
                              marginBottom: 0,
                            }}
                          >
                            {data?.default === 0 ? "Make Primary" : "Primary"}
                          </p>
                        )}

                        <Typography
                          style={{
                            textTransform: "capitalize",
                            fontSize: 14,
                            color: "#4c606b",
                          }}
                        >
                          {data && data.card_type} Ending in{" "}
                          {data && data.last4.slice(-4)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack spacing={0.5}>
                      <Typography
                        style={{
                          textTransform: "capitalize",
                          fontSize: 14,
                          color: "#aab8bd",
                          fontStyle: "italic",
                        }}
                      >
                        Expires
                      </Typography>
                      <Typography
                        style={{ textTransform: "capitalize", fontSize: 14 }}
                      >
                        {data?.expiration}
                      </Typography>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    paddingBlock={"20px"}
                    width="64%"
                    direction="row"
                    justifyContent={"space-between"}
                    gap={"20px"}
                  >
                    <Stack
                      alignItems={"baseline"}
                      direction="row"
                      spacing={2.5}
                      marginLeft="58px"
                    >
                      <Stack spacing={0.5}>
                        <a
                          style={{
                            textTransform: "capitalize",
                            fontSize: 14,
                            color: "#aab8bd",
                          }}
                        >
                          Name on card
                        </a>
                        <Typography
                          style={{ textTransform: "capitalize", fontSize: 14 }}
                        >
                          {data?.cardholder}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack spacing={0.5}>
                      <Typography
                        style={{
                          textTransform: "capitalize",
                          fontSize: 14,
                          color: "#aab8bd",
                        }}
                      >
                        Billing Address
                      </Typography>
                      <Typography
                        style={{
                          textTransform: "capitalize",
                          fontSize: 14,
                          lineHeight: "18px",
                        }}
                      >
                        {data?.billing_address1}
                        <br></br>
                        {data?.billing_city}
                        <br></br>
                        {data?.billing_zip}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack paddingBottom={"15px"} alignItems={"end"}>
                    <button
                      style={{ height: "40px" }}
                      className="cardcancel"
                      onClick={() => RemoveCustomerCard(data?.id)}
                    >
                      Remove
                    </button>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
      </div>
      {/* <Divider /> */}
      {/* Add Card */}
      <div id="add-card" className="accountinfo">
        <span
          className="addline"
          
          onClick={() => setshow(!show)}
        >
          <span>
            <AiFillPlusCircle fontSize={"25px"} />
          </span>
          &nbsp;&nbsp;&nbsp; <span>Add a Credit Card</span>
        </span>
        {show && (
          <>
            <Stack
              spacing={2}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
                 <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror.cardNumb === 1}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  name="textmask"
                  placeholder="0000 0000 0000 0000"
                  value={values.textmask}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  id="outlined-required"
                  label="Card Number"
                />
              </Typography>
              <div  style={{ width: "50%",display:'flex',justifyContent:"space-between" , columnGap:16}}>
              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror.cardMonthYear === 3}
                  InputProps={{
                    inputComponent: MMYY,
                  }}
                  id="outlined-required"
                  label="MM/YY"
                  name="mmyy"
                  value={values.mmyy}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Typography>
              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror.cardCvv === 2}
                  value={cvv}
                  onChange={(e) => {
                    (e.target.value >= 0) & (e.target.value.length < 4) &&
                      setcvv(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* <FuseSvgIcon className="text-48" size={24} color="action">material-outline:credit_card</FuseSvgIcon> */}
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-required"
                  label="CVV"
                />
              </Typography>

              </div>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
             
              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  error={rederror.cardHolderName === 0}
                  fullWidth
                  id="outlined-required"
                  label="Cardholder Name"
                  value={cardholdername}
                  onChange={(e) => {
                    setcardholdername(e.target.value);
                  }}
                />
              </Typography>
              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror.cardAddress === 6}
                  value={creditCardAddress}
                  onChange={(e) => setCreditCardAddress(e.target.value)}
                  id="outlined-required"
                  label="Address"
                />
              </Typography>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  error={rederror.cardCity === 4}
                  fullWidth
                  id="outlined-required"
                  label="City"
                  name="city"
                  value={creditCardCity}
                  onKeyDown={(evt)=>{
                    !(/^[a-zA-Z ]*$/.test(evt.key)) && evt.preventDefault();
                  }}
                  onChange={(e) => {
                    setCreditCardCity(e.target.value);
                  }}
                />
              </Typography>

              <FormControl 
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <InputLabel error={rederror.cardState === 5} id="demo-simple-select-helper-label">State</InputLabel>
                <Select
                  fullWidth
                  error={rederror.cardState === 5}
                  name="state"
                  value={creditCardState?creditCardState:""}
                  onChange={OnChangeCreditCardState}
                  id="outlined-required"
                  label="State"
                >
                        {states.map((option)=>{
                          return(
                          <MenuItem value={option.code}>{option.name}</MenuItem>
                          )
                        })
                        }
                </Select>
                
              </FormControl >
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
          

              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror.cardZip === 7}
                  id="outlined-required"
                  label="Zip"
                  name="zip"
                  value={creditCardZip}
                  onKeyDown={handleNumType}
                  onChange={OnChangeCreditCardZip}
                />
              </Typography>
              <div   style={{ width: "50%", marginLeft:16 }}>

              </div>
            </Stack>
            {/* <FormControlLabel
              className="mt-8"
              control={<Checkbox />}
              label="I have read and accept the terms of use, rules of flight and privacy policy"
            /> */}
            <br></br>

            <button className="cardcancel" onClick={() => setshow(false)}>
              Cancel
            </button>
            <button className="cardsave" onClick={() => addcard()}>
              Save
            </button>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    cardDetails: state.CardReducer,
  };
};

export default connect(mapStateToProps, {
  UpdateUserInfoAction,
  RemoveCard,
  AddNewCreditCard,
  MakeCardPrimary,
})(Accountinfo);
