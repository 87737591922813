import React from "react";
import Group86 from "../assets/Group86.svg";

const Header = () =>{

return(


<div className="plans-main">
        <div className="support-tetx whyus">
          <div className="whyus">
           
            <h1 className="head-p">Why Choose Us?</h1>
            <img className="im86" src={Group86} />
          </div>
        </div>
    </div>

)

}

export default Header;