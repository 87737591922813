const INTIAL_STATE = {
  // cart :[{name:"2GB DATA", amount_recurring:"25"},{name:"4GB DATA", amount_recurring:"25"},{name:"6GB DATA", amount_recurring:"25"}]

  cart: {},
  order_groups: [],
  coupon: [],
  shippingfee: 0.0,
  statetax: 0.0,
  regulatoryfee: 0.0,
  anything: [],
  loading: false,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "ADD_CART":

      

      return {
        ...state,
        cart: action.payload,
      };
    case "REMOVE_CART":
      return {
        ...state,
        cart: state.cart.filter((x) => {
          if (x.name != action.payload) return x;
        }),
      };
    case "ADD_COUPON":
      return {
        ...state,
        coupon: [
          ...state.coupon,
          {
            code: action.payload.code,
            amount: action.payload.total,
            info: action.payload.coupon_amount_details.details,
          },
        ],
      };
    case "REMOVE_COUPON":
      return {
        ...state,
        coupon: state.coupon.filter((obj) => {
          if (obj.code != action.payload) {
            return true;
          }
        }),
      };
    case "EMPTY_MY_CART":
      return {
        ...state,
        cart: {},
      };
    case "EMPTY_ORDER_HASH":
      delete state.hash;
      return {
        ...state,
      };
    case "STORE_ANYTHING":
      console.log("store anything");
      console.log(action.payload);

      Object.assign(state, action.payload);
    case "set_loading":
      return {
        ...state,
        loading: action.payload,
      };
    case "update_cart":
      console.log(state);
   
    return {...state,
      cart:{...state.cart,taxes:action.payload,totalPrice:state.cart.totalPrice+action.payload-state.cart.taxes}
      
    };
    default:
      return state;
  }
};
