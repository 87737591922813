import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";



const Nationwide = () =>{
    
    const [zipcode,setzipcode]=useState();
    const navigate = useNavigate();

    const submit = () =>{
     
        if(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode))
        {   
            navigate("/coverage", { state: { zipcode:zipcode }});
        }
        else{
            seterror(1);
          
        }

        
    }

    const [error,seterror] = useState(0);
return(
    <div className="nationwidediv">
        <div className="nationwideright">
            <h2>Nationwide Coverage For Less</h2>
            <p>connect to america's largest, fastest <br></br> and most reliable 5G network.</p>
        </div>
        <div className="nationwideleft">
                        <h2>Check your Coverage</h2>
            <div className="flex check-coverage-btn">
            <div className="zipcode">
                <input placeholder="Zip code" value={zipcode} onChange={(e)=>{setzipcode(e.target.value);seterror(0)}}  />

            </div>
                <button className="letsgo" onClick={()=>submit()}>Let's Go</button>
            </div>
            <div style={{height:24}}>
            {error==1&&<p style={{color:"red"}}>Please enter valid zip code.</p>}
            </div>
        </div>

    </div>

)


}

export default Nationwide;