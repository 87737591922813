import cart from "./cart";
import CardReducer from "./CardReducer";
import authReducer from "./authReducer";
import { combineReducers } from "redux";
import updateReducer from "./updateReducer";

const rootreducers = combineReducers({
    cart : cart,
    authReducer:authReducer,
    CardReducer: CardReducer,
    updateReducer:updateReducer
});
   
export default rootreducers;