const INTIAL_STATE = {
  customerCardsDetail: [],
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case "GET_CUSTOMER_CARDS":
      return {
        ...state,
        customerCardsDetail: action.payload,
      };
    case "ADD_NEW_CREDIT_CARD":
      return {
        ...state,
        customerCardsDetail: [...state.customerCardsDetail, action.payload],
      };
    case "REMOVE_CREDIT_CARD":
      const index = state.customerCardsDetail.findIndex((i) => {
        return i.id === action.payload;
      });
      state.customerCardsDetail.splice(index, 1);
      return {
        ...state,
      };
    case "MAKE_PRIMARY_CARD":
      const updatedCardDetails = state.customerCardsDetail.map((element) => {
        if (element.id == action.payload) {
          return { ...element, default: 1 };
        }
        else if (element.default == 1) {
          return { ...element, default: 0 };
        }
        else {
          return element
        }
      });
      return {
        ...state,
        customerCardsDetail:updatedCardDetails
      };
case "RESET_STATE":
  return { customerCardsDetail: []}
    default:
      return state;
  }
};
