import React, { useEffect, useImperativeHandle } from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { addcoupon, chargenewcard, removecoupon } from "../../Service/Service";
import { connect } from "react-redux";
import { addcouponredux, removecouponredux } from "../../actions";
import { Alert } from "react-bootstrap";
import Swal from "sweetalert2";
import { IMaskInput, useIMask } from "react-imask";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  LinearProgress,
  Stack,
} from "@mui/material";
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000 0000 0000 0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};




const Paymentinfo = React.forwardRef((props, ref) => {
  const [loading, setloading] = useState(false);
  const [rederror, setrederror] = useState();
  const [cardnumber, setcardnumber] = useState("saved");
  const [coupon, setcoupon] = useState("");

  useImperativeHandle(ref, () => ({
    childFunction4() {
      chargenewcard(newcard, props.billinginfo).then(() => {
        Swal.fire({
          icon: "success",
          title: "Order placed successfully",
        });
      });
    },
  }));

  const applycoupon = () => {
    addcoupon(coupon, props.auth.userInfo.id).then((result) => {
      if (result.code == coupon) {
        props.addcouponredux(result);
        Swal.fire({
          icon: "success",
          title: "Coupon Added Successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Not a valid coupon",
        });
      }
    });
  };

  const [newcard, setnewcard] = useState({
    cardholdername: "",
    cardnumber: "",
    cvv: "",
    mmyy: "",
  });

  const handleEvent = (e) => {
    const { name, value } = e.target;
    setnewcard((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    console.log("render");
  });

  const removethiscoupon = (obj) => {
    removecoupon(obj).then((result) => {
      if (result.status) {
        props.removecouponredux(obj);
      }
    });
  };

  return (
    <>
      <form className="myform">
        <FormControl>
          <h3 style={{ marginBottom: 15 }}>Apply Coupon</h3>
          <div>
            <TextField
              label=""
              value={coupon}
              onChange={(e) => setcoupon(e.target.value)}
              style={{ marginBottom: 30 }}
              className="half"
              variant="outlined"
              InputProps={{ endAdornment: <TaskAltIcon /> }}
            />
            <button
              style={{ height: 54, width: 103, marginLeft: 10 }}
              className="btn btn--primary btn--medium primary"
              onClick={applycoupon}
              type="button"
            >
              ADD
            </button>
          </div>
          {props.cart.coupon.length > 0 &&
            props.cart.coupon.map((obj) => {
              return (
                <>
                  <div>
                    {obj.code}
                    <button
                      type="button"
                      onClick={() => removethiscoupon(obj.code)}
                    >
                      remove
                    </button>
                  </div>
                </>
              );
            })}

          <h3 style={{ marginBottom: 10 }}>Select Card</h3>
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={cardnumber}
            onChange={(e) => setcardnumber(e.target.value)}
            defaultValue="saved"
            id="selectedcard"
            s
          >
            <FormControlLabel
              onChange={() => setloading(false)}
              value="saved"
              control={<Radio />}
              label="4242"
            />
            <FormControlLabel
              onChange={() => setloading(true)}
              value="new"
              control={<Radio />}
              label="Add New Card"
            />
          </RadioGroup>
        </FormControl>
        <Stack
              spacing={2}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  error={rederror === 0}
                  fullWidth
                  id="outlined-required"
                  label="Cardholder Name"
                  value={newcard.cardholdername}
                  onChange={handleEvent}
                />
              </Typography>

              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror === 1}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  name="textmask"
                  placeholder="0000 0000 0000 0000"
                  value={newcard.cardnumber}
                  onChange={handleEvent}
                  id="outlined-required"
                  label="Card Number"
                />
              </Typography>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              width="100%"
              justifyContent={"space-between"}
            >
              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror === 2}
                  value={newcard.cvv}
                  onChange={(e) => {
                    (e.target.value >= 0) & (e.target.value.length < 4) &&
                      handleEvent(e)
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* <FuseSvgIcon className="text-48" size={24} color="action">material-outline:credit_card</FuseSvgIcon> */}
                      </InputAdornment>
                    ),
                  }}
                  id="outlined-required"
                  label="CVV"
                />
              </Typography>

              <Typography
                style={{ width: "50%" }}
                className="mt-8 font-medium text-3xl leading-none"
              >
                <TextField
                  fullWidth
                  error={rederror === 3}
                  InputProps={{
                    inputComponent: newcard.mmyy,
                  }}
                  id="outlined-required"
                  label="MM/YY"
                  name="mmyy"
                  value={newcard.mmyy}
                  onChange={handleEvent}
                />
              </Typography>
            </Stack>
        {loading && (
          <>
            {" "}
            <div>
              <TextField
                label="Cardholder Name"
                name="cardholdername"
                value={newcard.cardholdername}
                onChange={handleEvent}
                className="full"
                variant="outlined"
              />
            </div>
            <div>
            
              <TextField
                label="Card Number"
                name="cardnumber"
                InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                  placeholder="0000 0000 0000 0000"
                value={newcard.cardnumber}
                onChange={handleEvent}
                className="full"
                variant="outlined"
              />
            </div>
            <div>
              <TextField
                id="outlined-basic"
                name="cvv"
                value={newcard.cvv}
                onChange={handleEvent}
                className="half"
                label="CVV"
                variant="outlined"
              />
              <TextField
                label="MM/YY"
                name="mmyy"
                value={newcard.mmyy}
                onChange={handleEvent}
                className="half"
                variant="outlined"
              />
            </div>
          </>
        )}

        <FormControl>
          {" "}
          <FormControlLabel
            control={<Checkbox />}
            label="I accept the terms of service."
          />
        </FormControl>
      </form>
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps, { addcouponredux, removecouponredux })(
  Paymentinfo
);
