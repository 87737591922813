import React,{useEffect,useState} from "react";
import { useLocation } from "react-router-dom";
import Footer from "../footer/Footer";
import Navbar from "../Navbar";


const Coverage = () =>{
    const location = useLocation();
    const [zip,setZip]=useState();
    useEffect(() => {
      location.state ===null ? setZip(54000) :setZip(location.state.zipcode);
    }, []);
  
return (
    <>

    <Navbar/>
    <div className="support-tetx">
          <div className="support-trans">
           
            <h1 className="head-p">Putting excellent coverage on the map</h1>
            
          </div>

       
        </div>
        <div style={{height:900,padding:"40px 80px"}}>
        <iframe src={"https://www.mintmobile.com/stripped-coverage-map/?variation=noroam-5g&address="+zip} style={{width:"100%",height:"100%"}} id="map-frame"></iframe>
        </div>
        <Footer/>
    </>
   

)

}


export default Coverage;