import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./dialogStyle.css"
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import visaCard from "../../components/assets/visacard.png";
import { useSelector } from "react-redux";
import { useState } from "react";
import { MakePayment } from "../../Service/Service";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PaymentModal = ({ open, handleOpenModal, totalAmount,refresh }) => {
  console.log(
    "🚀 ~ file: PaymentModal.js:32 ~ PaymentModal ~ totalAmount",
    totalAmount
  );
  const { customerCardsDetail } = useSelector((s) => s.CardReducer);
  const handleClose = () => {
    handleOpenModal();
  };
  const [loading, setloading] = useState(false);
  const [amount, setAmount] = useState(totalAmount);
  // console.log("🚀 ~ file: PaymentModal.js:39 ~ PaymentModal ~ amount", amount);
  const [creditCardId, setCreditCardId] = useState(customerCardsDetail.length>0&&customerCardsDetail?.find((x) => x?.default === 1)?.id);
  const [errorMessage, setErrorMessage] = useState(false);
  useEffect(() => {
    setAmount(totalAmount);
  }, [totalAmount]);

  const MakepaymentHandler = () => {
    if (!creditCardId) {
      setErrorMessage("Please choose credit card");
    } else if (!amount) {
      setErrorMessage("Please provide amount");
    } else if (amount && amount < 0) {
      setErrorMessage("Amount Should be greater then 0");
    } else {
      setloading(true);
      setErrorMessage();
      MakePayment(creditCardId, amount).then((res) => {
        if (res.original.success === true) {
          handleOpenModal();
          refresh()
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Payment Sucessfull!",
          });
          setloading(false);
        } else {
          setloading(false);
        }
      }).catch((reson)=>{
        setloading(false);
        handleOpenModal();
          refresh()
          console.clear();
        console.log(reson);
        Swal.fire({
          icon: "error",
          title: "Server error",  
        });
      });
    }
  };
  const style = {
    height: "300px"
  };
  const mediaStyle = {
    "@media (max-width: 768px)": {
      ...style,
      height: "unset"
    },
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        sx={{ ...style, ...mediaStyle }}
        maxWidth="md"
      >
        <DialogTitle>
          {"Make transaction"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent maxWidth="lg">
          <div
            className="make-payment-dialog"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <img src={visaCard} />
            </div>
            <Stack spacing={2}>
              <div>
                <InputLabel
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#aab8bd",
                  }}
                >
                  Card:
                </InputLabel>
              </div>
              <div>
                <FormControl variant="standard" sx={{ width: 100 }}>
                  <Select
                    defaultValue={
                      customerCardsDetail?.length > 0 &&
                      customerCardsDetail?.find((x) => x?.default === 1)?.id
                    }
                    // value={creditCardId}
                    onChange={(e) => {
                      setCreditCardId(e.target.value);
                    }}
                    sx={{ width: "220px" }}
                  >
                    {customerCardsDetail?.length > 0 &&
                      customerCardsDetail?.map((data) => (
                        <MenuItem value={data?.id}>
                          {data?.card_type} {data?.last4}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {errorMessage && errorMessage == "Please choose credit card" ? (
                  <Typography
                    style={{ marginTop: 2, fontSize: 12, color: "#c77c7c" }}
                  >
                    {errorMessage}
                  </Typography>
                ) : null}
              </div>
              <Button
                style={{
                  color: "#535353",
                  border: "1px solid #535353",
                  borderRadius: "20px",
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  handleClose();
                  document.getElementById("my-account").click();
                  setTimeout(() => {
                    document
                      .getElementById("add-card")
                      .scrollIntoView({ behavior: "smooth" });
                  }, 500);
                }}
              >
                Add New Card
              </Button>
            </Stack>
            <Stack spacing={2}>
              <div>
                <InputLabel
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#aab8bd",
                  }}
                >
                  Amount:
                </InputLabel>
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  variant="standard"
                  placeholder="0"
                />
                {errorMessage && errorMessage == "Please provide amount" ? (
                  <Typography
                    style={{ marginTop: 2, fontSize: 12, color: "#c77c7c" }}
                  >
                    {errorMessage}
                  </Typography>
                ) : errorMessage == "Amount Should be greater then 0" ? (
                  <Typography
                    style={{ marginTop: 2, fontSize: 12, color: "#c77c7c" }}
                  >
                    {errorMessage}
                  </Typography>
                ) : null}
              </div>
              <Button
                onClick={MakepaymentHandler}
                style={{
                  color: "#535353",
                  border: "1px solid #535353",
                  borderRadius: "20px",
                  fontSize: 12,
                  width: "fit-content",
                  textTransform: "capitalize",
                }}
              >
                {loading ? <Spinner size="sm" /> : <span>Make Payment</span>}
              </Button>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaymentModal;
