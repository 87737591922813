import React from "react";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Password } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { checkemail } from "../../Service/Service";
import { useImperativeHandle } from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Customerinfo = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    childFunction1() {
      return validate();
    },
  }));

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [error, seterror] = useState("");
  const [rederror, setrederror] = useState();

  const handleEvent = (e) => {
    setrederror(9);
    console.log("triggered");
    props.setcustomerinfo({
      ...props.customerinfo,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    console.log(props.customerinfo);

    if (props.customerinfo.firstname === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter firstname");
      setrederror(0);
      return false;
    } else if (props.customerinfo.lastname === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter lastname");
      setrederror(1);
      return false;
    } else if (props.customerinfo.email === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter email");
      setrederror(2);
      return false;
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        props.customerinfo.email
      )
    ) {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("You have entered an invalid email address!");
      setrederror(2);
      return false;
    } else if (props.customerinfo.phone === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter phone no");
      setrederror(3);
      return false;
    } else if (props.customerinfo.password === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter password");
      setrederror(4);
      return false;
    } else if (props.customerinfo.password != props.customerinfo.confirmpass) {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("confirm password doesnt match");
      setrederror(4);
      return false;
    } else if (
      props.customerinfo.pin.length < 4 ||
      props.customerinfo.pin.length > 4
    ) {
      setState({ open: true, vertical: "top", horizontal: "right" });
      seterror("pin should be 4 digit long");
      setrederror(5);
      return false;
    } else if (props.customerinfo.email != "") {
      checkemail(props.customerinfo.email).then((result) => {
       

        if (result.emailCount === 1) {
          setState({ open: true, vertical: "bottom", horizontal: "right" });
         
          setrederror(2);
          seterror("This email is already registered.");

          return false;
        } else {
          props.setActiveStep((prev) => prev + 1);
        }
      });
    }
  };
  return (
    <>
      <form className="myform">
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            name="firstname"
            value={props.customerinfo.firstname}
            error={rederror === 0}
            onChange={handleEvent}
            className="half"
            label="First Name"
            variant="outlined"
            
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Last Name"
            className="half"
            value={props.customerinfo.lastname}
            error={rederror === 1}
            onChange={handleEvent}
            name="lastname"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Email"
            className="full"
            value={props.customerinfo.email}
            error={rederror === 2}
            onChange={handleEvent}
            name="email"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            name="phone"
            value={props.customerinfo.phone}
            error={rederror === 3}
            onChange={handleEvent}
            className="half"
            label="Phone"
            variant="outlined"
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Alternate Phone"
            name="altphone"
            value={props.customerinfo.altphone}
            onChange={handleEvent}
            className="half"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            name="password"
            error={rederror === 4}
            value={props.customerinfo.password}
            onChange={handleEvent}
            className="half"
            label="Password"
            variant="outlined"
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Confirm Password"
            name="confirmpass"
            error={rederror === 4}
            value={props.customerinfo.confirmpass}
            onChange={handleEvent}
            className="half"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            type="number"
            error={rederror === 5}
            value={props.customerinfo.pin}
            name="pin"
            className="half"
            onChange={handleEvent}
            label="Create a 4 digit pin"
            variant="outlined"
          />
          <div className="half">
            <Snackbar
              sx={{ top: 400 }}
              anchorOrigin={{ vertical, horizontal }}
              open={open}
              onClose={handleClose}
              message="I love snacks"
              key={vertical + horizontal}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </Snackbar>
          </div>
        </div>
        
      </form>
    </>
  );
});

export default Customerinfo;
