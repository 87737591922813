import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  LinearProgress,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../assets/bill-phone3.jpg";
import LoadingSpinner from "../../components/assets/loaderspinner.gif";

import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import BasicTabs from "./Tabs";
import { Button, Spinner } from "react-bootstrap";

import {
  customerSubscriptions,
  requestConnection,
} from "../../Service/Service";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BorderStyle, SettingsApplicationsOutlined } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1000,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  width:'95%',
};

const Monthlybilling = ({ inovices,auth }) => {
  const [customerPlansResult, setCustomerPlansResult] = useState([]);
  const [PopulateAllData, setPopulateAllData] = useState();
  const [showEditState, setShowEditState] = useState();
  const [CycleValue, setCycleValue] = useState(202201);
  const [SubcritionID, setSubcritionID] = useState();
  const [UsageType, setUsageType] = useState(1);
  const [monthlyAmountDetails, setmonthlyAmountDetails] = useState([]);
  const [usages, setUsage] = useState([]);
  const { userInfo } = useSelector((state) => state.authReducer);
  // console.log(customerPlansResult);
  const [labelName, setcustomerlabelName] = useState();
  // const [customerFirstName, setcustomerFirstName] = useState(
  //   inovices.auth.userInfo && inovices.auth.userInfo.fname
  // );

  const[loading,setloading] = useState(false);
  function getGB(id) {}
  function addonCharges(subcription) {
    return subcription.subscription_addon_not_removed
      .map((sub) => sub.addons)
      .flat()
      .reduce((sum, addon) => sum + addon.amount_recurring, 0);
  }
  function getUsages(subscriptions) {
    let usagesArr = [];
    subscriptions.forEach((subscription) => {
      let subscription_id = parseInt(subscription.id);
      let arr = subscription.sim_card_num;

      requestConnection(arr).then((res) => {
        if (res.data) {
          usagesArr[subscription_id] = {
            data: res.data,
            voice: res.voice,
            sms: res.sms,
            last_updated: res.updated_at,
          };
        }
      });
    });
    return usagesArr;
  }
  const [open, setOpen] = React.useState(false);
  const [Refresh, setRefresh] = React.useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // console.log("hamza",customerPlansResul)
    setloading(true)
    customerSubscriptions(auth).then((res) => {
      console.log(res);
      if (res) {
        setCustomerPlansResult(res["customer-plans"]);
        setmonthlyAmountDetails(res.monthlyAmountDetails);
        setUsage(getUsages(res["customer-plans"]));
        setloading(false)
        console.log("========result====", res["customer-plans"]);
      }
    });
  }, []);
  const editURL = `${window.location.origin}/dashboard`;
  const [IsLoading, setIsLoading] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const [TotalMin, setTotalMin] = React.useState(0);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getCallLogs(2214);
  }, [UsageType, CycleValue]);

  const getCallLogs = (ID) => {
    console.log("id====>")
    console.log(ID);
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      var user = JSON.parse(localStorage.getItem("user_token"));
      myHeaders.append("AccessToken", "key");
      myHeaders.append("Authorization", "alar324r23423");
      const d = new Date();
      let year = d.getFullYear().toString();
      let month = d.getMonth().toString();
      console.log(month, year);



      const formdata1 = new FormData();
      formdata1.append("sim", ID ? ID : SubcritionID);
      formdata1.append("date", CycleValue);
      formdata1.append("type", UsageType);

      var requestOptions = {
        method: "POST",
        // headers: myHeaders,
        body: formdata1,
        redirect: "follow",
      };

      fetch("https://britex.pw/api/get-usage", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("Get Call logs ----------->>>>", result);
          // resolve(result);
          setPopulateAllData(result);
          let sum = 0;
          result?.data.forEach((ele) => {
            if (UsageType === 1) {
              sum = sum += Number(ele?.ans_time_duration_min);
            } else if (UsageType === 3) {
              sum = sum += Number(ele?.total_volume_mb);
            }
          });
          setTotalMin(sum);
          console.log(sum);
          setIsLoading(false);
        })
        .catch((error) => {
          reject(error);
          console.log("error", error);
        });
    });
  };

  const updateSublabel = (ID, label) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      var user = JSON.parse(localStorage.getItem("user_token"));
      myHeaders.append("AccessToken", "key");
      myHeaders.append("Authorization", "4hO6lcrSaF");
      const formdata = new FormData();
      formdata.append("id", ID);
      formdata.append("label", label);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch("https://britex.pw/api/update-sub-label", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("Update sub label ----------->>>>", result);
          setLoading(false);
            let updatedarr=customerPlansResult.map((obj)=>{
                if(obj.id===ID)
                {   obj.label=label;
                    return obj;

                }
                else{
                  return obj;
                }

            })    
            setCustomerPlansResult(updatedarr);
            setShowEditState();
            setLoading(false);
        })
        .catch((error) => {
          reject(error);
    
        });
    });
  };
  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
      )}
           <div className="monthlybilling">
     
        <h1>Your Bill</h1>
        <p className="chargemonth">
          Charges for {inovices?.billing_start} to {inovices?.billing_end}
          <span>
            You are {userInfo && userInfo.auto_pay == 0 && "not"} enrolled in
            auto-pay.{" "}
            <a
              className="edit"
              onClick={() => {
                document.getElementById("my-account").click();
                setTimeout(() => {
                  document
                    .getElementById("payment")
                    .scrollIntoView({ behavior: "smooth" });
                }, 400);
              }}
            >
              Edit
            </a>
          </span>
        </p>
        <Divider />
        <div className="mygrid">
          <div>
            <h4>Charges</h4>

            <h2>
              ${inovices?.payment && inovices?.payment[0]}
              <span>.{inovices?.payment && inovices?.payment[1]}</span>
            </h2>
          </div>
          <div>
            <h4>Payments/Credits</h4>

            <h2>
              ${inovices?.payment && inovices?.payment[0]}
              <span>.{inovices?.payment && inovices?.payment[1]}</span>
            </h2>
          </div>
          <div>
            <h4>Past Due</h4>

            <h2>
              ${inovices?.past_due && inovices?.past_due[0]}
              <span>.{inovices?.past_due && inovices?.past_due[1]}</span>
            </h2>
          </div>
          <div>
            <h4>Total Due</h4>
            <h2>
              ${inovices?.total && inovices?.total[0]}
              <span>.{inovices?.total && inovices?.total[1]}</span>
            </h2>
            <h4>{inovices?.due_date && inovices?.due_date}</h4>
          </div>
        </div>

        <h1>My Plans</h1>
      
        {customerPlansResult &&
          customerPlansResult.map((value) => (
            <>
              <Divider />

              <div className="myplanflex">
                <div className="myplans border-right">
                  <div className="myplanflex1">
                    <div>
                      <img src={img} />
                    </div>
                    <div>
                      <div>
                        <h2 className="NA1">NA</h2>
                        <h2 className="NA2">{value.phone_number_formatted}</h2>
                      </div>
                      {/* first name */}
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                        style={{ display: "block" }}
                      >
                        <div className="fields">
                          <h4 className="input-label">label</h4>
                          {showEditState && showEditState === value.id ? (
                            <TextField
                              size="small"
                              id="demo-helper-text-aligned"
                              defaultValue={value.label}
                              onChange={(e) =>
                                setcustomerlabelName(e.target.value)
                              }
                            />
                          ) : (
                            <h4>{value.label}</h4>
                          )}
                        </div>
                        {showEditState && showEditState === value.id ? (
                          <Stack direction="row" spacing={2} mb={2}>
                            <Button
                              className="cancel-button"
                              onClick={() => setShowEditState()}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="save-button"
                              onClick={() =>
                                updateSublabel(value.id,labelName?labelName:value.label)
                              }
                            >
                              Save
                            </Button>
                          </Stack>
                        ) : (
                          <Button
                            className="edit-button mb-2"
                            onClick={() => setShowEditState(value.id)}
                          >
                            Edit
                          </Button>
                        )}
                      </Stack>
                    </div>
                  </div>

                  <div className="myplanflex1">
                    <table className="usages">
                      <tbody>
                        <tr>
                          <th>Data Usage</th>
                          <th className="voice-usage">Voice Usage</th>
                          <th>Text Usage</th>
                        </tr>
                        <tr>
                          <th>
                            {usages.data &&
                              (usages[value.id].data / 1024).toFixed(2)}{" "}
                            GB
                          </th>
                          <th>{usages.voice && usages[value.id].voice} MINs</th>
                          <th>{usages.sms && usages[value.id].sms} SMS</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="flexcenter">
                    <button
                      className="usagesbtn"
                      onClick={() => {
                        handleOpen();
                        getCallLogs(value.id);
                        setSubcritionID(value.id);
                      }}
                      type="button"
                    >
                      View Usage
                    </button>
                  </div>
                </div>

                <div className="myplans border-right">
                  <h2 className="NA1">Plan</h2>
                  <h3 className="NA2">{value.plan.name}</h3>

                  <br></br>

                  <h2 className="NA1">Monthly Charges</h2>
                  <h3 className="NA2">
                    ${addonCharges(value) + value.plan.amount_recurring}
                    <span>.00</span>
                  </h3>
                </div>

                <div className="myplans">
                  <h2 className="NA1">IMEI</h2>
                  <h3 className="NA2">TabletOne Plus T-Mobile</h3>
                  <br></br>
                  <h2 className="NA1">SIM</h2>
                </div>
              </div>

              <Divider />
            </>
          ))}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
            padding: "10px 20px",
          }}
        >
          <div>
            <table className="total">
              <tr>
                <td>Subtotal</td>
                <td className="price">
                  ${monthlyAmountDetails && monthlyAmountDetails.subtotal}
                  <sup>.00</sup>
                </td>
              </tr>
              <tr>
                <td>Regulatory Fee</td>
                <td className="price">
                  ${monthlyAmountDetails && monthlyAmountDetails.regulatoryFee}
                  <sup>.00</sup>
                </td>
              </tr>
              <tr>
                <td>State Tax</td>
                <td className="price">
                  ${monthlyAmountDetails && monthlyAmountDetails.stateTax}
                  <sup>.00</sup>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <p className="monthlytotal">
              Monthly Total : $
              <span>
                {monthlyAmountDetails &&
                  monthlyAmountDetails.monthlyTotalAmount}
              </span>
            </p>
          </div>
        </div>
       
      </div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            style={{ display: "flex", marginLeft: "auto", cursor: "pointer" }}
            onClick={handleClose}
          />
          <BasicTabs
            setIsLoading={setIsLoading}
            TotalMin={TotalMin}
            setCycleValue={setCycleValue}
            CycleValue={CycleValue}
            IsLoading={IsLoading}
            PopulateAllData={PopulateAllData}
            setUsageType={setUsageType}
            setOpen={setOpen}
          />
        </Box>
      </Modal>
    </>
  );
};
export default Monthlybilling;
