import React from "react";
import "./Plans.css";
import Group86 from "../../assets/Group86.svg";
import tick from "../../assets/tick.svg";
import Button from "@mui/material/Button";
import Iconarrow from "../../assets/Iconarrow.svg";
import Group263 from "../../assets/Group263.svg";
import Group262 from "../../assets/Group262.svg";
import { Ultra_Mobile, code, Britex } from "../../../environement";
import Group203 from "../../assets/Group203.svg";
import Group201 from "../../assets/Group201.svg";
import Group199 from "../../assets/Group199.svg";
import { useLayoutEffect } from "react";
import {
  closeordergroupapi,
  getAddons,
  getHash,
  getPlans,
  getSims,
  ordergroup,
  postorder,
  addcoupon,
  removecoupon,
  checkAssociatePlans,
} from "../../../Service/Service";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { addanything, addtocart, setLoading } from "../../../actions";
import MenuItem from "@mui/material/MenuItem";
import { useAsyncError } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../src/components/assets/loaderspinner.gif";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  borderRadius: ".3rem",
  minHeight: 600,
  maxWidth: "1000px",
};
const mediaStyle = {
  "@media (max-width: 768px)": {
    ...style,
    width: "95vw",
    minHeight: 400,
    flexDirection: "column",
    maxHeight: "100vh",
  },
};

const Plans = (props) => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [sims, setSims] = useState([]);
  const [ownsimcard, setownsimcard] = useState();
  useLayoutEffect(() => {
    props.setLoading(true);
    console.log("plans associated ", props.cart.cart.plansAssociated);
    if (props.cart.cart.activeGroupId && props.cart.cart.order_groups.find(
      (obj) => obj.id == props.cart.cart.activeGroupId
    ).device ) {
      checkAssociatePlans(
        props.cart.cart.order_groups.find(
          (obj) => obj.id == props.cart.cart.activeGroupId
        ).device.id
      ).then((result) => {
        setPlans(result);
        props.setLoading(false);
      });
    } else {
    const prom=  getPlans().then((result) => {
        setPlans(result);
        props.setLoading(false);
        return true
      });
      console.clear()
      console.log(prom);

      const prom2 = getPlans().then((result) => {
        setPlans(result);
        props.setLoading(false);
        return true
      });

      const prom3 = getPlans().then((result) => {
        setPlans(result);
        props.setLoading(false);
        // console.log(result);
        return true
      });


      Promise.all([prom,prom2,prom3]).then((result)=>{
         debugger
        console.log(result);

      })
     
    }

 

  }, []);

  const [ErrorOnRadio, setErrorOnRadio] = useState(false);
  const [sessionCart, setsessionCart] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [owndeviceSection, setOwndeviceSection] = useState(0);
  const [addons, setAddons] = useState([]);

  const [portdiv, setportdiv] = useState({
    portingyesno: 0,
    portmessage1: 0,
    portmessage2: 0,
    portmessage3: 0,
    portingchecked: 0,
    portnumberchange: 0,
    portnumber: 0,
    areacode: 0,
    areacodenumber: 0,
    areacodenote: 0,
  });

  const [bringowndevice, setbringowndevice] = useState(0);
  const [LengthError, setLengthError] = useState(false);
  const [simnum, setsimnum] = useState(0);
  const [CarrierId, setCarrierId] = useState();
  const [error, setError] = useState(false);
  const [portnumber, setportnumber] = useState();
  const [areacode, setareacode] = useState("");
  const [operatingsystem, setoperatingsystem] = useState();
  const [imei, setimei] = useState();
  const [areacodeerror, setareacodeerror] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const gettinghash = (obj) => {
    return new Promise((resolve, reject) => {
      if (!props.cart.hasOwnProperty("hash")) {
        getHash(obj.id).then((result) => {
          if (result.order_hash) {
            resolve(true);
            props.addanything({ hash: result });
            getSims(obj.id, props.cart.hash.order_hash).then((result) => {
              setSims(result);
            });
          }
        });
      } else {
        getSims(obj.id, props.cart.hash.order_hash).then((result) => {
          resolve(true);
          setSims(result);
        });
      }
    });
  };

  const handleOpen = (obj) => {
    console.log("request =======>");
    // console.log(obj);

    //   if (props.cart.hasOwnProperty("cart"))  {
    //     if(props.cart.cart.hasOwnProperty("order_groups"))
    //     {
    //     var activeGroupId = props.cart.cart.active_group_id;
    //     var orderGroups = props.cart.cart.order_groups;

    //     if (orderGroups.length === 0) {
    //       if (obj.require_device_info === 1) {
    //         setOwndeviceSection(1);
    //       } else {
    //         setOwndeviceSection(0);
    //       }
    //     } else {
    //       if (obj.require_device_info === 1) {
    //         setOwndeviceSection(1);
    //       } else {
    //         setOwndeviceSection(0);
    //       }
    //     }
    //   }
    // }

    setAddons([]);
    setportdiv({
      portingyesno: 0,
      portmessage1: 0,
      portmessage2: 0,
      portmessage3: 0,
      portingchecked: 0,
      portnumberchange: 0,
      portnumber: 0,
      areacode: 0,
      areacodenumber: 0,
      areacodenote: 0,
    });

    setownsimcard();

    gettinghash(obj).then((result) => {
      setportdiv({
        portingyesno: 0,
        portmessage1: 0,
        portmessage2: 0,
        portmessage3: 0,
        portingchecked: 0,
        portnumberchange: 0,
        portnumber: 0,
        areacode: 0,
        areacodenumber: 0,
        areacodenote: 0,
      });
      getAddons(obj.id, props.cart.hash.order_hash).then((result) => {
        if (result.length != 0) {
          setAddons(result);
        }
      });
      if (obj.signup_porting && !obj.area_code) {
        setportdiv({
          ...portdiv,
          portmessage1: 1,
          portmessage2: 1,
          portmessage3: 1,
          portnumber: 1,
        });
      } else if (!obj.signup_porting && obj.area_code) {
        setportdiv({
          ...portdiv,
          portmessage2: 1,
          portingchecked: 2,
          areacode: 1,
        });
      } else if (obj.signup_porting && obj.area_code) {
        setportdiv({
          portingyesno: 1,
          portingchecked: 2,
          portmessage1: 1,
          portmessage2: 1,
          areacode: 1,
          portnumberchange: 1,
          areacodenote: 1,
        });
      } else if (!obj.signup_porting && !obj.area_code) {
      }

      setownsimcard(obj.own_sim_card_option);
      setselectplan(obj);
      // console.log(obj)
      setOpen(true);
    });
  };

  const updatecart = (request, type) => {
    setErrorOnRadio(false);
    if ((ownsimcard === 1 && siminfo[0] !== 1) || siminfo[1] === 1) {
      if (siminfo[1] !== 1 && siminfo[0] !== 1) {
        setErrorOnRadio(true);

        return;
      }
      // if (siminfo[0] !== 1) {
      //   setErrorOnRadio(true);
      //   return;
      // }
      console.log(ownsimcard);

      if (LengthError) {
        return;
      }
      if (error) {
        return;
      }
    }

    if (portdiv.areacode === 1) {
      if (areacode.length < 3) {
        setareacodeerror(1);
        return;
      }
    }

    console.log(ownsimcard, LengthError, error);
    // if (request.id != null) {
    //   var response = postWithPlanId(request);
    // }
    // if (response) {
    //   return false;
    // }

    var arrayData = getData(request);
    // console.log("Array data");
    console.log(arrayData);
    arrayData.sim_id = sims[0].id;

    console.log(arrayData);
    postOrder(request, arrayData).then(() => {
      if (!closeordergroup(type)) {
        // handleClose()
        setIsLoading(true);
        // Swal.fire({
        //       icon: "error",
        //       title: "Item not added try again",

        //     })
      }
    });

    return arrayData;
  };

  const postOrder = (request, arr) => {
    return new Promise((resolve, reject) => {
      if (request.hasOwnProperty("addon")) {
        var addons = [];
        // for (var i = 0; i < request.addon.length; i++) {}
        postorder([...arr, ...addons]).then((result) => {
          resolve(true);
        });
      } else {
        postorder(arr).then((result) => {
          resolve(true);
        });
      }
    });
  };

  const getData = (request) => {
    if (request.buy_sim === "yes") {
      request.sim_type = null;
      request.sim_number = null;
    }

    var data;
    if (request.sim_number) {
      data = {
        plan_id: request.id,
        sim_id: 0,
        order_hash: props.cart.hash.order_hash,
        sim_type: request.sim_num,
        sim_required: request.sim_required,
        paid_monthly_invoice: props.cart.paid_monthly_invoice
          ? props.cart.paid_monthly_invoice
          : null,
        require_device: request.associate_with_device == 2 ? 1 : 0,
      };
    } else {
      data = {
        plan_id: request.id,
        sim_id: request.sim_id,
        order_hash: props.cart.hash.order_hash,
        paid_monthly_invoice: props.cart.paid_monthly_invoice,
        require_device: request.associate_with_device == 2 ? 1 : 0,
      };
    }

    if (request.porting === "yes") {
      data = { ...data, porting_number: request.porting };
    } else if (request.porting === "no") {
      data = { ...data, area_code: request.area_code };
    }

    if (props.cart.customer_hash) {
      data = { ...data, customer_hash: props.cart.customer_hash };
    }

    if (request.bring_own_device) {
      if (request.bring_own_device == "yes") {
        data = {
          ...data,
          operating_system: request.operating_system,
          imei_number: request.imei,
        };
      }
    }
    // if(props.cart.custom)
    return data;
  };

  const postWithPlanId = (plan) => {
    console.log(plan);
    if (!props.cart.hash) {
      postorder({
        plan_id: plan.id,
        paid_monthly_invoice: props.cart.paid_monthly_invoice
          ? props.cart.paid_monthly_invoice
          : null,
      }).then((result) => {
        props.addanything({ hash: result });
      });
    } else {
      postorder({
        plan_id: plan.id,
        order_hash: props.cart.hash.order_hash,
        paid_monthly_invoice: props.cart.paid_monthly_invoice
          ? props.cart.paid_monthly_invoice
          : null,
      }).then((result) => {});
    }
    return false;
  };

  // const validationoptions = () => {
  //   if (!selectyoursim) {
  //     alert("please choose some option");
  //     return false;
  //   }

  //   setselectyoursim(false);
  //   return true;
  // };

  const closeordergroup = (type) => {
    if (type === "plan") {
      ordergroup(1, props.cart.hash.order_hash).then(() => {});
    }

    closeordergroupapi(props.cart.hash.order_hash, null).then((result) => {
      // if(result.order_groups.map((obj)=>{

      //     if(obj.sim==null)
      //     return obj;
      // }).length>0)
      // {
      //   handleClose();
      //   Swal.fire({
      //     icon: "error",
      //     title: "Item no added try again",

      //   })

      // }
      // else{
      // console.log('result====>',result.status)
      if (
        result.order_groups.slice(-1).filter((obj) => {
          if (obj.plan === null) return obj;
        }).length != 0
      ) {
        setIsLoading(false);
        setOpen(false);
        Swal.fire({
          icon: "error",
          title: "Item not added try again",
        });
        return false;
      } else {
        props.addtocart(result);
        handleClose();
        setIsLoading(false);
        if (type === "plan") {
          Swal.fire({
            title: "Success",
            text: `${selectplan.name} has been added to your cart.`,
            denyButtonText: `Checkout`,
            showDenyButton: true,
            denyButtonColor: "#7066e0",
            icon: "success",
            confirmButtonText: "Shop more",
            allowOutsideClick: true,
            allowEscapeKey: false,
            preDeny: () => {
              // processCoupon();
              // getCartOrder();

              if (props.cart.cart.business_verification) {
                navigate("/checkout");
              } else {
                if (props.auth.userInfo.id) {
                  navigate("/checkout");
                } else {
                  if (props.auth.userInfo != null) {
                    if (props.auth.userInfo.id) {
                      navigate("/checkout");
                    }
                  } else {
                    if (props.cart.cart && props.cart.cart.company) {
                      if (
                        props.cart.cart.company.business_verification === 1 &&
                        !props.auth.userInfo.id
                      ) {
                      } else {
                        navigate("/checkout");
                      }
                    }
                  }
                }
              }

              navigate("/checkout");
            },
            preConfirm: () => {},
          });
        } else {
          navigate("/devices", { state: { id: selectplan.id, CarrierId } });
        }
      }
    });
  };

  const CheckCondtion = (Value) => {
    var reg = /^\d+$/;
    if (reg.test(Value) || Value == "") setsimnum(Value);

    if (Value.length < 7) {
      setLengthError(true);
      return;
    }
    setLengthError(false);
    if (CarrierId === 5) {
      checkNumberVefiQuery(Value);
    } else {
      checkNumberVerification(Value);
    }
  };

  const checkNumberVefiQuery = (Value) => {
    setError(false);

    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      var user = JSON.parse(localStorage.getItem("user_token"));
      myHeaders.append("AccessToken", "key");
      myHeaders.append("Authorization", "alar324r23423");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: formdata,
        redirect: "follow",
      };

      fetch(
        `${Ultra_Mobile}/GetSIMDetails/code=${code}==&company_id=1&iccid=${Value}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Invalid Sim Number") {
            setError(true);
          }
        })
        .catch((error) => {
          reject(error);
          console.log("error");
        });
    });
  };

  const checkNumberVerification = (Value) => {
    setError(false);
    return new Promise((resolve, reject) => {
      var myHeaders = new Headers();
      var user = JSON.parse(localStorage.getItem("user_token"));
      myHeaders.append("AccessToken", "key");
      myHeaders.append("Authorization", "alar324r23423");

      var formdata = new FormData();
      formdata.append("sim_number", Value);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${Britex}/validate-sim-num`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Invalid Sim Number") {
            setError(true);
          }
          // setCardDetails(result);
        })
        .catch((error) => {
          reject(error);
          console.log("error");
        });
    });
  };

  const processCoupon = () => {
    var couponCodes = props.cart.couponCodes ? props.cart.couponCodes : [];

    if (couponCodes.length > 0) {
      couponCodes.map((couponCode) => {
        if (couponCode) {
          removecoupon(couponCode, props.auth.userInfo.id).then();

          addcoupon(
            couponCode,
            props.auth.userInfo.id,
            props.cart.order_id
          ).then((result) => {
            if (!result.error) props.addanything({ couponAmount: result });
          });
        }
      });
    }
  };

  const getCartOrder = () => {
    if (props.cart.hash.hasOwnProperty("order_hash")) {
      closeordergroupapi(props.cart.hash.order_hash, null).then((getOrder) => {
        if (!getOrder.order_groups.length > 0) {
          alert(
            "Oops! Something is incorrect, please check your mail again or contact us if the problem still persists."
          );
        } else {
          // props.addanything({hash:})\\
          console.log("order");
          console.log(getOrder);
          props.addtocart(getOrder);

          if (getOrder.couponDetails) {
            props.addanything({ couponAmount: getOrder.couponDetails });

            getOrder.couponDetails.forEach((coupon) => {
              var couponCodes = props.cart.couponCode
                ? props.cart.couponCode
                : [];
              if (coupon.error && !couponCodes.includes(coupon.code)) {
                props.addanything({ couponCodes: coupon.code });
              }
            });
          }

          if (props.cart.status == 1) {
            alert("you have already placed your order");
          }
        }
      });
    } else {
      alert(
        "Oops! Something is incorrect, please <b>Verify Your Business</b> first."
      );
    }
  };

  const handleClose = () => setOpen(false);
  const [selectplan, setselectplan] = useState();
  const [ports, setPorts] = useState([0, 0, 0, 0, 0]);

  const [siminfo, setsiminfo] = useState([0, 0]);
  const [selectyoursim, setselectyoursim] = useState();
  const handleNumType = (evt) => {
    if (
      evt.key !== "Backspace" &&
      evt.key !== "Delete" &&
      (evt.key.length !== 1 || evt.key < "0" || evt.key > "9")
    ) {
      evt.preventDefault();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <div className="plans-main">
        <div className="support-tetx">
          <div className="support-trans">
            <p className="service-p">
              The Service You Want. The Support You Need.
            </p>
            <h1 className="head-p">One Low Monthly Fee.</h1>
            <img className="im86" src={Group86} />
          </div>
        </div>

        <div className="price-text">
          <h1 className="head-h1">Which plan is right for you?</h1>
        </div>

        <div className="pc" id="planSection">
          <div
            className="row gutter"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {plans?.map((obj) => {
              return (
                <>
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 mb-5 "
                    style={{ flexBasis: "30%" }}
                  >
                    <div className="parice-card2">
                      <button className="btnbasic3">{obj?.name}</button>
                      <div className="doll2">
                        <span
                          style={{
                            font: " 800 27px / 40px Nunito Sans",
                          }}
                        >
                          $
                        </span>
                        <span
                          style={{
                            font: " bold 55px / 13px Nunito Sans",
                          }}
                        >
                          {obj?.amount_recurring}
                          <span
                            style={{
                              font: " normal normal 800 29px/1px Nunito Sans",
                            }}
                          >
                            /month
                          </span>
                        </span>
                      </div>
                      <div className="border"></div>
                      <div className="pcardh1">
                        <h1 className="whatsinclude talk1">What’s included?</h1>
                      </div>
                      {obj?.description
                        ?.replace(/<\/?[^>]+(>|$)/g, ",")
                        .split(",")
                        .slice(0, 16)
                        .map((obj, index) => {
                          if (obj?.length > 2)
                            return (
                              <>
                                <div className="talk1">
                                  <img className="tick2" src={tick} />
                                  {index === 11 ? (
                                    <div>
                                      <h1
                                        className="l11"
                                        dangerouslySetInnerHTML={{
                                          __html: obj,
                                        }}
                                      ></h1>
                                      <p className="tagline_para">
                                        {`(Pictures, Maps, Songs `}
                                        {"\n"}
                                        After 2 GB of data, data speeds will
                                        slow.
                                      </p>
                                    </div>
                                  ) : (
                                    <>
                                      <h1
                                        className="l11"
                                        dangerouslySetInnerHTML={{
                                          __html: obj,
                                        }}
                                      ></h1>
                                    </>
                                  )}
                                </div>
                              </>
                            );
                        })}
                      <div className="talk1">
                        {/* <img className="tick2" src={tick} /> */}
                        {/* <h1
                                    className="l11"
                                    dangerouslySetInnerHTML={{ __html: obj }}
                                  ></h1> */}
                      </div>
                      <Button
                        className="buttonplan"
                        onClick={() => {
                          handleOpen(obj);
                          setCarrierId(obj?.carrier_id);
                          setErrorOnRadio(false);
                          setsiminfo([0, 0]);
                          setsimnum(0);
                          setError(false);
                        }}
                        style={{
                          fontFamily: "Nunito Sans",
                          width: "224px",
                          height: "47px",

                          bottom: "47px",
                          position: "absolute",
                          background:
                            "0% 0% no-repeat padding-box padding-box rgb(21, 45, 121)",
                          boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px",
                          borderRadius: "34px",
                          opacity: "1",
                          color: " rgb(255, 255, 255)",
                        }}
                        variant="primary"
                      >
                        Choose this Plan
                        <img
                          className="pricc-btnimg"
                          style={{
                            width: "7px",
                            height: "10px",
                            marginLeft: "10px",
                          }}
                          src={Iconarrow}
                        />
                      </Button>{" "}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <div className="pa1">
          <h1 className="showtext">
            *The totals shown here are costs for your monthly service only. They
            do not include any taxes and fees.
          </h1>
        </div>

        <div className="network-part">
          <div className="network-heading">
            <h1 className="nth">
              Powered by the nation’s strongest 5G network.
            </h1>
            <img
              className="img866"
              style={{
                position: "absolute",
                marginTop: "122px",
                height: "2.9px",
                marginLeft: "71px",
              }}
              src={Group86}
            />
          </div>

          <div className="main-iccon">
            <div className="iicon">
              <div className="iicon-head">
                <img className="iicon-img" src={Group263} />
                <h2 className="hed">Port Your Phone Number</h2>
                <p className="per">
                  Keep your existing number - let friends and family stay in
                  touch.
                </p>
              </div>
            </div>

            <div className="iicon">
              <div className="iicon-head">
                <img className="iicon-img" src={Group262} />
                <h2 className="hed">Free Calls To Canada & Mexico</h2>
                <p className="per">
                  Free international calling - without incurring an additional
                  charge.
                </p>
              </div>
            </div>

            <div className="iicon">
              <div className="iicon-head">
                <img className="iicon-img" src={Group203} />
                <h2 className="hed">Wifi Calling</h2>
                <p className="per">
                  Stay connected to your loved ones - even if the phone service
                  is down.
                </p>
              </div>
            </div>

            <div className="iicon">
              <div className="iicon-head">
                <img className="iicon-img" src={Group201} />
                <h2 className="hed">Mobile Hotspot</h2>
                <p className="per">
                  Use your smartphone as wifi - enable others to access the
                  internet.
                </p>
              </div>
            </div>

            <div className="iicon">
              <div className="iicon-headl">
                <img className="iicon-img" src={Group199} />
                <h2 className="hed">Location Assist</h2>
                <p className="per">
                  Know where your loved ones are - with the click of a button.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "scroll" }}
      >
        <Box sx={{ ...style, ...mediaStyle }}>
          <div className="closeBtn" style={{ display: "none" }}>
            <span onClick={handleClose}>×</span>
          </div>
          <div className="modalleft">
            {selectplan && (
              <>
                <div
                  style={{ display: "flex", marginTop: "5px", fontSize: 19 }}
                >
                  {" "}
                  <h2>{selectplan?.name}</h2>
                </div>

                <div className="innerdiv">
                  <h3 className="text-black">+Select your sim</h3>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(e) => setselectyoursim(e.target.value)}
                    >
                      {ownsimcard == 1 && (
                        <FormControlLabel
                          value="I will bring my own Sim Card"
                          control={<Radio onClick={() => setsiminfo([1, 1])} />}
                          label="I will bring my own Sim Card"
                        />
                      )}

                      <FormControlLabel
                        value="I want to buy one"
                        control={<Radio onClick={() => setsiminfo([1, 0])} />}
                        label="I want to buy one"
                      />
                    </RadioGroup>
                    {ErrorOnRadio && (
                      <FormHelperText style={{ color: "red" }}>
                        Please select your Sim
                      </FormHelperText>
                    )}
                    {/* <Divider /> */}

                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      {sims?.map((obj) => {
                        return (
                          <FormControlLabel
                            value={obj?.name}
                            control={<Radio />}
                            label={obj?.name}
                          />
                        );
                      })}
                    </RadioGroup>

                    {siminfo[1] == 1 && (
                      <TextField
                        size="small"
                        id="outlined-basic"
                        type="text"
                        value={simnum}
                        onChange={(e) => {
                          CheckCondtion(e.target.value);
                          setError(false);
                        }}
                        label="Enter Sim"
                        error={error}
                        helperText={
                          error ? (
                            <>
                              <span>The SIM can't be used</span>
                            </>
                          ) : LengthError ? (
                            <span className="text-danger">
                              Enter at least 7 character
                            </span>
                          ) : (
                            ""
                          )
                        }
                        variant="outlined"
                      />
                    )}
                  </FormControl>
                </div>

                {owndeviceSection == 1 && (
                  <>
                    <div className="innerdiv">
                      <h3>Bringing your own device ?</h3>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Yes"
                            onClick={setbringowndevice(1)}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            onClick={setbringowndevice(2)}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="innerdiv">
                      <h3>Your Device Info: * ?</h3>
                      <FormControl>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Operating System"
                          // value={props.desc}
                          // onChange={(e)=>handleChange(e)}
                          helperText="Please select your operating system"
                          className="w-full sm:w-1/2 md:w-full"
                          onChange={(e) => setoperatingsystem(e.target.value)}
                          required
                        >
                          <MenuItem value="ANDROID">ANDROID</MenuItem>
                          <MenuItem value="BLACKBERRY">BLACKBERRY</MenuItem>
                          <MenuItem value="IOS">IOS</MenuItem>
                          <MenuItem value="NONE">NONE (BASIC PHONE)</MenuItem>
                          <MenuItem value="WINDOWS">WINDOWS</MenuItem>
                        </TextField>

                        <TextField
                          onChange={(e) => setimei(e.target.value)}
                          className="w-full sm:w-1/2 md:w-full"
                          id="outlined-basic"
                          label="IMEI"
                          variant="outlined"
                        />
                        <Typography>
                          Dial *#06# from your device to locate your IMEI
                          number.
                        </Typography>
                      </FormControl>
                    </div>
                  </>
                )}

                {portdiv?.portmessage1 == 1 && (
                  <h4>
                    Will you be porting in your number from your current
                    carrier?
                  </h4>
                )}

                {portdiv?.portmessage2 == 1 && (
                  <p>
                    Please keep in mind, you can not port an existing T-mobile
                    number to our service.
                  </p>
                )}
                {portdiv?.portingyesno == 1 && (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      onClick={() =>
                        setportdiv({ ...portdiv, portingchecked: 1 })
                      }
                      checked={portdiv.portingchecked === 1}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      onClick={() =>
                        setportdiv({ ...portdiv, portingchecked: 2 })
                      }
                      checked={portdiv.portingchecked === 2}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                )}

                {portdiv?.portmessage3 == 1 && (
                  <h3>Please enter the number you would like to port</h3>
                )}

                {portdiv?.areacodenote == 1 && (
                  <h4>
                    If you would like to be assigned a new number, please enter
                    desired area code
                  </h4>
                )}

                {portdiv?.portnumber == 1 && (
                  <TextField
                    value={portnumber}
                    onChange={(e) => setportnumber(e.target.value)}
                    size="small"
                    type="text"
                    id="outlined-basic"
                    maxLength={10}
                    label="Port no"
                    variant="outlined"
                    onKeyDown={handleNumType}
                  />
                )}

                {portdiv?.areacode == 1 && (
                  <TextField
                    value={areacode}
                    error={areacodeerror === 1}
                    onChange={(e) => {
                      e.target.value.length <= 3 && setareacode(e.target.value);
                      setareacodeerror(0);
                    }}
                    size="small"
                    id="outlined-basic"
                    label="Area Code"
                    variant="outlined"
                    onKeyDown={handleNumType}
                    autoComplete="off"
                  />
                )}

                {addons?.length > 0 &&
                  addons?.map((obj) => {
                    return (
                      <>
                        <h3>Add-on Features (Optional)</h3>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value={obj?.name}
                            control={<Radio />}
                            label={
                              obj?.name + " - " + "$" + obj?.amount_recurring
                            }
                          />

                          <div
                            className="pt-2"
                            dangerouslySetInnerHTML={{
                              __html: obj?.description,
                            }}
                          ></div>
                        </RadioGroup>
                      </>
                    );
                  })}
              </>
            )}
          </div>
          <div className="modalright">
            {selectplan && (
              <>
                <h1>${selectplan?.amount_recurring}/month</h1>

                <div className="innerdiv">
                  {" "}
                  {selectplan?.description
                    ?.replace(/<\/?[^>]+(>|$)/g, ",")
                    .split(",")
                    .slice(0, 16)
                    .map((obj) => {
                      if (obj.length > 2)
                        return (
                          <div>
                            <h4 dangerouslySetInnerHTML={{ __html: obj }}></h4>
                          </div>
                        );
                    })}
                </div>
                <div className="btn">
                  {
                  props.cart.cart.activeGroupId ? 
                  
                  
                  props.cart.cart.order_groups.find(
      (obj) => obj.id == props.cart.cart.activeGroupId
    ).device ?
                    <Button
                      variant="contained"
                      onClick={() => {
                        updatecart(selectplan, "plan");
                      }}
                    >
                      Add To Cart 
                    </Button>
                   : 
                    <>
                  
                      <Button
                        variant="contained"
                        onClick={() => {
                          updatecart(selectplan, "plan");
                        }}
                      >
                        Add To Cart w/o Device
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => updatecart(selectplan, "device")}
                      >
                        Add To Cart & Choose Device
                      </Button>
                    </>
                    :
                    <>
                  
                    <Button
                      variant="contained"
                      onClick={() => {
                        updatecart(selectplan, "plan");
                      }}
                    >
                      Add To Cart w/o Device
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => updatecart(selectplan, "device")}
                    >
                      Add To Cart & Choose Device
                    </Button>
                  </>
                  }
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps, { addtocart, addanything, setLoading })(
  Plans
);
