import React from 'react'
import Footer from '../footer/Footer'
import Navbar from '../Navbar'
import Myaccount from './Myaccount'


const MyAccount_main = () => {
  return (
    <>
    <Navbar/>
    <Myaccount/>
    <Footer/>
    </>
  )
}

export default MyAccount_main
