import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { removefromcart } from '../actions';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

 function Cartlist(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(()=>{
    settotal(props.cart.cart.reduce((total,obj)=>total+=obj.amount_recurring,0));
  })
  const [total,settotal] = useState(props.cart.cart.reduce((total,obj)=>total+=obj.amount_recurring,0))

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450 }}
      role="presentation"
    
      onKeyDown={toggleDrawer(anchor, false)}
    >

  
      <List className='cartlist' >
      <button className='cross'  onClick={toggleDrawer(anchor, false)}><CloseIcon/></button>
            <h2>Your Cart</h2>
        {props.cart.cart.order_groups.map((obj, index) => (
          <>
          <div>
               <div > <h4>{obj.plan.name}</h4></div>
               <div><p>${obj.plan.amount_onetime}</p></div>
                <div onClick={()=>props.removefromcart(obj.plan.name)} ><CloseIcon fontSize='small' /></div>
          </div>
          <Divider />
          <div>
          <div > <h4>{obj.sim.name}</h4></div>
               <div><p>${obj.sim.amount_w_plan}</p></div>
                <div onClick={()=>props.removefromcart(obj.plan.name)} ><CloseIcon fontSize='small' /></div>
          </div>
          </>
        ))}
      </List>
      
      <List>
      <div className='myflex'>
            <div>Subtotal:</div>
            <div>${total}.00</div>
          </div>
          <div className='myflex'>
            <div>Shipping:</div>
            <div>+ $0.00</div>
          </div>
          <div className='myflex'>
            <div>Coupons:{props.cart.coupon.map((obj)=><small>{obj.code}</small>)}</div>
            <div>- ${props.cart.coupon.reduce((total,obj)=>total+=obj.amount,0)}.00</div>
          </div>
          <div className='myflex'>
            <div>State Tax:</div>
            <div>+ $0.00</div>
          </div>
          <div className='myflex'>
            <div>Regulatory Fee:</div>
            <div>+ $0.00</div>
          </div>
      </List>
      <Divider />
        <div className='myflex'>
        <h3>Total: ${(total-props.cart.coupon.reduce((total,obj)=>total+=obj.amount,0)).toFixed(2)}</h3>
      <Button variant="contained" color="success" onClick={()=>navigate("/checkout")}>Checkout</Button>
      </div>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button style={{color:"transparent"}} onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={toggleDrawer(anchor, false) }
       
      >
          <Drawer
    
              variant="persistent"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            
          >
            {list(anchor)}
          </Drawer>
          </ClickAwayListener>
        </React.Fragment>
      ))}
    </div>
  );
}

const mapStateToProps= (state)=>
{

        return{
        cart : state.cart
            }
    
}


export default connect(mapStateToProps,{removefromcart})(Cartlist);