import React, { useEffect, useState } from "react";
import "./checkout.css";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { removefromcart } from '../../actions';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from 'react-router-dom';


const Summary = (props) => {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    
      const navigate = useNavigate();
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

      const [total,settotal]=useState(props.cart.cart.reduce((subtotal,obj)=>subtotal+=obj.amount_recurring,0));
      useEffect(()=>{
        settotal(props.cart.cart.reduce((total,obj)=>total+=obj.amount_recurring,0));
      })
        

  return (
    <><Box
sx={{ width:  450,padding:"40px 10px",border:"1px solid black" }}
role="presentation"


>


<List className='cartlist'  >

    
  {props.cart.cart.map((text, index) => (
    <>
    <div >
         <div > <h4>{text.name.substring(0,8)}</h4></div>
         <div><p>${text.amount_recurring}/Month</p></div>
         <div onClick={()=>props.removefromcart(text.name)} ><CloseIcon fontSize='small' /></div>
    </div>
    <Divider />
    </>
  ))}
</List>

<List>
<div className='myflex'>
      <div>Subtotal:</div>
      <div>${total}.00</div>
    </div>
    <div className='myflex'>
      <div>Shipping:</div>
      <div>+ $0.00</div>
    </div>
    <div className='myflex'>
      <div>Coupons:{props.cart.coupon.map((obj)=><small>{obj.code}</small>)}</div>
      <div>- ${props.cart.coupon.reduce((total,obj)=>total+=obj.amount,0)}.00</div>
    </div>
    <div className='myflex'>
      <div>State Tax:</div>
      <div>+ $0.00</div>
    </div>
    <div className='myflex'>
      <div>Regulatory Fee:</div>
      <div>+ $0.00</div>
    </div>
    <div className='myflex'>
      <div>Regulatory Fee:</div>
      <div>+ $0.00</div>
    </div>
</List>
<Divider />
  <div className='myflex'>
  <h3>Total due today:</h3>
    <h3>${total-props.cart.coupon.reduce((total,obj)=>total+=obj.amount,0)}</h3>
</div>
</Box>  </>
  );
};

const mapStateToProps= (state)=>
{

        return{
        cart : state.cart
            }
    
}


export default connect(mapStateToProps,{removefromcart})(Summary);

