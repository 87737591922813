import React, { useRef, useState } from "react";
import LoadingSpinner from "../../components/assets/loaderspinner.gif";
import {
  addbillingcard,
  addcoupon,
  chargenewcard,
  checkmonthlyinvoices,
  closeordergroupapi,
  createcustomer,
  createCustomerAPI,
  createDeviceRecord,
  createSimRecord,
  createsubscription,
  createsubscriptionaddon,
  generateOneTimeInvoice,
  getcustomercards,
  getCustomerCart,
  getStateTax,
  getTaxrate,
  paymentfailed,
  updatesubscription,
} from "../../Service/Service";
import { addanything, addtocart, emptymycart, signOut, updateCart, updateState } from "../../actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { IMaskInput, useIMask } from "react-imask";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel } from "@mui/material";
import updateReducer from "../../reducers/updateReducer";
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000 0000 0000 0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MMYY = React.forwardRef(function MMYY(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

MMYY.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Payment = (props) => {
  const [newcard, setnewcard] = useState({
    open: 0,
    cardholdername: "",
    cardnumber: "",
    cvv: "",
    mmyy: "",
    customer_card: "customer_card",
  });
  const [error, seterror] = useState({});
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [res, setres] = useState({
    subscription_id: [],
    same_subscription_id: [],
    subscription_addon_id: [],
    new_subscription_addon_id: [],
    customer_standalone_device_id: [],
    customer_standalone_sim_id: [],
  });
  const handleEvent = (e) => {
    const { name, value } = e.target;
    setnewcard((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [agreement, setagreement] = useState(false);


  const [request, setrequest] = useState({
    billing_fname: props.auth.userInfo.billing_fname,
    billing_lname: props.auth.userInfo.billing_lname,
    billing_address1: props.auth.userInfo.billing_address1,
    billing_address2: props.auth.userInfo.billing_address2,
    billing_city: props.auth.userInfo.billing_city,
    billing_state_id: props.auth.userInfo.billing_state_id,
    billing_zip: "54000",
    coupon: null,
    customer_card: 536,
    autopay: 1,
    payment_card_no: null,
    payment_card_holder: "Rana Gulraiz",
    expires_mmyy: "07/23",
    payment_cvc: "999",
  });

  const checkout = () => {
    console.log(request);
    setloading(true);

    if (props.cart.cart.order_groups.length == 0) {
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "No Item in cart",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    } else if (request.customer_card || !request.customer_card) {
      // setloading(false);
      if (checked == "new" ? validation() : true) {
        // closeordergroupapi(props.cart.hash.order_hash, null).then((result) => {
        //   if (result.order_groups.length) {
        //     props.addanything({ cart: result });
        //   }
        // });

        checkmonthlyinvoices(
          props.auth.userInfo.id,
          props.auth.userInfo.hash
        ).then((result) => {
          if (localStorage.getItem("paid_monthly_invoice") != result[0]) {
            props.signOut();
            localStorage.clear();
            alert("Session Expired!!! Payment NOT processed, Please try again");
            setloading(false);
            navigate("/");
          }
        });

        if (props.cart.changePlanStatus === "Downgrade") {
          updateSubscription();
          generateInvoice();
          // navigate("/confirmation");
        } else {
          creditCardApi().then((result) => {
            if (result == 1) {
             
              setloading(false);
              Swal.fire({
                icon: "success",
                title: "Order placed successfully",
              }).then(() => {
                // props.emptymycard()
                navigate("/confirm", { state: { finaldata: props.cart } });
              });
            }
          });
        }

        // subscriptionApi();
      }
    }
  };


  const creditCardApi = () => {
    return new Promise((resolve, reject) => {
      if (props.auth.userInfo.id) {
        createCustomerAPI(
          props.auth.userInfo.id,
          props.cart.hash.order_hash
        ).then(() => {
          var subtotalPrice = totalprice();
          console.log("sub total price");
          console.log(subtotalPrice);

          if (checked == "new") {
            request.customer_card = "customer_card";
            request.expires_mmyy = newcard.mmyy;
            request.payment_card_holder = newcard.cardholdername;
            request.payment_card_no = newcard.cardnumber;
            request.payment_cvc = newcard.cvv;
            request.billing_fname = props.billinginfo.firstname;
            request.billing_lname = props.billinginfo.lastname;
            request.billing_address1 = props.billinginfo.address1;
            request.billing_address2 = props.billinginfo.address2;
            request.billing_state_id = props.billinginfo.state;
            request.billing_zip = props.billinginfo.zipcode;
            request.billing_city = props.billinginfo.city;
          } else {
            request.customer_card = card[checked].id;
            request.expires_mmyy = card[checked].expiration;
            request.payment_card_holder = card[checked].cardholder;
            request.payment_card_no = card[checked].cardholder;
            request.payment_cvc = null;
          }

          var data = {
            ...request,
            amount: props.cart.cart.totalPrice,
            order_hash: props.cart.hash.order_hash,
          };

          if (props.auth.userInfo.id) {
            data.customer_id = props.auth.userInfo.id;
          } else if (props.cart) {
            data.customer_id = props.auth.userInfo.id;
          }

          chargenewcard(data).then((results) => {
            if (results.message) {
              setloading(false);
              if (
                results.message.includes(
                  "validation.credit_card.card_expiration_date_invalid"
                )
              ) {
                Swal.fire({
                  icon: "Error",
                  title: "Error Adding Card",
                  text: "Please enter correct card expiration",
                });
              } else {
                Swal.fire({
                  icon: "Error",
                  title: "Error Adding Card",
                  text: "Please enter correct card number",
                });
                resolve(0);
              }
            } else {
              subscriptionApi().then((result) => {
                chargenewcardvar = results;
                // resolve(1);
              });
              
              standalonesApi();
              
              Promise.all(dynamicPromises).then((result)=>{
                console.log(result);
                const tmp = {
                  subscription_id: result.filter(obj=>obj.subscription_id).length>0?result.filter(obj=>obj.subscription_id).map(obj=>obj.subscription_id):null,
                  same_subscription_id: [],
                  subscription_addon_id: [],
                  customer_standalone_device_id:result.filter(obj=>obj.device_id).length>0?result.filter(obj=>obj.device_id).map(obj=>obj.device_id):null
                };
                console.log(JSON.stringify(props.cart.cart.couponDetails));
                const invoiceitem = {
                  data_to_invoice: tmp,
                  customer_id: props.auth.userInfo.id,
                  hash: props.cart.hash.order_hash,
                  // 'order_id'              : session('cart')['id'],
                  auto_generated_order: props.cart.paid_monthly_invoice,
                  coupon: JSON.stringify(props.cart.cart.couponDetails),
                  auto_generated_order: 0,
                };

                generateOneTimeInvoice(invoiceitem).then((api) => {
                  if (api.hasOwnProperty("order_num")) {
                    localStorage.setItem("order_num", api.order_num);
                    return resolve(true);
                  }
                });
               
              });



            }
          });
          var chargenewcardvar;
          if (subtotalPrice > 0) {
          } else if (props.cart.changePlanStatus === "sameplan") {
            updateSubscription("sameplan");
            generateInvoice("sameplan");
          } else if (props.cart.changePlanStatus === "Upgrade") {
            updateSubscription("for-upgrade");
            generateInvoice("for-upgrade");
            chargenewcardvar = null;
          }
        });
      }

      return chargenewcard;
    });
  };

  const totalprice = () => {
    if (props.cart.hasOwnProperty("total_price")) {
      props.addanything({ total_price: 0 });
    }

    const activegroupid = props.cart.cart.active_group_id
      ? props.cart.cart.active_group_id
      : null;

    let prices = 0;
    let activation = 0;
    let shippingfee = 0;
    let regulatoryfee = 0;
    let coupounAmount = 0;
    let taxes = 0;
    if (props.cart != null) {
      if (props.cart.cart.order_groups.length > 0) {
        props.cart.cart.order_groups.forEach((cart) => {
          if (cart.device != null) {
            if (cart.plan == null) {
              if (cart.id == activegroupid) {
                prices = prices + cart.device.amount_w_plan;
              } else {
                prices = prices + cart.device.amount;
              }
            } else {
              prices = prices + cart.device.amount_w_plan;
            }
          }

          if (cart.plan != null) {
            if (cart.plan.amount_onetime && cart.plan.amount_onetime > 0) {
              activation = activation + cart.plan.amount_onetime;
            }
          }

          if (cart.plan_prorated_amt) {
            prices = prices + cart.plan_prorated_amt;
          } else {
            prices =
              prices + (cart.plan != null ? cart.plan.amount_recurring : 0);
          }

          if (cart.sim != null && cart.plan != null) {
            prices = prices + cart.sim.amount_w_plan;
          } else if (cart.sim != null && cart.plan == null) {
            prices = prices + cart.sim.amount_alone;
          }

          if (cart.addons != null) {
            cart.addons.forEach((addon) => {
              if (addon.prorated_amt != null) {
                prices = prices + addon.prorated_amt;
              } else {
                prices = prices + addon.amount_recurring;
              }
            });
          }

          if (cart.device) {
            if (cart.device.shipping_fee != null) {
              shippingfee = shippingfee + cart.device.shipping_fee;
            }
            if (cart.sim) {
              if (cart.sim.shipping_fee) {
                shippingfee = shippingfee + cart.device.shipping_fee;
              }
            }
          }

          if (cart.plan && cart.status) {
            if (cart.plan.regulatory_fee_type == 1) {
              regulatoryfee = regulatoryfee + cart.plan.regulatory_fee_amount;
            } else if (cart.plan.regulatory_fee_type == 2) {
              if (cart.plan_prorated_amt != null) {
                regulatoryfee =
                  regulatoryfee +
                  (cart.plan.regulatory_fee_amount * cart.plan_prorated_amt) /
                    100;
              } else {
                regulatoryfee =
                  regulatoryfee +
                  (cart.placeholder.regulatory_fee_amount *
                    cart.plan.amount_recurring) /
                    100;
              }
            }
          }

          taxes = taxes + calTaxableItems(cart, null);
        });
      }
    }

    if (props.cart.couponAmount) {
      props.cart.couponAmount.forEach((coupon) => {
        if (coupon.total) {
          coupounAmount = coupounAmount - coupon.total;
        }
      });
    }
    props.addanything({ tax_total: taxes });

    return prices + taxes + activation + shippingfee + regulatoryfee;
  };

  const calTaxableItems = (cart, taxId) => {
    var stateId = "";

    if (!taxId) {
      if (
        props.cart.cart.business_verification &&
        props.cart.cart.business_verification.billing_state_id
      ) {
        props.addanything({
          tax_id: props.cart.cart.business_verification.billing_state_id,
        });
      } else if (
        props.cart.cart.customer &&
        props.cart.cart.customer.billing_state_id
      ) {
        props.addanything({
          tax_id: props.cart.cart.customer.billing_state_id,
        });
      }
    } else {
      props.addanything({ tax_id: taxId });
    }

    stateId = [{ tax_id: props.cart.tax_id }];

    let taxrate;
    getTaxrate(props.cart.tax_id).then((result) => {
      taxrate = result;
      if (
        !props.cart.taxrate ||
        (props.cart.taxrate &&
          taxrate.tax_rate &&
          props.cart.taxrate != taxrate.tax_rate)
      ) {
        getTaxrate(stateId).then((result) => {
          props.addanything({ taxrate: result.tax_rate ? result.tax_rate : 0 });
        });
      }
    });

    var taxpercentage = props.cart.taxrate / 100;

    if (cart.status && cart.status == "SamePlan") {
      var addons = addTaxesToAddons(cart, taxpercentage);
      return addons;
    }
    if (props.cart.status && props.cart.status == "Upgrade") {
      var plans = addTaxesToPlans(cart, cart.plan, taxpercentage);
      var addons = addTaxesToAddons(cart, taxpercentage);
      return plans + addons;
    }

    var devices = addTaxesDevices(cart, cart.device, taxpercentage);

    var sims = addTaxesSims(cart, cart.sim, taxpercentage);

    var plans = addTaxesToPlans(cart, cart.plan, taxpercentage);

    var addons = addTaxesToAddons(cart, taxpercentage);

    return devices + sims + plans + addons;
  };

  const addTaxesSims = (cart, item, taxPercentage) => {
    var itemTax = 0;

    if (item && item.taxable) {
      var amount = cart.plan != null ? item.amount_w_plan : item.amount_alone;

      if (props.cart.couponAmount) {
        var discounted = getCouponPrice(props.cart.couponAmount, item, 3);
        amount = amount > discounted ? amount - discounted : 0;
        itemTax = itemTax + taxPercentage * amount;
      }
    }

    return itemTax;
  };

  const addTaxesDevices = (cart, item, taxpercentage) => {
    var itemtax = 0;
    if (item && item.taxable) {
      var amount = props.cart.plan != null ? item.amount_w_plan : item.amount;

      if (props.cart.couponAmount) {
        var discounted = getCouponPrice(props.cart.couponAmount, item, 2);

        amount = amount > discounted ? amount - discounted : 0;
      }
      itemtax = taxpercentage * amount;
    }

    return itemtax;
  };

  const addTaxesToPlans = (cart, item, taxpercentage) => {
    var plantax = 0;
    if (item != null && item.taxable) {
      var amount =
        cart.plan_prorated_amt != null
          ? cart.plan_prorated_amt
          : item.amount_recurring;
      amount =
        item.amount_onetime != null ? amount + item.amount_onetime : amount;

      if (props.cart.couponAmount) {
        var discounted = getCouponPrice(props.cart.couponAmount, item, 1);
        amount = amount > discounted ? amount - discounted : 0;
      }
      plantax = plantax + taxpercentage * amount;
    }

    return plantax;
  };

  const addTaxesToAddons = (cart, taxpercentage) => {
    var addonTax = 0;
    if (cart.addons) {
      cart.addons.forEach((addon) => {
        if (addon.taxable === 1) {
          var amount =
            addon.prorated_amt != null
              ? addon.prorated_amt
              : addon.amount_recurring;
          if (props.cart.couponAmount) {
            var discounted = getCouponPrice(props.cart.couponAmount, addon, 4);
            amount = amount > discounted ? amount - discounted : 0;
          }
          addonTax = addonTax + taxpercentage * amount;
        }
      });
    }
    return addonTax;
  };

  const getCouponPrice = (couponData, item, itemType) => {
    var productDiscount = 0;

    couponData.forEach((coupon) => {
      var type = coupon.coupon_type;
      if (type == 1) {
        var appliedTo = coupon.applied_to.applied_to_all;
      } else if (type == 2) {
        var appliedTo = coupon.applied_to.applied_to_types;
      } else if (type == 3) {
        var appliedTo = coupon.applied_to.applied_to_products;
      }

      if (appliedTo.length > 0) {
        appliedTo.forEach((product) => {
          if (
            product.order_product_type === itemType &&
            product.order_product_id === item.id
          ) {
            productDiscount = productDiscount + product.discount;
          }
        });
      }
    });

    return productDiscount;
  };

  const standalonesApi = () => {
    setDataValue();
    if (props.auth.userInfo.id) {
      setdata([...data, { customer_id: props.auth.userInfo.id }]);
    } else if (props.auth.cart.length > 0) {
      setdata([...data, props.cart.customer_id]);
    }

    props.cart.cart.order_groups.forEach((cart) => {
      if ((cart.plan === null) & (cart.device != null)) {
        var deviceCoupons = getCouponData(card.id);
        var couponData = [];

        deviceCoupons.forEach((deviceCoupon) => {
          couponData.push({
            code: deviceCoupon.code,
            amount: deviceCoupon.amount,
            description: deviceCoupon.code,
          });
        });

       const standalonepromise= createDeviceRecord(
          { device_id: cart.device.id ,
           imei: cart.imei_number ,
            customer_id:props.auth.userInfo.id,
            order_id:props.cart.hash.id
          }
        ).then((deviceRecord) => {
          if (deviceRecord.hasOwnProperty("device_id")) {
            res.customer_standalone_device_id.push(deviceRecord.device_id);
            return {device_id:deviceRecord.device_id}
          }
        });

        dynamicPromises.push(standalonepromise);

      }

      

      if (cart.plan === null && cart.sim != null) {
        var simcoupons = getCouponData(cart.id);
        var couponData = [];

        simcoupons.forEach((coupon) => {
          couponData.push({
            code: coupon["code"],
            amount: coupon["amount"],
            description: coupon["code"],
          });
        });

        const standalonepromise= createSimRecord([
          ...couponData,
          { sim_id: cart.sim.id },
          { sim_num: cart.sim_num },
        ]).then((simRecord) => {
          if (simRecord.hasOwnProperty("sim_id")) {
            res.customer_standalone_sim_id.push(simRecord.sim_id);
            return {sim_id:simRecord.sim_id}
          }
        });

        dynamicPromises.push(standalonepromise);
      }
    });
    return res;
  };

  const dynamicPromises = [];

  const subscriptionApi = () => {
    return new Promise((resolve, reject) => {
      let data = {};

      props.cart.cart.order_groups.map((cart) => {
  
        if (cart.plan != null) {
          if (
            cart.plan.id != null &&
            !cart.plan.hasOwnProperty("auto_generated_plans")
          ) {
            data = {
              api_key: "alar324r23423",
              order_id: props.cart.cart.id,
              device_id: cart.device?cart.device.id:null,
              plan_id: cart.plan.id,
              sim_id: cart.sim != null && cart.sim.id,
              sim_num: cart.sim_num,
              sim_type: cart.sim_type,
              porting_number: cart.porting_number,
              area_code: cart.area_code,
              operating_system: props.cart.cart.operating_system,
              imei_number: props.cart.cart.imei_number,
              subscription: cart.subscription,
              status: cart.status ? cart.status : null,
            };

            let subscriptionCoupons = getCouponData(cart.id);
            let coupondata = [];

            subscriptionCoupons.forEach((subscriptionCoupon) => {
              coupondata.push({
                code: subscriptionCoupon.code,
                amount: subscriptionCoupon.amount,
                description: subscriptionCoupon.code,
              });
            });

            data.coupon_data = JSON.stringify(coupondata);

            const standalonepromise= createsubscription(data).then((subscription) => {
              if (subscription.success === 1) {
                props.addanything({
                  subscription_id: subscription.subscription_id,
                });
                localStorage.setItem("subid", subscription.subscription_id);
                return {subscription_id:subscription.subscription_id};
       
              }

              if (subscription.hasOwnProperty("subscription_id")) {
                res.subscription_id.push(subscription.subscription_id);

                props.cart.addons &&
                  props.cart.addons.forEach((addon) => {
                    let subscriptionAddon = subscriptionAddonApi(
                      subscription.subscription_id,
                      addon.id,
                      addon.subscription_addon_id,
                      addon.subscription_id,
                      props.cart.plan.id
                    );

                    if (subscriptionAddon) {
                      if (
                        subscriptionAddon.hasOwnProperty(
                          "subscription_addon_id"
                        )
                      ) {
                        res.subscription_addon_id.push(
                          subscriptionAddon.subscription_addon_id
                        );
                      }
                    }
                  });
              } else if (subscription.hasOwnProperty("same_subscription_id")) {
                res.same_subscription_id.push(
                  subscription.same_subscription_id
                );

                props.cart.addons.forEach((addon) => {
                  let subscriptionAddon = subscriptionAddonApi(
                    subscription.subscription_id,
                    addon.id,
                    addon.subscription_addon_id,
                    addon.subscription_id,
                    props.cart.plan.id
                  );

                  if (subscriptionAddon) {
                    if (
                      subscriptionAddon.hasOwnProperty("subscription_addon_id")
                    ) {
                      res.subscription_addon_id.push(
                        subscriptionAddon.subscription_addon_id
                      );
                    }
                  }
                });
              }
            });

            dynamicPromises.push(standalonepromise);

            if (props.cart.hasOwnProperty("status")) {
            }
          }
     
        }
        else{
          const invoiceitem = {
            // data_to_invoice: tmp,
            customer_id: props.auth.userInfo.id,
            hash: props.cart.hash.order_hash,
            // 'order_id'              : session('cart')['id'],
            auto_generated_order: props.cart.paid_monthly_invoice,
            coupon: JSON.stringify(props.cart.cart.couponDetails),
            auto_generated_order: 0,
          };

          generateOneTimeInvoice(invoiceitem).then((api) => {
            if (api.hasOwnProperty("order_num")) {
              localStorage.setItem("order_num", api.order_num);
              return resolve(true);
            }
          });
        }
      });
    });
  };

  const subscriptionAddonApi = (
    subscriptionid,
    addonId,
    subscriptionAddonId,
    addonSubscriptionid,
    planid = 0
  ) => {
    setDataValue();
    let tmpdata = [
      ...data,
      {
        subscription_id: subscriptionid,
        addon_id: addonId,
        subscription_addon_id: subscriptionAddonId,
        addon_subscription_id: addonSubscriptionid,
        plan_id: planid,
      },
    ];

    createsubscriptionaddon(tmpdata).then((response) => {
      return response;
    });
  };

  const setDataValue = () => {
    if (props.cart.length > 0) {
      setdata([...data, { order_id: props.cart.id }]);
    }
  };

  const getCouponData = (cartId) => {
    let coupondata =
      props.cart.couponAmount != null ? props.cart.couponAmount : [];
    let productDiscount = [];

    coupondata.forEach((coupon) => {
      let tempAmount = 0;

      if (coupon.applied_to.applied_to_all != null) {
        let types = coupon.applied_to.applied_to_all;
        types.forEach((type) => {
          if (
            type.hasOwnProperty("order_group_id") &&
            type.order_group_id === cartId
          ) {
            tempAmount = tempAmount + type.discount;
          }
        });
      } else if (coupon.applied_to.applied_to_types != null) {
        let types = coupon.applied_to.applied_to_types;
        types.forEach((type) => {
          if (
            type.hasOwnProperty("order_group_id") &&
            type.order_group_id == cartId
          ) {
            tempAmount = tempAmount + type.discount;
          }
        });
      } else if (coupon.applied_to.applied_to_products != null) {
        let types = coupon.applied_to.applied_to_products;
        types.forEach((type) => {
          if (
            type.hasOwnProperty("order_group_id") &&
            type.order_group_id == cartId
          ) {
            tempAmount = tempAmount + type.discount;
          }
        });
      }

      productDiscount.push({
        amount: tempAmount,
        code: coupon.code,
      });
    });

    return productDiscount;
  };

  const updateSubscription = (status = "downgrade-scheduled") => {
    props.cart.cart[0].order_groups.forEach((element) => {
      if (element.plan.id != null) {
        const data = {
          order_hash: props.auth.order_hash,
          id: element.subscription.id,
          new_plan_id: element.plan.id,
          updgrate_downgrade_status: status,
          order_group: props.cart.cart[0].order_group[0].id,
        };

        updatesubscription(data).then((result) => {});

        if (status === "for-upgrade") {
          return false;
        }
      }
    });
  };

  const generateInvoice = (type = "downgrade-scheduled") => {
    const invoicedata = {
      customer_id: props.auth.id,
      order_hash: props.auth.order_hash,
      status: "Without Payment",
      order_groups: props.cart.cart.order_groups,
      type: type,
    };

    generateOneTimeInvoice(invoicedata).then((result) => {
      if (result.order_num) {
        localStorage.setItem("order_num", result.order_num);
      }
      localStorage.setItem("check_done", true);
    });
  };

  const validation = () => {
    let errors = {};
    console.log(props.billinginfo);
    if (
      props.billinginfo.firstname === null ||
      props.billinginfo.lastname === null ||
      props.billinginfo.address1 == null ||
      props.billinginfo.zipcode == null
    ) {
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "Billing address is missing",
      });
      // seterror(1);
      errors.nameOnCard = 1;
      // return 0;
    } else if (newcard.cardholdername === "") {
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "Please enter cardholder name",
      });
      // seterror(1);
      errors.nameOnCard = 1;
      // return 0;
    }
    if (newcard.cardnumber === "" && newcard.cardnumber.length < 16) {
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "Please enter card number",
      });
      // seterror(2);
      errors.numOnCard = 2;
      // return 0;
    }
    if (newcard.mmyy === "" && newcard.mmyy.length < 4) {
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "Please enter card expiry",
      });
      // seterror(3);
      errors.expiryOnCard = 3;
      // return 0;
    }
    if (newcard.cvv === "" && newcard.cvv.length < 3) {
      setloading(false);
      Swal.fire({
        icon: "error",
        title: "Please enter card cvv",
      });
      // seterror(4);
      errors.cvvOnCard = 4;
      // return 0;
    }
    if (Object.keys(errors).length !== 0) {
      seterror(errors);
      return 0;
    } else {
      seterror({});
      return 1;
    }
  };

  const [card, setcard] = useState([]);
  const [checked, setchecked] = useState(0);
  useEffect(() => {
    // getcustomercards()
    if (props.auth.userInfo && props.auth.userInfo.hasOwnProperty("id")) {
      getcustomercards(props.auth.userInfo.id).then((result) => {
        setcard(result);
        if (result.length === 0) {
          setchecked("new");
        }
      });
    }
  }, [props.auth.userInfo]);

  const [loading, setloading] = useState(false);

  const [coupon, setcoupon] = useState("");




  const applycoupon = () => {
    setcoupon("");

    addcoupon(
      coupon,
      props.auth.userInfo.id,
      props.cart.hash.id,
      props.auth.userInfo.hash
    ).then((couponAmount) => {
      if (!couponAmount.error) {
        getCustomerCart(props.auth.userInfo.id).then((cart) => {
          if (cart.hasOwnProperty("order_hash")) {
            console.log("chalaa");
            props.addanything({
              hash: { id: cart.id, order_hash: cart.order_hash },
            });
          }

     
          props.updateState(props.updateReducer+1)
          props.addtocart(cart);
          setcoupon("");
        });

        let couponCodes = props.cart.hasOwnProperty("couponCodes")
          ? props.cart.couponCodes
          : [];
        let couponAmounts = props.cart.hasOwnProperty("couponAmount")
          ? props.cart.couponAmount
          : [];

        if (!couponCodes.includes(couponAmount.code)) {
          couponCodes.push(couponAmount.code);
          props.addanything({ couponCodes: couponCodes });
        }

        let couponAmountAlreadyExists = false;
        let tempCouponAmount = [];
        couponAmounts.forEach((coupon) => {
          if (coupon.code === couponAmount.code) {
            couponAmountAlreadyExists = true;
            tempCouponAmount.push(couponAmount);
          } else {
            tempCouponAmount.push(coupon);
          }
        });

        if (!couponAmountAlreadyExists) {
          couponAmounts.push(couponAmount);
          props.addanything({ couponAmount: couponAmounts });
        } else {
          props.addanything({ couponAmount: tempCouponAmount });
        }
        if (couponAmount.code == coupon) {
          Swal.fire({
            icon: "success",
            title: "Coupon Added Successfully",
          });
        }

        return couponAmount;
      } else {
        Swal.fire({
          icon: "error",
          title: "Not a valid coupon",
        });
      }
    });
  };

  const [toggle, settoggle] = useState(
    props.auth.userInfo.hasOwnProperty("billing_fname") ? true : false
  );
  const myDivRef = useRef(null);
  const scrollToAndFocusDiv = () => {
    if (myDivRef.current) {
      const offset = -120; // Adjust this value to the desired offset
      const scrollToPosition = myDivRef.current.offsetTop + offset;

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });

      myDivRef.current.focus();
    }
  };

  useEffect(() => {
    if (
      props.auth.userInfo.hasOwnProperty("billing_fname") &&
      props.auth.userInfo.billing_fname != null
    ) {
      scrollToAndFocusDiv();
      settoggle(true);
    } else {
      settoggle(false);
    }
  }, [props.auth]);

  return (
    <>
      {loading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
      )}

      <h4 class="mb-3 fw-bold" style={{ color: "#152D79 " }}>
        <button
          className={`accordion-button border-0 ${
            toggle ? "" : "collapsed"
          } mb-3 p-0 pb-3`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#paymentCollapse"
          aria-expanded={toggle}
          aria-controls="paymentCollapse"
          disabled={
            !props.auth.userInfo.hasOwnProperty("billing_fname") ||
            props.auth.userInfo.billing_fname == null
          }
        >
          3.Payment
        </button>
      </h4>

      <div
        id="paymentCollapse"
        className={`${toggle ? "collapse show" : "collapse"}`}
      >
        <form class="card p-2a" style={{ width: "40%" }}>
          <div class="input-group">
            <input
              type="text"
              value={coupon}
              onChange={(e) => setcoupon(e.target.value)}
              class="form-control"
              placeholder="Coupon"
            />
            <div class="input-group-append">
              <button
                type="button"
                className="btnbtn btn-secondary"
                style={{ background: "#152D79 " }}
                onClick={applycoupon}
              >
                Apply Coupon
              </button>
            </div>
          </div>
        </form>

        <div class="d-block my-3" ref={myDivRef}>
          <h5 className="fw-bold">Select card</h5>
          {card.length > 0 &&
            card.slice(0, 3).map((obj, i) => {
              return (
                <>
                  <div class="custom-control custom-radio d-flex gap-2 mb-2">
                    <input
                      name="paymentMethod"
                      type="radio"
                      class="custom-control-input"
                      onClick={() => setchecked(i)}
                      checked={checked == i}
                      require
                      id={i}
                    />

                    <label for={i} class="custom-control-label">
                      {obj.info}
                    </label>
                  </div>
                </>
              );
            })}
          <>
            {" "}
            <div className="d-flex gap-2">
              <input
                name="paymentMethod"
                type="radio"
                class="custom-control-input"
                checked={checked == "new"}
                onClick={() => setchecked("new")}
                id="new"
              />
              <label class="custom-control-label" for="new">
                Add new card
              </label>
            </div>
            {checked == "new" && (
              <>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label className="fw-bold" for="cc-name">
                      Name on card
                    </label>

                    <TextField
                      type="text"
                      fullWidth
                      name="cardholdername"
                      value={newcard.cardholdername}
                      onChange={handleEvent}
                      id="cc-name"
                      placeholder=""
                      required
                    />
                    <small class="text-muted">
                      Full name as displayed on card
                    </small>
                    {error.nameOnCard === 1 && (
                      <div
                        class="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Name on card is required
                      </div>
                    )}
                  </div>
                  <div class="col-md-6 mb-3">
                    <label className="fw-bold" for="cc-number">
                      Credit card number
                    </label>
                    <TextField
                      type="text"
                      name="cardnumber"
                      value={newcard.cardnumber}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                      placeholder="0000 0000 0000 0000"
                      onChange={handleEvent}
                      fullWidth
                      id="cc-number"
                      required
                    />
                    {error.numOnCard === 2 && (
                      <div
                        class="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Credit card number is required
                      </div>
                    )}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 mb-3">
                    <label className="fw-bold" for="cc-expiration">
                      Expiration
                    </label>
                    <TextField
                      type="text"
                      fullWidth
                      name="mmyy"
                      value={newcard.mmyy}
                      onChange={handleEvent}
                      placeholder="MM/YY"
                      id="cc-expiration"
                      InputProps={{
                        inputComponent: MMYY,
                      }}
                    />
                    {error.expiryOnCard === 3 && (
                      <div
                        class="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Expiration date required
                      </div>
                    )}
                  </div>
                  <div class="col-md-3 mb-3">
                    <label className="fw-bold" for="cc-expiration">
                      CVV
                    </label>
                    <TextField
                      type="text"
                    name="cvv"
                      value={newcard.cvv}
                      onChange={(e) => {
                        (e.target.value >= 0) & (e.target.value.length < 4) &&
                          handleEvent(e);
                      }}
                      id="cc-cvv"
                      placeholder=""
                      required
                    />
                    {error.cvvOnCard === 4 && (
                      <div
                        class="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Security code required
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        </div>
        <hr class="mb-4" />
      </div>

      {/* <Button onClick={addcreditcard}  style={{display:"block",margin:"20px auto"}}>Add card</Button> */}

      <p>
        Click{" "}
        <a href="terms" target="_blank">
          here
        </a>{" "}
        to read the terms of service.
      </p>
      <div className="mb-5">
        <FormControlLabel
          control={
            <Checkbox
              value={agreement}
              onChange={(e) => setagreement(!agreement)}
            />
          }
          label="I accept the terms of service."
        />
      </div>
      <button
        class="btn btn-primary btn-lg btn-block"
        type="button"
        onClick={checkout}
        title="Create account first"
        disabled={
          !props.auth.userInfo.hasOwnProperty("id") ||
          !props.auth.userInfo.hasOwnProperty("billing_fname") ||
          !agreement
        }
      >
        Place Order
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.authReducer,
    updateReducer:updateReducer,
  };
};

export default connect(mapStateToProps, {
  signOut,
  addtocart,
  addanything,
  emptymycart,
  updateCart,
  updateState
  
})(Payment);
