import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useState } from "react";
import {
  checkAssociatePlans,
  closeordergroupapi,
  getHash,
  getalldevices,
  ordergroup,
  postorder,
} from "../../Service/Service";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import { addanything,addtocart, setLoading } from "../../actions";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

 function FullWidthTabs(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getalldevices().then((result) => {
      dispatch(setLoading(false));
      setdevices(result);
    });
  }, []);
  const [open, setOpen] = useState(false);
  const [myDevice, setMyDevice] = useState(null);
  const [devices, setdevices] = useState([]);
  const [plansAssociated, setplansAssociated] = useState([]);
  const [loading,setloading] = useState(false);
  const handleOpen = (obj) => {
    

    gettinghash().then(()=>{



  
    const objectToSave = getObjectById(obj.id);
    if (obj.associate_with_plan) {
        setloading(obj.id);
      checkAssociatePlans(obj.id).then((result) => {
        setloading(false);
        setplansAssociated(result);
        console.log(objectToSave);
        setMyDevice(objectToSave);
        setOpen(true);
      });
    } else {
      console.log(objectToSave);
      setMyDevice(objectToSave);
      setOpen(true);
    }

})
  };
  const style = {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "90vw",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    borderRadius: ".3rem",
    minHeight: 600,
    maxWidth: "1000px",
    flexDirection: "column",
  };
  const mediaStyle = {
    "@media (max-width: 768px)": {
      ...style,
      width: "95vw",
      minHeight: 400,
      flexDirection: "column",
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buyDevice = (id,mode) => {
    setIsLoading(true);
    mode?setloading(id+mode):setloading(id)
    postorder({ device_id: id, order_hash: cart.hash.order_hash }).then(() => {
      ordergroup(1, cart.hash.order_hash).then(() => {
        closeordergroupapi(cart.hash.order_hash, null).then((result) => {
          setloading(false)
          dispatch(addtocart(result));
          handleClose();
          setIsLoading(false);
          Swal.fire({
            title: "Success",
            text: `Your device has been added to your cart.`,
            denyButtonText: `Checkout`,
            showDenyButton: true,
            denyButtonColor: "#7066e0",
            icon: "success",
            confirmButtonText: "Shop more",
            allowOutsideClick: true,
            allowEscapeKey: false,
            preDeny: () => {
              if (cart.cart.business_verification) {
                navigate("/checkout");
              } else {
                if (auth.userInfo.id) {
                  navigate("/checkout");
                } else {
                  if (auth.userInfo != null) {
                    if (auth.userInfo.id) {
                      navigate("/checkout");
                    }
                  } else {
                    if (cart.cart && cart.cart.company) {
                      if (
                        cart.cart.company.business_verification === 1 &&
                        !auth.userInfo.id
                      ) {
                      } else {
                        navigate("/checkout");
                      }
                    }
                  }
                }
              }

              navigate("/checkout");
            },
            preConfirm: () => {
              navigate("/plans");
            },
          }).then((result) => {
            // check if the modal was closed
            if (result.isDismissed) {
              // navigate to the checkout page
              navigate("/checkout");
            }
          });
        });
      });
    });
    console.log(cart.hash.order_hash);
  };

  const handleClose = () => setOpen(false);
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.authReducer);
  const getObjectById = (id) => {
    return devices.find((obj) => obj.id === id);
  };

  const goNavigate = (id,mode)=>{
    setloading(id+mode)
    postorder({ device_id: id, order_hash: cart.hash.order_hash }).then(() => {
       
          closeordergroupapi(cart.hash.order_hash, null).then((result) => {
            setloading(false)
              result.plansAssociated=plansAssociated;
            dispatch(addtocart(result));
            handleClose();
            setIsLoading(false);
            const data = { plansAssociated : plansAssociated };
            navigate('/plans', { state: { plansAssociatedObj: data } });
          });
        
      });

    

  }



  const gettinghash = (obj) =>{
    return new Promise((resolve, reject) => {
      
      if (!props.cart.hasOwnProperty("hash")) {
        getHash({}).then((result) => {
          if (result.order_hash) {
            resolve(true);
            props.addanything({ hash: result });
          
          }


        });
      } 
      else{
        resolve(true);
      }
      
    })

  }


  return (
    <>
   

      <Box sx={{ bgcolor: "background.paper", width: 800 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Tablets" {...a11yProps(0)} />
            <Tab label="Phones" {...a11yProps(1)} />
            <Tab label="Accessories" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
      </Box>
      <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <div className="device-wrap">
              {devices
                .filter((obj) => obj.type === 2)
                .map((obj) => {
                  return (
                    <div className="col-md-4 col-sm-12 item" key={obj.id}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="img-wrap">
                            <img
                              src={
                                obj.primary_image
                                  ? obj.primary_image
                                  : "https://teltik.pw/imgs/placeholders/default-tablet.png"
                              }
                              alt="Device image"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="px-3 my-3">
                            <div className="device-name">{obj.name}</div>
                            <div
                              className="device-specs"
                              dangerouslySetInnerHTML={{
                                __html: obj?.description,
                              }}
                            ></div>
                          </div>
                          <div className="plan-price">
                            <div className="price1">
                              <span>${obj.amount_w_plan}</span> with Plan
                            </div>
                            <div className="price2">
                              <span>${obj.amount}</span> without Plan
                            </div>
                          </div>
                          <div className="my-3">
                            <button
                              onClick={() => handleOpen(obj)}
                              className={`cart-btn ${loading===obj.id&&'loading'}`}
                            >
                              ADD TO CART
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div className="device-wrap">
              {devices
                .filter((obj) => obj.type === 1)
                .map((obj) => {
                  return (
                    <div className="col-md-4 col-sm-12 item" key={obj.id}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="img-wrap">
                            <img
                              src="https://teltik.pw/imgs/placeholders/default-phone.png"
                              alt="Device image"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="px-3 my-3">
                            <div className="device-name">{obj.name}</div>
                            <div
                              className="device-specs"
                              dangerouslySetInnerHTML={{
                                __html: obj?.description,
                              }}
                            ></div>
                          </div>
                          <div className="plan-price">
                            <div className="price1">
                              <span>${obj.amount_w_plan}</span> with Plan
                            </div>
                            <div className="price2">
                              <span>${obj.amount}</span> without Plan
                            </div>
                          </div>
                          <div className="my-3">
                            <button
                              onClick={() => handleOpen(obj)}
                              className={`cart-btn ${loading===obj.id&&'loading'}`}
                            >
                              ADD TO CART
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <div className="device-wrap">
              {devices
                .filter((obj) => obj.type === 8)
                .map((obj) => {
                  return (
                    <div className="col-md-4 col-sm-12 item" key={obj.id}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="img-wrap">
                            <img
                              src="https://teltik.pw/imgs/placeholders/default-phone.png"
                              alt="Device image"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="px-3 my-3">
                            <div className="device-name">{obj.name}</div>
                            <div
                              className="device-specs"
                              dangerouslySetInnerHTML={{
                                __html: obj?.description,
                              }}
                            ></div>
                          </div>
                          <div className="plan-price">
                            <div className="price1">
                              <span>${obj.amount_w_plan}</span> with Plan
                            </div>
                            <div className="price2">
                              <span>${obj.amount}</span> without Plan
                            </div>
                          </div>
                          <div className="my-3">
                            <button
                              onClick={() => handleOpen(obj)}
                              className={`cart-btn ${loading===obj.id&&'loading'}`}
                            >
                              ADD TO CART
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </TabPanel>
        </SwipeableViews>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "scroll" }}
      >
        <Box sx={{ ...style, ...mediaStyle }}>
          <div className="closeModalBtn">
            <span onClick={handleClose}>×</span>
          </div>
          <div className="upper-modal modal-section">
            <div className="img-section">
              <div className="img-wraper">
                <img src="https://teltik.pw/imgs/placeholders/default-phone.png" />
              </div>
            </div>
            <div className="detail-section w-100">
              <h2 className="device-title">{myDevice?.name}</h2>
              {/* <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> iPhone X features a 5.8-inch Super Retina display with HDR </span>
                            </div> */}
              <div
                className="device-description"
                dangerouslySetInnerHTML={{
                  __html: myDevice?.description,
                }}
              ></div>
              <div className="add-cart-btn py-4 my-3">
                <div className="d-flex justify-content-between w-100">
                  <div className="price">
                    <span>${myDevice?.amount_w_plan}</span> with Plan
                  </div>
                  <div className="price">
                    <span>${myDevice?.amount}</span> without Plan
                  </div>
                </div>
                <div
                  className="mt-5 text-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    columnGap: "15px",
                  }}
                >
                  {myDevice?.associate_with_plan ? (
                    <>
                     
                      {plansAssociated.length > 0 && (
                        <button
                        className={`cart-btn ${loading===myDevice.id+"wp"&&'loading'}`}
                          onClick={() => goNavigate(myDevice?.id,"wp")}

                          disabled={loading===myDevice?.id+"wp"}
                        >
                          Add to cart and choose plan
                        </button>
                      )}
                       <button
                       className={`cart-btn ${loading===myDevice.id+"wop"&&'loading'}`}
                        
                        onClick={() => buyDevice(myDevice?.id,"wop")}
                        disabled={loading===myDevice?.id+"wop"}
                      >
                        Add to cart without plan
                      </button>
                    </>
                  ) : (
                    <button
                    className={`cart-btn ${loading===myDevice?.id&&'loading'}`}
                      onClick={() => buyDevice(myDevice?.id)}
                      disabled={loading===myDevice?.id}
                    >
                      Add to cart
                    </button>
                  )}
                </div>
              </div>
              {/* <h4 className='device-title'>WHAT'S IN THE BOX</h4>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> 5.8" Super Retina HD Display </span>
                            </div>
                            <div className='list-item'>
                                <img className='tick-icon' src={tick} />
                                <span> iPhone X features a 5.8-inch Super Retina display with HDR </span>
                            </div> */}
            </div>
          </div>
          {/* <div className='lower-modal modal-section'>
                        <div className='specification-wrap'>
                            <h4 className='spec-title'>Specifications</h4>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            <div className='row item'>
                                <div className='col-sm-4 col-xs-12'>
                                    <div className='spec'>Time on RaloRadar</div>
                                </div>
                                <div className='col-sm-8 col-xs-12'>
                                    <div className='spec-detail'>
                                        Up to 21 hours
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> */}
          <div
            className="lower-modal modal-section"
            dangerouslySetInnerHTML={{
              __html: myDevice?.description_detail,
            }}
          ></div>
        </Box>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
    return {
      cart: state.cart,
      auth: state.authReducer,
    };
  };
  
  export default connect(mapStateToProps, { addtocart, addanything,setLoading })(FullWidthTabs);
  
