import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { savebilling,getcustomerdetail, getCustomerCart } from "../../Service/Service";
import states from '../../myData/states.json';
import { useState } from "react";
import Swal from "sweetalert2";
import {Updatebilling, addtocart} from "../../actions";
import LoadingSpinner from "../../components/assets/loaderspinner.gif";
import { useRef } from "react";
const Billing = (props) =>{
  const [error,seterror] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  
const validation = () =>{
  let errors={};
  if(props.billinginfo.firstname==="")
  {
      // seterror(7);
      errors.billingFname=7;
      // return false;
  }
  if(props.billinginfo.lastname==="")
  {
      // seterror(8);
      errors.billingLname=8;
      // return false;
  }
  if(props.billinginfo.address1==="")
  {
      // seterror(9);
      errors.billingAdd1=9;
      // return false;

  }
  if(props.billinginfo.city==="" || !/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/.test(props.billinginfo.city))
  {

      // seterror(10)
      errors.billingCity=10;
      // return false;
  }
  if(props.billinginfo.state==="")
  {
      // seterror(11)
      errors.billingState=11;
  }
  if(props.billinginfo.zipcode==="" || !/^\d+$/.test(props.billinginfo.zipcode) || props.billinginfo.zipcode.length!==5)
  {
      // seterror(12)
      errors.billingZip=12;
  }
  if (Object.keys(errors).length !== 0) {
    seterror(errors);
    return false;
  }
  else{
    seterror({});
    savebillingaddress()
  }
}

const savebillingaddress = ()=>{

    setIsLoading(true);

  savebilling(props.billinginfo,props.auth.userInfo.id,props.cart.hash.order_hash).then(()=>{
    getcustomerdetail(props.auth.userInfo.hash).then((result)=>{
      setIsLoading(false);
      props.Updatebilling(result)
      getCustomerCart(props.auth.userInfo.id).then((cart)=>{
        props.addtocart(cart);
        settoggle(false);
      })
      Swal.fire({
        icon: 'success',
        title: 'Your billing Address is updated',
          })
  })
    
  })

}

const updateEvent = (e) =>{

  const {name,value} = e.target;

  props.setbillinginfo({...props.billinginfo,[name]:value});

}
const handleNumType=(evt)=>{
  if (evt.key !== 'Backspace' && evt.key !== 'Delete' && (evt.key.length !== 1 || evt.key < '0' || evt.key > '9')) {
    evt.preventDefault();
  }
}

const [toggle,settoggle]= useState(props.auth.userInfo.hasOwnProperty("billing_fname")?false:true)
const myDivRef = useRef(null);
const scrollToAndFocusDiv = () => {
  if (myDivRef.current) {
    const offset = -120; // Adjust this value to the desired offset
    const scrollToPosition = myDivRef.current.offsetTop + offset;

    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth',
    });

    myDivRef.current.focus();
  }
};

useEffect(()=>
{

  if(props.auth.userInfo.hasOwnProperty('billing_fname')&&props.auth.userInfo.billing_fname!=null)
  {
    settoggle(false)

  }
  else if (props.auth.userInfo.hasOwnProperty('shipping_fname'))
  {
    scrollToAndFocusDiv();
    settoggle(true);
  }
  else{

    settoggle(false);
  }

}
,[props.auth])


return (
<>

  
{isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
      )}

    <div className="accordion" id="billingAccordion">
      <div className="accordion-item border-0">
        <h2 className="accordion-header" id="billingHeading">
          
         
              {/* <button className="accordion-button border-0  mb-3 p-0 pb-3" type="button" data-bs-toggle="collapse" data-bs-target="#billingCollapse" aria-expanded="true" aria-controls="billingCollapse" disabled={!props.auth.userInfo.hasOwnProperty("shipping_fname")}>
             2.Billing Info
           </button>
             */}
            <button className={`accordion-button border-0 ${toggle?"":"collapsed"} mb-3 p-0 pb-3`} type="button" data-bs-toggle="collapse" data-bs-target="#billingCollapse" aria-expanded={toggle}  aria-controls="billingCollapse" disabled={!props.auth.userInfo.hasOwnProperty("shipping_fname")}>
            2.Billing Info
          </button>
          
          
          </h2>
          <div id="billingCollapse" className={`accordion-collapse false collapse  ${toggle &&"show"}`} aria-labelledby="billingHeading" data-bs-parent="#billingAccordion">
       
            <div className="accordion-body" ref={myDivRef}>
            <form class="needs-validation" novalidate>
            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" onClick={()=>props.setbillinginfo(props.shippinginfo)} class="custom-control-input" id="same-address" />
              <label class="custom-control-label px-2" for="same-address">Billing address is the same as my shipping address</label>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label className="fw-bold" for="firstName">First name</label>
                <input  type="text" class="form-control" id="firstName" placeholder="" name="firstname" onChange={updateEvent} value={props.billinginfo.firstname} required />
                {error.billingFname===7&&
                <div class="invalid-feedback" style={{display:"block"}}>
                  Valid first name is required.
                </div>
              }
              </div>
              <div class="col-md-6 mb-3">
                <label className="fw-bold" for="lastName">Last name</label>
                <input type="text" class="form-control" id="lastName" placeholder=""  name="lastname" onChange={updateEvent} value={props.billinginfo.lastname} required />
                {error.billingLname===8&&
                <div class="invalid-feedback" style={{display:"block"}}>
                  Valid last name is required.
                </div>
                }
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <label className="fw-bold" for="firstName">Address1</label>
                <input type="text" class="form-control" id="firstName" placeholder="" name="address1" onChange={updateEvent} value={props.billinginfo.address1}  required />
                
                {error.billingAdd1===9&&
                <div class="invalid-feedback" style={{display:"block"}}>
                Valid Address is required.
                </div>
                }
              </div>
              <div class="col-md-6 mb-3">
                <label className="fw-bold" for="lastName">Address2</label>
                <input type="text" class="form-control" id="lastName" placeholder="" name="address2"  onChange={updateEvent} value={props.billinginfo.address2}  required />
                <div class="invalid-feedback">
                  Valid last name is required.
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-md-6 mb-3">
                  <label for="country" className="form-label fw-bold">City</label>
                          <input type="text" class="form-control" name="city" 
                          onKeyDown={(evt)=>{
                                !(/^[a-zA-Z ]*$/.test(evt.key)) && evt.preventDefault();
                                }}
                          onChange={updateEvent} id="lastName" placeholder="" value={props.billinginfo.city}  required />
                          {error.billingCity===10&&
              <div class="invalid-feedback" style={{display:"block"}}>
              Please select a valid city.
              </div>
              }
                  <div class="invalid-feedback">
                    
                  </div>
                </div>

                <div class="col-md-3 mb-3">
                  <label for="state" className="form-label fw-bold">State</label>
                  <select class="form-select"  onChange={updateEvent} name="state" value={props.billinginfo.state} id="state" required>
                    
                    {states.map((option)=>{
                      return(
                      <option value={option.code}>{option.name}</option>
                      )
                    })
                    }
                  </select>
                  {error.billingState===11&&
                    <div class="invalid-feedback" style={{display:"block"}} >
                    Please provide a valid state.
                  </div>
              }
                  
                </div>
                <div class="col-md-3">
                  <label for="zip"className="form-label fw-bold">Zip</label>
                  <input type="text" class="form-control" id="zip" placeholder="" onKeyDown={handleNumType} onChange={updateEvent} name="zipcode"  value={props.billinginfo.zipcode} required />
                  {error.billingZip===12&&
                    <div class="invalid-feedback" style={{display:"block"}}>
                    Valid zip code required.
                  </div>
              }
                  
                </div>
                
            </div>
          
            {/* <div class="row">
              <div class="col-md-5 mb-3">
                <label for="country">Country</label>
                <select class="custom-select d-block w-100" id="country" required>
                  <option value="">Choose...</option>
                  <option>United States</option>
                </select>
                <div class="invalid-feedback">
                  Please select a valid country.
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="state">State</label>
                <select class="custom-select d-block w-100" id="state" required>
                  <option value="">Choose...</option>
                  <option>California</option>
                </select>
                <div class="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="zip">Zip</label>
                <input type="text" class="form-control" id="zip" placeholder="" required />
                <div class="invalid-feedback">
                  Zip code required.
                </div>
              </div>
            </div> */}
            <Button onClick={validation}  style={{display:"block",margin:"20px 0"}}>Save & Continue</Button>
            <hr class="mb-4" />

        
            </form>
          </div>
          
        </div>
      </div>
    </div>
    </>
)

}


const mapStateToProps = (state) =>{

  return{
  cart : state.cart,
  auth: state.authReducer
      }

}


export default connect(mapStateToProps,{Updatebilling,addtocart})(Billing);

