import "./App.css";
import Plansmain from "./components/home/plans/Plansmain";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "../src/components/home/Home";
import MyAccount_main from "./components/myaccount/MyAccount_main";
import Whyus from "./components/whyus/Whyus";
import Support from "./components/support/Support";
import Checkout from "./components/checkout/Checkout";
import Dashboard from "./components/dashboard/Dashboard";
// import Footer from "./components/footer/Footer";
import Checkout2 from "./components/checkout2/Checkout2";
import { useEffect } from "react";
import Confirm from "./components/checkout2/Confirm";
import PrivateRoute from "./routes/PrivateRoute";
import Coverage from "./components/coveragepage/Coverage";
import Devices from "./components/home/devices/Devices";
import Terms from "./components/Terms/Terms";
import Devicespage from "./components/devices/Devices";

function App() {
  useEffect(() => {
    const handleListen = () => {
      if (!window.history.state) {
        window.history.replaceState({ path: window.location.pathname }, "");
      }
    };

    window.addEventListener("popstate", handleListen);

    return () => {
      window.removeEventListener("popstate", handleListen);
    };
  }, []);
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/plans" element={<Plansmain />} />
      
        <Route exact path="/devices" element={<Devices />} />
        <Route exact path="/myaccount" element={<MyAccount_main />} />
        <Route exact path="/whyUs" element={<Whyus />} />
        <Route exact path="/support" element={<Support />} />
        {/* <Route exact path="/checkout" element={<Checkout />} /> */}
        <Route exact path="/coverage" element={<Coverage/>} />
      
        <Route
          // exact
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
             </PrivateRoute>
          }
        />
        <Route exact path="/checkout" element={<Checkout2 />} />
        <Route exact path="/confirm" element={<Confirm />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/devicespage" element={<Devicespage/>} />
      </Routes>
    </>
  );
}

export default App;
