import React from 'react';
import Footer from '../../footer/Footer';
import Navbar from '../../Navbar';
import Plans from './Plans';
import Plansp2 from './Plansp2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import { connect } from "react-redux";
import { addanything, addtocart ,setLoading } from "../../../actions";
import {closeordergroupapi, ordergroup

} from "../../../Service/Service";

const Plansmain = (props) => {
  const location = useLocation();
  const customData = location.state?.plansAssociatedObj;

  const navigate = useNavigate();

  const checkout = () =>{
    ordergroup(1, props.cart.hash.order_hash).then(() => {
      closeordergroupapi(props.cart.hash.order_hash, null).then((result)=>{
        props.addtocart(result);
        navigate("/checkout");
      })


  });
    // Swal.fire({
    //   title: "Success",
    //   text: `Continue checkout without plan`,
    //   denyButtonText: `Checkout`,
    //   showDenyButton: true,
    //   denyButtonColor: "#7066e0",
    //   icon: "success",
    //   confirmButtonText: "Shop more",
    //   allowOutsideClick: true,
    //   allowEscapeKey: false,
    //   preDeny: () => {
    //     // processCoupon();
    //     // getCartOrder();

    //     // if (props.cart.cart.business_verification) {
    //     //   navigate("/checkout");
    //     // } 
    //     // else {
    //     //   if (props.auth.userInfo.id) {
    //     //     navigate("/checkout");
    //     //   } else {
    //     //     if (props.auth.userInfo != null) {
    //     //       if (props.auth.userInfo.id) {
    //     //         navigate("/checkout");
    //     //       }
    //     //     } else {
    //     //       if (props.cart.cart && props.cart.cart.company) {
    //     //         if (
    //     //           props.cart.cart.company.business_verification === 1 &&
    //     //           !props.auth.userInfo.id
    //     //         ) {
    //     //         } else {
    //     //           navigate("/checkout");
    //     //         }
    //     //       }
    //     //     }
    //     //   }
    //     // }

    //     ordergroup(1, props.cart.hash.order_hash).then(() => {
    //         closeordergroupapi(props.cart.hash.order_hash, null).then((result)=>{
    //           props.addtocart(result);
    //           navigate("/checkout");
    //         })


    //     });
      
    //   },
    //   preConfirm: () => {},
    // });

  }

  console.log("plans " ,customData);
  return (
    <>
    <Navbar/>
    <Plans plansAssociatedObj={customData}/>
    <Plansp2/>
    <Footer/>
    {props.cart.cart.activeGroupId && props.cart.cart.order_groups.find(
          (obj) => obj.id == props.cart.cart.activeGroupId
        ).device &&<div className='btnfixed'><Button   variant="contained" onClick={()=>checkout()}>Continue Without Plan</Button></div>}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.authReducer,
  };
};
export default connect(mapStateToProps, { addtocart, addanything,setLoading })(Plansmain);
