import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { Button } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import Customerinfo from './Customerinfo';
import Shippinginfo from './Shippinginfo';
import Billinginfo from './Billinginfo';
import Paymentinfo from './Paymentinfo';
import { StepLabel } from '@mui/material';
import { useState , useRef} from 'react';
import { createcustomer, orderupdateshipping, savebilling } from '../../Service/Service';
import { connect } from "react-redux";
import { signIn } from '../../actions';
import authReducer from '../../reducers/authReducer';
import { addcoupon } from '../../actions';


 const Checkoutform = (props) => {
  const steps = ['Customer Info', 'Shipping Info', 'Billing Info','Payment Info'];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
  
 
    if(activeStep==0)
    {
    if(childRef1.current.childFunction1())
    {
      const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);

    }
  }
  else if(activeStep===1)
  {
    if(childRef2.current.childFunction2())
    {

    orderupdateshipping(shippinginfo).then()
        createcustomer(customerinfo,shippinginfo).then((result)=>{

           if(result.success)
           {

                props.signIn(result.customer)

           }



        })



      const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
        setActiveStep(newActiveStep);

    }

  }
  else if(activeStep===2)
  {


    // calculateStateTax();
    savebilling(billinginfo,props.auth.userInfo.id).then(()=>{


    })

    if(childRef3.current.childFunction3())
    {

    
      const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
        setActiveStep(newActiveStep);

    }
    
  }

  else if (activeStep===3)
  {
    childRef4.current.childFunction4()

  }

    
  };


  const calculateStateTax = () =>{

        if(props.cart.length!=0)
        {
            props.cart.cart.map((obj)=>{
                  callTaxableItems(obj,billinginfo.state)

            })
        
        }

  }


  const callTaxableItems = (cartitem , stateid=null) =>{

      if(!stateid)
      {
          if(localStorage.getItem("business_verification") && localStorage.getItem("billing_state_id"))
          {
            localStorage.setItem("tax_id",localStorage.getItem("billing_state_id"));
          }else if( localStorage.getItem("customer") && localStorage.getItem("billing_state_id"))
            {
              localStorage.setItem("tax_id",localStorage.getItem("billing_state_id"));
            }
      }
      else{

         localStorage.setItem("tax_id", stateid);
      }

      const taxrate = taxrate(stateid);

      if(!localStorage.getItem('taxrate') || localStorage.getItem('taxrate') && taxrate['taxrate'] && localStorage.getItem("taxrate") != taxrate['tax_rate'] )
      {
        taxrate=taxrate(stateid);
        
        taxrate['tax_rate']?localStorage.setItem('tax_rate',taxrate['tax_rate']): localStorage.setItem('tax_rate' , 0)


      }

      const taxpercentage = parseInt(localStorage.getItem("tax_rate"))/100;
      
      if(cartitem.status!=null && cartitem.status=="SamePlan")
      {

            let addons=addTaxesToAddon(cartitem,taxpercentage)
            return addons;
      }
      if(cartitem.status!=null && cartitem.status=="Upgrade")
      {
          let plans = addtaxestoplans(cartitem,cartitem.plan,taxpercentage);
          let addons =  addTaxesToAddon(cartitem,taxpercentage);
          return plans+addons;

      }
      
      let devices =  addTaxesDevices(cartitem,cartitem.device,taxpercentage);
      
      let sims = addTaxesSims(cartitem,cartitem.sims,taxpercentage);

      let plans = addtaxestoplans(cartitem,cartitem.plan,taxpercentage);
       
      let addons = addTaxesToAddon(cartitem,taxpercentage);

      return devices+sims+plans+addons;

  }



  const addTaxesSims=(cart,item,taxPercentage)=>{
  
      const itemtax=0;
      if(item && item.taxable)
      {
        let  amount =cart.plan != null ? item.amount_w_plan : item.amount_alone;
        if(localStorage.getItem('couponAmount'))
        {
          let discounted = getCouponPrice(localStorage.getItem('couponAmount'),item,3)
          
          amount = amount > discounted ? amount - discounted : 0;
          
        }

        itemtax=itemtax + taxPercentage * amount;
      }
      return itemtax;

  }


  const addTaxesDevices =(cart,item,taxPercentage)=>{

          let itemtax=0 ;
          if(item && item.taxable)
          {
            let  amount =cart.plan != null ? item.amount_w_plan : item.amount;
            if(localStorage.getItem('couponAmount'))
            {
              let discounted = getCouponPrice(localStorage.getItem('couponAmount'),item,2)
              
              amount = amount > discounted ? amount - discounted : 0;
              
            }

            itemtax=itemtax + taxPercentage * amount;
          }
          return itemtax;


  }

  const customerdata=useRef();
  const taxrate = (statid) =>{

      if(customerdata)
    {
        return customerdata;
    }
    else{


    }
  }

  const addTaxesToAddon=(cart,taxpercentage)=>
  {
      const addontax=0;
      if(props.cart.cart.addons)
      {

            props.cart.cart.addons.map((obj)=>
            {
                  if(obj.taxable===1)
                  {
                      let amount= obj.prorated_amt!=null ? obj.prorated_amt : obj.amount_recurring;
                      if(localStorage.getItem("couponAmount"))
                      {
                        let discounted=  getCouponPrice(localStorage.getItem('couponAmount',obj,4));
                        
                        amount=amount>discounted?amount-discounted:0;


                      }
                      addontax=addontax+taxpercentage+amount;
                  }

            })

      }

            return addontax;

  }


  const addtaxestoplans = (cart,item,taxpercentage) =>{

      const plantax =0 ;
      if(item!=null && item.taxable)
      {
        let amount = cart.plan_prorated_amt !=null ? cart.plan_prorated_amt : item.amount_recurring;
        amount = item.amount_onetime != null ? amount + item.amount_onetime : amount;
        if(localStorage.getItem("couponAmount"))
        {
          let discounted=  getCouponPrice(localStorage.getItem('couponAmount',item,1));
          amount = amount>discounted ? amount - discounted :0;

        }
          plantax = plantax + taxpercentage * amount;
      }
      return plantax;
  } 



  const getCouponPrice =(coupondata,item,itemtype)=>
  {
      let productdiscount = 0;

        coupondata.map((obj)=>{

          let type=obj.coupon_type;
          if(type==1)
          {

            var appliedto = obj.applied_to.applied_to_all;
          }
          else if(type==2)
          {
            var appliedto = obj.applied_to.applied_to_types;
          }
          else if(type==3)
          {

            var appliedto = obj.applied_to.applied_to_product;
          }

          if(appliedto.length>0)
          {
              appliedto.map((obj)=>{

                    if(obj.order_product_type==itemtype  && obj.order_product_id===item.id)
                    {
                      productdiscount=productdiscount+obj.discount;
                    }

              })

          }




      })

        return productdiscount;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const childRef1 = useRef();
  const childRef2 = useRef();
  const childRef3 = useRef();
  const childRef4 = useRef();

  const [customerinfo,setcustomerinfo] = useState({

    firstname:"",
    lastname:"",
    email:"",
    phone:"",
    altphone:"",
    password:"",
    confirmpass:"",
    pin:""
})


const [shippinginfo,setshippinginfo]= useState({

  firstname:"",
  lastname:"",
  address1:"",
  address2:"",
  city:"",
  state:"",
  zipcode:""

})

const [billinginfo,setbillinginfo]= useState({

  firstname:"",
  lastname:"",
  address1:"",
  address2:"",
  city:"",
  state:"",
  zipcode:""

})


  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
             <StepLabel> {label} </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (

          
          <React.Fragment>
           
          {activeStep==0&&<>
            <Customerinfo setActiveStep={setActiveStep} customerinfo={customerinfo} setcustomerinfo={setcustomerinfo} ref={childRef1}/>
 
            </>
          }
         
          {activeStep==1&&<>
            <Shippinginfo ref={childRef2}  shippinginfo={shippinginfo} setshippinginfo={setshippinginfo}/>

            </>
          }

          {activeStep==2&&<>
            <Billinginfo Shippinginfo={shippinginfo} ref={childRef3}  billinginfo={billinginfo} setbillinginfo={setbillinginfo} />

            </>
          }
          {activeStep==3&&<>
           <Paymentinfo billinginfo={billinginfo} ref={childRef4} />

            </>
          }
          
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2,position:"absolute", bottom: "-142px",marginBottom:10,width:"100%" }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} style={{marginRight:10}} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}


const mapStateToProps = (state) =>{

  return{
  cart : state.cart,
  auth: state.authReducer
      }

}


export default connect(mapStateToProps,{signIn})(Checkoutform);