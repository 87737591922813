import React from "react";
import "./Covragepart.css";
import { Button } from "react-bootstrap";
import tick from "../assets/tick.svg";
import Iconarrow from "../assets/Iconarrow.svg";
import { useLayoutEffect } from "react";
import { getPlans } from "../../Service/Service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Covragepart = () => {
  const [plans, setPlans] = useState([]);
  useLayoutEffect(() => {
    getPlans().then((result) => {
      setPlans(result);
      console.log(result);
    });
  }, []);
  const navigate = useNavigate();

  return (
    <>
      <div className="cardd2">
        <div className="kk1">
          <h1 className="kk2">We make Wireless Simple</h1>
          <p className="k1">Choose between 2 cell phone plans:</p>
        </div>
      </div>

      <div className="cardd1">
        {plans.slice(0,2).map((obj,i) => { 
          
          return (
            <>
              <div className="parice-card">
                <button className="btnbasic">{obj?.name}</button>
                <div className="doll2">
                  <span
                    className="d0l-1"
                    style={{
                      marginLeft: "-140px",

                      font: "normal normal 800 31px/29px Nunito Sans",
                    }}
                  >
                    $
                  </span>
                  <span
                    className="mo-15"
                    style={{
                      font: " bold 63px / 18px Nunito Sans",
                    }}
                  >
                    {obj.amount_recurring}
                    <span className="month1">/month</span>
                  </span>
                </div>
                <div className="border"></div>
                {obj.description ? obj.description
                  .replace(/<\/?[^>]+(>|$)/g, ",")
                  .split(",")
                  .slice(0, 16)
                  .map((obj,index) => {
                    if (obj?.length > 2)
                      return (
                        <div className="talk">
                          <img className="tick1" src={tick} />
                        {i===0 && index===11 ?
                          <div>
                           <h1
                           className="l1"
                           dangerouslySetInnerHTML={{ __html: obj }}
                           ></h1>
                          <p style={{marginLeft:"5%", marginTop:"-4%",position:"absolute"}} className="tagline_para">{`(Pictures, Maps, Songs)`}</p>
                          </div>
                         :
                         <h1
                         className="l1"
                         dangerouslySetInnerHTML={{ __html: obj }}
                       ></h1>
                        }
                        </div>
                      );
                  }):''}
                <Button
                  className="buttonw1"
                  onClick={() => navigate("/plans")}
                  style={{
                    fontFamily: "Nunito Sans",
                    width: "293px",
                    height: "67px",
                    marginTop: "40px",
                    marginRight: "295px",
                    marginBottom: "54px",
                    background:
                      "0% 0% no-repeat padding-box padding-box rgb(21, 45, 121)",
                    boxShadow: "rgb(0 0 0 / 16%) 0px 3px 6px",
                    borderRadius: "34px",
                    opacity: "1",
                    color: "rgb(255, 255, 255)",
                  }}
                  variant="primary"
                >
                  Learn More
                  <img
                    className="pricc-btnimg"
                    style={{ width: "7px", height: "10px", marginLeft: "10px" }}
                    src={Iconarrow}
                  />
                </Button>{" "}
              </div>
              
            </>
          );
                
        })}
      </div>
      <div className="text-belowbtn">
        <p className="belowbtn1">
          You don't need to figure out how many minutes you need each month or
          how much data you use. We offer unlimited everything, at one set price
          per month.
        </p>
      </div>
    </>
  );
};

export default Covragepart;
