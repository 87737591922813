import React from 'react';
import Group53 from "../assets/Group53.svg";
// import Ellipse40 from "../assets/Ellipse40.svg";
// import Ellipse41 from "../assets/Ellipse41.svg";
// import Ellipse44 from "../assets/Ellipse44.svg";
import Carousel from 'react-bootstrap/Carousel';


const Comma = () => {

  return (
    <div className="commadiv">
      <img className="comma" src={Group53} />

      <h2>They’ve connected for less:</h2>
       <Carousel >
       <Carousel.Item>
      <p className="p1">
      My daughter has been trying to get me to wear a tracker for a while now,
        but they are all too bulky and scream GRANDMA! I never agreed to wear
        any of them until I found the DOT Wearable. It’s a simple, smooth device
        that weighs almost nothing and is easily hidden beneath my clothes. I
        feel safer when wearing my Mobile Medical Alert. I know that if I need
        help, I just need to push the button.
      </p>
      <p className="p2">- Steve M., state</p>
      </Carousel.Item>
      <Carousel.Item>
      <p className="p1">
      Switching to Teltik has allowed me cut my cost for wireless service by about two thirds, and given me access to a great network that covers everywhere I go. It is one of the best decisions I've made.
      </p>
      <p className="p2">- Patrick McCullough</p>
      </Carousel.Item>
      <Carousel.Item>
      <p className="p1">
      T-Mobile and Sprint have officially merged, so we updated this page to reflect everything we know about the merger so far. We will continue to monitor the situation and let you know if either service changes its plans, prices, or product offerings.
      </p>
      <p className="p2">- Bill Frost</p>
      </Carousel.Item>
      <Carousel.Item>
      <p className="p1">
      Excellent customer service very fast replay with awesome network for cheapest price highly recommend it to everyone who likes T-Mobile’s service !
      </p>
      <p className="p2">- Neil K</p>
      </Carousel.Item>
      
    </Carousel>
    </div>
  );
};

export default Comma;
