const INTIAL_STATE = 0;
 




  export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
      case "update_state":
        
        return state+1;
 
      default:
        return state;
    }
  };