import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import EnhancedTable from "./table";
import { useEffect } from "react";
// import { getUsages } from "../../services/services";
import { useState } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { margin } from "@mui/system";
import Swal from "sweetalert2";
const style = {
  top: "50%",
  color: "#31a5d2",
};

let showButton;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  CycleValue,
  setCycleValue,
  IsLoading,
  id,
  setUsageType,
  PopulateAllData,
  TotalMin,
  setIsLoading,
  setOpen
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (e, newValue) => {
    if (e.target.name == "cycle1") {
      setloadingarr([1, 0, 0]);
      setcurrentcycle(e.target.value);
      setdata([]);
    }

    if (e.target.name == "cycle2") {
      setloadingarr([0, 1, 0]);
      setcurrentcycle3(e.target.value);
      setdata2([]);
    }

    if (e.target.name == "cycle3") {
      setloadingarr([0, 0, 1]);
      setcurrentcycle2(e.target.value);
      setdata3([]);
    }

    setValue(newValue);
  };

  // const getCallLogs = (ID) => {
  //     setIsLoading(true)
  //     return new Promise((resolve, reject) => {
  //         var myHeaders = new Headers();
  //         var user = JSON.parse(localStorage.getItem("user_token"));
  //         myHeaders.append("AccessToken", "key");
  //         myHeaders.append("Authorization", "alar324r23423");
  //         const d = new Date();
  //         let year =d.getFullYear().toString();
  //         let month= d.getMonth().toString();
  //         console.log(month ,year)

  //         const formdata = new FormData();
  //         formdata.append("sim", ID ? ID : SubcritionID);
  //         formdata.append("date", JSON.parse(year + '0' + month));
  //         formdata.append("type", UsageType);

  //         const formdata1 = new FormData();
  //         formdata1.append("sim", 1392);
  //         formdata1.append("date", 202201);
  //         formdata1.append("type", UsageType);

  //         var requestOptions = {
  //             method: "POST",
  //             // headers: myHeaders,
  //             body: formdata1,
  //             redirect: "follow",
  //         };

  //         fetch("http://137.184.122.121/getApi.php", requestOptions)
  //             .then((response) => response.json())
  //             .then((result) => {
  //                 console.log('Get Call logs ----------->>>>',result)
  //                 // resolve(result);
  //                 setPopulateAllData(result)
  //                 setdata(result.data);
  //                 let sum = 0;
  //                 result?.data.forEach((ele)=>{
  //                   if(UsageType === 1){
  //                     sum = sum += Number(ele?.ans_time_duration_min)
  //                   }
  //                   else if(UsageType === 3){
  //                     sum = sum += Number(ele?.total_volume_mb)

  //                   }
  //                 })
  //                 setTotalMin(sum)
  //                 console.log(sum)
  //                 setIsLoading(false)
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //                 console.log("error", error);
  //             });
  //     });
  // }

  useEffect(() => {
    setloading(true);
  
    if (!PopulateAllData.hasOwnProperty("details")&&PopulateAllData?.data.length > 0) {
      setdata(PopulateAllData.data);
      setdata2(PopulateAllData.data);
      setdata3(PopulateAllData.data);
      setcycle(PopulateAllData.cycle);
      setcycle2(PopulateAllData.cycle);
      setcycle3(PopulateAllData.cycle);
      setcurrentcycle(PopulateAllData.cycle[0].value1);
      setcurrentcycle2(PopulateAllData.cycle[0].value1);
      setcurrentcycle3(PopulateAllData.cycle[0].value1);
    }

    showButton = PopulateAllData?.data;

    // setdata(PopulateAllData.data)
    // getUsages(1392, 1).then((result) => {
    //   console.log(result.cycle);
    //   setcycle(result.cycle);
    //   setdata(result.data);
    //   console.log("===>current cycle");
    //   console.log(result.cycle[0].value1);
    //   setcurrentcycle(result.cycle[0].value1);

    //   getUsages(1392, 2).then((result) => {
    //     console.log(result.cycle);
    //     setcycle2(result.cycle);
    //     setcurrentcycle2(result.cycle[0].value1);
    //     setdata2(result.data);

    //     getUsages(1392, 3).then((result) => {
    //       console.log(result.cycle);
    //       setcycle3(result.cycle);
    //       setcurrentcycle3(result.cycle[0].value1);
    //       setdata3(result.data);
    //       setloading(false);
    //     });
    //   });
    // });
  }, []);

  const [cycle, setcycle] = useState([]);
  const [cycle2, setcycle2] = useState([]);
  const [cycle3, setcycle3] = useState([]);

  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const [data3, setdata3] = useState([]);

  const [currentcycle, setcurrentcycle] = useState();
  const [currentcycle2, setcurrentcycle2] = useState();
  const [currentcycle3, setcurrentcycle3] = useState();

  const headCells1 = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Local Time Call Started",
    },
    {
      id: "fat",
      numeric: true,
      disablePadding: false,
      label: "Answer Time Duration Min",
    },
    {
      id: "carbs",
      numeric: true,
      disablePadding: false,
      label: "Direction",
    },
    {
      id: "protein",
      numeric: true,
      disablePadding: false,
      label: "Number Called",
    },
    {
      id: "techused",
      numeric: true,
      disablePadding: false,
      label: "Tech Used",
    },
  ];

  const headCells2 = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Local Time Session Started",
    },
    {
      id: "fat",
      numeric: true,
      disablePadding: false,
      label: "Total Volumn Mb",
    },
    {
      id: "techused",
      numeric: true,
      disablePadding: false,
      label: "Tech Used",
    },
  ];

  const headCells3 = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Local Time Call Started",
    },
    {
      id: "fat",
      numeric: true,
      disablePadding: false,
      label: "Answer Time Duration Min",
    },
    {
      id: "techused",
      numeric: true,
      disablePadding: false,
      label: "Direction",
    },
    {
      id: "techused",
      numeric: true,
      disablePadding: false,
      label: "Number Called",
    },
    {
      id: "techused",
      numeric: true,
      disablePadding: false,
      label: "Tech Used",
    },
  ];

  const [loading, setloading] = useState(false);

  const handleCycle = (e) => {
    console.log(e.target.value);
    console.log(e);
    setCycleValue(e.target.value);
    // if (e.target.name == "cycle1") {
    //   setloadingarr([1, 0, 0]);
    //   setcurrentcycle(e.target.value);
    //   setdata([]);
    //   getUsages(1392, 1, e.target.value).then((result) => {
    //     console.log(result.cycle);
    //     setdata(result.data);
    //     console.log("===>current cycle");
    //     setloadingarr([0, 0, 0]);
    //   });
    // }

    // if (e.target.name == "cycle2") {
    //   setloadingarr([0, 1, 0]);
    //   setcurrentcycle3(e.target.value);
    //   setdata2([]);
    //   getUsages(1392, 2, e.target.value).then((result) => {
    //     console.log(result.cycle);
    //     setdata2(result.data);
    //     console.log("===>current cycle");
    //     setloadingarr([0, 0, 0]);
    //   });
    // }

    // if (e.target.name == "cycle3") {
    //   setloadingarr([0, 0, 1]);
    //   setcurrentcycle2(e.target.value);
    //   setdata3([]);
    //   getUsages(1392, 3, e.target.value).then((result) => {
    //     console.log(result.cycle);
    //     setdata3(result.data);
    //     console.log("===>current cycle");
    //     setloadingarr([0, 0, 0]);
    //   });
    // }
  };

  const [loadingarr, setloadingarr] = useState([0, 0, 0]);

  const downloadcsv = () => {
    var json = data.map((obj) => {
      return {
        Phone_Number: obj.msisdn,
        Local_Time_Call_Started: obj.local_time_call_started,
        Answer_Time_Duration_Min: obj.ans_time_duration_min,
        Direction: obj.call_direction == 0 ? "Outgoing" : "Incoming",
        Number_Called: obj.subscriber_call_no,
        Tech_Used: obj.tech_used,
      };
    });
    if (json[0] !== undefined && json[0] !== null) {
      var fields = Object.keys(json[0]);
      var replacer = function (key, value) {
        return value === null ? "" : value;
      };
      var csv = json.map(function (row) {
        return fields
          .map(function (fieldName) {
            return JSON.stringify(row[fieldName], replacer);
          })
          .join(",");
      });
      csv.unshift(fields.join(",")); // add header column
      csv = csv.join("\r\n");
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
  
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
  
      const formattedToday = dd + "/" + mm + "/" + yyyy;
  
      let csvContent = "data:text/csv;charset=utf-8," + csv;
      var encodedUri = encodeURI(csvContent);
  
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Voice Log" + formattedToday + ".csv");
      document.body.appendChild(link); // Required for FF
  
      link.click();
    }
    else{
      setOpen(false)
      Swal.fire({
        icon: "error",
        title: "No data",
        // text: "Please enter correct card expiry",
      });
    }
   
  };

  const downloadcsv2 = () => {
    var json = data3.map((obj) => {
      return {
        Phone_Number: obj.msisdn,
        Local_Time_Call_Started: obj.local_time_call_started,
        Total_Volume_Mb: obj.total_volume_mb,
        Tech_Used: obj.tech_used,
      };
    });
    if (json[0] !== undefined && json[0] !== null) {
      var fields = Object.keys(json[0]);
      var replacer = function (key, value) {
        return value === null ? "" : value;
      };
      var csv = json.map(function (row) {
        return fields
          .map(function (fieldName) {
            return JSON.stringify(row[fieldName], replacer);
          })
          .join(",");
      });
      csv.unshift(fields.join(",")); // add header column
      csv = csv.join("\r\n");
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
  
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
  
      const formattedToday = dd + "/" + mm + "/" + yyyy;
  
      let csvContent = "data:text/csv;charset=utf-8," + csv;
      var encodedUri = encodeURI(csvContent);
  
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Data Log" + formattedToday + ".csv");
      document.body.appendChild(link); // Required for FF
  
      link.click();
    }
    else{
      setOpen(false)
      Swal.fire({
        icon: "error",
        title: "No data",
        // text: "Please enter correct card expiry",
      });
    }
  };

  const downloadcsv3 = () => {
    var json = data2.map((obj) => {
      return {
        Phone_Number: obj.msisdn,
        Local_Time_Call_Started: obj.local_time_call_started,
        Answer_Time_Duration_Min: obj.ans_time_duration_min,
        Direction: obj.call_direction == 0 ? "Outgoing" : "Incoming",
        Number_Called: obj.subscriber_call_no,
        Tech_Used: obj.tech_used,
      };
    });
    if (json[0] !== undefined && json[0] !== null) {
      var fields = Object.keys(json[0]);
      var replacer = function (key, value) {
        return value === null ? "" : value;
      };
      var csv = json.map(function (row) {
        return fields
          .map(function (fieldName) {
            return JSON.stringify(row[fieldName], replacer);
          })
          .join(",");
      });
      csv.unshift(fields.join(",")); // add header column
      csv = csv.join("\r\n");
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
  
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
  
      const formattedToday = dd + "/" + mm + "/" + yyyy;
  
      let csvContent = "data:text/csv;charset=utf-8," + csv;
      var encodedUri = encodeURI(csvContent);
  
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Text Log" + formattedToday + ".csv");
      document.body.appendChild(link); // Required for FF
  
      link.click();
    }
    else{
      setOpen(false)
      Swal.fire({
        icon: "error",
        title: "No data",
        // text: "Please enter correct card expiry",
      });
    }
  };

  const country = [
    { code: "AD", name: "Andorra" },
    { code: "AE", name: "United Arab Emirates" },
    { code: "AF", name: "Afghanistan" },
    { code: "AG", name: "Antigua and Barbuda" },
  ];
  return (
    <Box sx={{ width: "100%", height: "80%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Voice"
            onClick={() => {
              setUsageType(1);
              setIsLoading(true);
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Data"
            onClick={() => {
              setUsageType(3);
              setIsLoading(true);
            }}
            {...a11yProps(1)}
          />
          <Tab
            label="Text"
            onClick={() => {
              setUsageType(2);
              setIsLoading(true);
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      {IsLoading ? (
        <div
          className="flex items-center justify-center h-full"
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: "95px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <TabPanel className="text-blue-gray-300" value={value} index={0}>
            <div className="flex justify-between justify-content-between">
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                style={{ margin: "0px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">Cycle</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={CycleValue}
                  label="Cycle"
                  name="cycle1"
                  onChange={handleCycle}
                >
                  {cycle.map((obj) => {
                    return <MenuItem value={obj.value1}>{obj.key1}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              {/* {showButton ? (
       <Button variant="outlined" size="small" onClick={downloadcsv}>
       Download CSV</Button>
      ) : (
        null
      )} */}

              <DownloadRoundedIcon
                style={{ cursor: "pointer" }}
                onClick={downloadcsv}
              />
            </div>
            <h1
              class="col-sm-12 col-md-12 col-lg-12"
              sx={style}
              style={{
                fontSize: "1.5rem",
                color:'#0d6efd',
                paddingTop: "20px",
                paddingBottom: "10px",
                margin: "0px",
              }}
              className="text-[6px] mt-[40px] mb-[10px] color-[#31a5d2]"
            >
              CALL LOGS
            </h1>

            <h2 className="mb-[20px] text-black" style={{ fontSize: "18px" }}>
              Total: {TotalMin} Min
            </h2>

            {/* {IsLoading ? (
                            <div className="flex items-center justify-center h-full">
                                <FuseLoading />
                            </div>
                        ) : ( */}
            <EnhancedTable data={PopulateAllData?.data} headcell={headCells1} />
            {/* )} */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="flex justify-between justify-content-between">
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                style={{ margin: "0px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">Cycle</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={CycleValue}
                  label="Cycle"
                  name="cycle2"
                  onChange={handleCycle}
                >
                  {cycle2.map((obj) => {
                    return <MenuItem value={obj.value1}>{obj.key1}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              {/* {showButton ? (
       <Button variant="outlined" size="small" onClick={downloadcsv2}>
       Download CSV</Button>
      ) : (
        null
      )} */}

              <DownloadRoundedIcon
                style={{ cursor: "pointer" }}
                onClick={downloadcsv2}
              />
            </div>
            <h1
              className="text-[36px] text-[#31a5d2] mt-[20px] mb-[10px]"
              style={{
                fontSize: "1.5rem",
                color:'#0d6efd',
                paddingTop: "20px",
                paddingBottom: "10px",
                margin: "0px",
              }}
            >
              DATA LOGS
            </h1>

            <h2 className="mb-[20px]" style={{ fontSize: "18px" }}>
              Total: {TotalMin.toFixed(2)} MB
            </h2>
            {/* 
                        {loadingarr[2] === 1 ? (
                            <div className="flex items-center justify-center h-full"> */}
            {/* <FuseLoading /> */}
            {/* </div> */}
            {/* ) : ( */}
            <EnhancedTable
              data1={PopulateAllData?.data}
              headcell={headCells2}
            />
            {/* )} */}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="flex justify-between justify-content-between">
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                style={{ margin: "0px" }}
                size="small"
              >
                <InputLabel id="demo-select-small">Cycle</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={CycleValue}
                  label="Cycle"
                  name="cycle2"
                  onChange={handleCycle}
                >
                  {cycle3.map((obj) => {
                    return <MenuItem value={obj.value1}>{obj.key1}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <DownloadRoundedIcon
                style={{ cursor: "pointer" }}
                onClick={downloadcsv3}
              />
            </div>
            <h1
              className="text-[36px] text-[#31a5d2] mt-[20px] mb-[10px]"
              style={{
                fontSize: "1.5rem",
                color:'#0d6efd',
                paddingTop: "20px",
                paddingBottom: "10px",
                margin: "0px",
              }}
            >
              TEXT LOGS
            </h1>

            <h2 className="mb-[20px]" style={{ fontSize: "18px" }}>
              Total:{" "}
              {data2.reduce(
                (total, obj) => total + parseFloat(obj.ans_time_duration_min),
                0
              )}
            </h2>
            {/* {isLo? (
                            <div className="flex items-center justify-center h-full">
                                <FuseLoading />
                            </div>
                        ) : ( */}
            <EnhancedTable data2={data2} headcell={headCells3} />
            {/* )} */}
          </TabPanel>
        </>
      )}
    </Box>
  );
}
