import React from "react";
import bar from "../assets/Group152.svg";
const Header = () =>{

return(


<div className="plans-main support">
        <div className="support-tetx support">
          <div className="header-head">
           
            <h1 className="head-p">Help Is Just A Touch Away</h1>
            <img className="bar-1" src={bar} />
            {/* <img className="im86 support" src={Group86} /> */}
          </div>
        </div>
    </div>

)

}

export default Header;