export const addtocart = (obj) => {
  return {
    type: "ADD_CART",
    payload: obj,
  };
};

export const removefromcart = (obj) => {
  return {
    type: "REMOVE_CART",
    payload: obj,
  };
};

export const emptymycart = () => {
  return {
    type: "EMPTY_MY_CART",
  };
};

export const signIn = (userInfo) => {
  return {
    type: "SIGN_IN",
    payload: userInfo,
  };
};

export const signOut = () => {
  return {
    type: "SIGN_OUT",
  };
};

export const signup = (userInfo) => {
  return {
    type: "SIGN_UP",
    payload: userInfo,
  };
};

export const addcouponredux = (coupon) => {
  return {
    type: "ADD_COUPON",
    payload: coupon,
  };
};

export const removecouponredux = (coupon) => {
  return {
    type: "REMOVE_COUPON",
    payload: coupon,
  };
};

export const addanything = (obj) => {
  return {
    type: "STORE_ANYTHING",
    payload: obj,
  };
};

export const UpdateUserInfoAction = (data) => {
  return {
    type: "UPDATE_USER_INFO",
    payload: data,
  };
};
export const GetCustomerCards = (data) => {
  return {
    type: "GET_CUSTOMER_CARDS",
    payload: data,
  };
};
export const RemoveCard = (data) => {
  return {
    type: "REMOVE_CREDIT_CARD",
    payload: data,
  };
};
export const AddNewCreditCard = (data) => {
  return {
    type: "ADD_NEW_CREDIT_CARD",
    payload: data,
  };
}
export const MakeCardPrimary = (id) => {
  console.log("🚀 ~ file: index.js:87 ~ MakeCardPrimary ~ id", id)
  return {
    type: "MAKE_PRIMARY_CARD",
    payload: id,
  };
};

export const Emptyorderhash = () =>{

  return {
    type: "EMPTY_ORDER_HASH",
  };
}

export const Updateshipping = (shipping) =>{

  return {
    type:"Update_Shipping",
    payload:shipping,
  }
}
export const Updatebilling = (billing) =>{

  return {
    type:"Update_Billing",
    payload:billing,
  }
}

export const resetState = () => ({
  type: "RESET_STATE",
});

export const setLoading = (loading) =>{
{
  return {
  type:"set_loading",
  payload:loading,
}
}
}


export const updateCart = (update)=>{

return{
  type:"update_cart",
  payload:update,
}
}


export const updateState = (update) =>{

  return {
    type:"update_state",
    payload:update
  }

}
