import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "./dashboard.css";
import { styled, width } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import Accountinfo from "./Accountinfo";
import { Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import Monthlybilling from "./Monthlybilling";
import { GetCustomerCards, signIn } from "../../actions";
import { connect, useDispatch } from "react-redux";
import LoadingSpinner from "../../components/assets/loaderspinner.gif";
import {
  customerData,
  customerinvoices,
  customerorders,
  customerPlans,
  GetCustomerCardsServices,
  getcustomerdetail,
} from "../../Service/Service";
import PaymentModal from "./PaymentModal";
import BillingHistory from "./BillingHistory";
import { useLayoutEffect } from "react";
const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)(
  ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  border-radius: 12px;
  opacity: 0.6;
  `
);

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 400px;
  background-color: ${blue[500]};
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  `
);

const Dashboard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totaldue, settotaldue] = useState(["0", "00"]);
  const [inovices, setInovices] = useState();
  const [Refresh, setRefresh] = useState(0);

  const [UserInfo, setUserInfo] = useState({});
  const [stats, setstats] = useState();
  const [customerDataResult, setCustomerDataResult] = useState();
  const [customerOrders, setCustomerOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [duedata, setduedate] = useState("");
  const handleOpenModal = () => {
    setOpenPaymentModal(!openPaymentModal);
    RefreshData();
  };

  useLayoutEffect(() => {
    setIsLoading(true);
    GetCustomerCardsServices().then((res) => {
      dispatch(GetCustomerCards(res));
    });
    setIsLoading(false);
  }, []);
  useEffect(() => {
    setIsLoading(true);
    GetCustomerDetails();
    getcustomerdetail(props.auth.userInfo.hash).then((res) => {
      dispatch(signIn(res));
    });
    customerinvoices().then((result) => {
      setstats(result);
      settotaldue(result.total);
      setduedate(result.due_date);
    });
    customerorders().then((result) => {
      setCustomerOrders(result);
    });

    customerData().then((res) => {
      setCustomerDataResult(res);
      setInovices(res);
    });
    setIsLoading(false);
  }, [Refresh]);

  const refreshinvoices=()=>{
    customerinvoices().then((result) => {
      setstats(result);
      settotaldue(result.total);
      setduedate(result.due_date);
    });
  }
  const RefreshData = () => {
    GetCustomerCardsServices().then((res) => {
      dispatch(GetCustomerCards(res));
    });
  };
  const GetCustomerDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "alar324r23423");
    var user = JSON.parse(localStorage.getItem("user_token"));

    var raw = "";

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`https://britex.pw/api/customer?hash=${props.auth.userInfo.hash}`, requestOptions)
      .then((response) => response.json())
      .then((result) =>
        // console.log(result)
        setUserInfo(result)
      )
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
      )}
      <Navbar />

      <div className="dashboardflex">
        <div className="leftdiv">
          <div className="card">
            <div className="cardflex">
              <h4 style={{ fontSize: 18, color: "#aab8bd" }}>Total Due</h4>
              <h5>{duedata}</h5>
              <h1>
                ${totaldue[0]}
                <sup>.{totaldue[1]}</sup>
              </h1>
            </div>
            <div>
              <p className="editbill">
                <a
                  onClick={() => {
                    document.getElementById("my-account").click();
                    setTimeout(() => {
                      document
                        .getElementById("payment")
                        .scrollIntoView({ behavior: "smooth" });
                    }, 400);
                  }}
                >
                  Edit Billing Preferences
                </a>
              </p>
              <Button onClick={handleOpenModal} className="payment">
                Make Payment
              </Button>
            </div>
          </div>
          <div>
            <a onClick={() => navigate("/plans")} className="add-line">
              Add a new line <i>+</i>
            </a>
          </div>
        </div>
        <div className="rightdiv">
          <TabsUnstyled
            style={{ maxWidth: 800, marginBottom: "50px" }}
            defaultValue={0}
          >
            <TabsList style={{ backgroundColor: "rgb(25 77 255)", minWidth:"unset" }}>
              <Tab id="my-account">My Account</Tab>
              <Tab>Monthly Billing</Tab>
              <Tab>Billing History</Tab>
            </TabsList>
            <TabPanel style={{ opacity: 1 }} value={0}>
              <Accountinfo
                UserInfo={UserInfo}
                setRefresh={setRefresh}
                Refresh={Refresh}
              />
            </TabPanel>
            <TabPanel style={{ opacity: 1 }} value={1}>
              <Monthlybilling inovices={stats}  auth={props.auth}/>
            </TabPanel>
            <TabPanel style={{ opacity: 1 }} value={2}>
              <BillingHistory customer={customerOrders} />
            </TabPanel>
          </TabsUnstyled>
        </div>
        <PaymentModal
          refresh={refreshinvoices}
          open={openPaymentModal}
          handleOpenModal={handleOpenModal}
          totalAmount={totaldue && totaldue[0]}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(Dashboard);

