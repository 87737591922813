import { PropaneSharp } from "@mui/icons-material";
import { Divider } from "@mui/material";
import React from "react";
import { useState } from "react";
import { checkemail, orderupdateshipping, createcustomer, updatecustomershipping, getcustomerdetail, signinWithApi } from "../../Service/Service";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { signIn } from "../../actions";
import states from '../../myData/states.json';
import Swal from "sweetalert2";
import { Updateshipping } from "../../actions";
import LoadingSpinner from "../../components/assets/loaderspinner.gif";
const Customerinfo = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, seterror] = useState({});
  const [emaiExist, setEmailExist]=useState(false)
  const validate = () => {


    let errors = {};
    // console.log(props.shippinginfo);
    if (props.customerinfo.firstname === "") {
      errors.fName = 0;
      // seterror(errors)
      // console.log(errors.fName);
      // return false;
    }
    if (props.customerinfo.lastname === "") {
      errors.lName = 1;
      // seterror(errors)
      // console.log(errors.lName);
      // return false;
    }
    if (props.customerinfo.email === "") {
      errors.email = 2;
      // seterror(errors)
      // return false;
    }
    else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(props.customerinfo.email)) {
      // console.log(3);
      errors.email = 2;
      // seterror(errors)
      // return false;
    }
    if (props.customerinfo.phone === "" || !/^\+?\d{1,3}[-. ]?\d{1,14}$/.test(props.customerinfo.phone)) {
      // seterror(3)
      errors.phone = 3;
      // seterror(errors)
      // return false;
    }
    if (props.customerinfo.password === "") {
      //   seterror(4)
      // return false;
      errors.pass = 4;
      // seterror(errors)
    }
    if (props.customerinfo.password != props.customerinfo.confirmpass) {
      errors.confirmPass = 5;
      // seterror(errors)
      // seterror(5)
      //  return false;
    }
    if (
      props.customerinfo.pin.length < 4 ||
      props.customerinfo.pin.length > 4 || !/^\d{4}$/.test(props.customerinfo.pin)

    ) {

      errors.pin = 6;
      // seterror(errors)
      //   seterror(6)

      // return false;
    }
    if (props.shippinginfo.firstname === "") {
      // seterror(7);
      // return false;
      errors.shipF_Name = 7;
      // seterror(errors)
    }
    if (props.shippinginfo.lastname === "") {
      errors.shipL_Name = 8;
      // seterror(errors)  
      // seterror(8);
      // return false;
    }
    if (props.shippinginfo.address1 === "") {
      errors.Add1 = 9;
      // seterror(errors)  
      // seterror(9);
      // return false;

    }
    if (props.shippinginfo.city === "" || !/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/.test(props.shippinginfo.city)) {

      errors.shipCity = 10;
      // seterror(errors)  
      // seterror(10);
      // return false;
    }
    if (props.shippinginfo.state === "") {
      errors.shipState = 11;
      // seterror(errors) 
      // seterror(11);
    }
    if (props.shippinginfo.zipcode === "" || !/^\d+$/.test(props.shippinginfo.zipcode) || props.shippinginfo.zipcode.length!==5) {
      // seterror(12);
      errors.shipZip = 12;
      // seterror(errors) 
    }
    if (props.customerinfo.email != "") {
      checkemail(props.customerinfo.email).then((result) => {
        // console.log(result);
        if (result.emailCount >0) {

          // seterror(23);
          // errors.emailExist = 23;
          // seterror(errors)
          setEmailExist(true)
          setIsLoading(false);
          // return false;
        }
        else {

          setEmailExist(false)
          if(Object.keys(errors).length === 0){
          orderupdateshipping(props.shippinginfo, props.cart.hash.order_hash).then(


            createcustomer(props.customerinfo, props.shippinginfo, props.cart.hash.order_hash).then((result) => {

              if (result.success) {
                setIsLoading(false);
                props.signIn(result.customer)
                signinWithApi(props.customerinfo.email,props.customerinfo.password).then(()=>{
                  Swal.fire({
                    icon: 'success',
                    title: 'Your account is created and logged In ',
                  })
                  props.setcollapse(1);

                })
               

              }



            })

          )
        }

        }
      });
    }

    if (Object.keys(errors).length !== 0) {
      seterror(errors);
      
      return false;
    }
    else {
      
      seterror({});
      setIsLoading(true);
    }
  };

  const updateinfo = () => {
    setIsLoading(true);
    updatecustomershipping(props.shippinginfo, props.auth.userInfo).then((result) => {

      getcustomerdetail(props.auth.userInfo.hash).then((result) => {

        props.Updateshipping(result)
      })

      setIsLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Your shipping address is updated',
      })
    })
  }
  const handleNumType = (evt) => {
    if (evt.key !== 'Backspace' && evt.key !== 'Delete' && (evt.key.length !== 1 || evt.key < '0' || evt.key > '9')) {
      evt.preventDefault();
    }
  }
  const handlePhoneNum = (evt) => {
    if (
      evt.key !== 'Backspace' && evt.key !== 'Delete' && (
        evt.key.length !== 1 || (
          evt.key !== '+' && evt.key !== '-' && (
            evt.key < '0' || evt.key > '9'
          )
        )
      )
    ) {
      evt.preventDefault();
    }
  }
  
  const checkEmailWrapper = (e) =>{

    console.log(e.target.value);
    setEmailExist(false);
    
    let errors = {};

   if(e.target.value.length>0)
   {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(e.target.value)) {
      // console.log(3);
      errors.email = 2;
      // seterror(errors)
      // return false;
    }
    else{
      console.log(e.target.value);
      checkemail(e.target.value).then((result)=>{
        if (result.emailCount >0) {
         setEmailExist(true)
       
        }
      })
      seterror({})
    }
    if (Object.keys(errors).length !== 0) {
      seterror(errors);
  
    
    }

   } 
   else{

    seterror({});
   }
   
    props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })
   
   


  }
  return (
    <>

      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <img width={80} height={80} src={LoadingSpinner} />
          </div>
        </div>
      )}


      {
        !props.auth.isSignedIn && <>
          <h4 className="mb-3 fw-bold" style={{ color: '#152D79 ' }}>1.Customer Info</h4>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="fw-bold" for="firstName">First name</label>
              <input type="text" className="form-control" id="firstName" placeholder="" name="firstname" value={props.customerinfo.firstname} onChange={(e) => props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} />

              {
                error.fName === 0 && <div className="invalid-feedback" style={{ display: "block" }}>
                  Valid first name is required.
                </div>
              }



            </div>
            <div className="col-md-6 mb-3">
              <label className="fw-bold" for="lastName">Last name</label>
              <input type="text" className="form-control" id="lastName" placeholder="" name="lastname" value={props.customerinfo.lastname} onChange={(e) => props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />

              {
                error.lName === 1 &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Valid last name is required.
                </div>
              }
            </div>
          </div>
          <div className="mb-3">
            <label className="fw-bold" for="email">Email <span className="text-muted">*</span></label>
            <input type="email" className="form-control" id="email" name="email" value={props.customerinfo.email} onChange={(e) => checkEmailWrapper(e)} placeholder="you@example.com" />

            {
              error.email === 2 &&
              <div className="invalid-feedback" style={{ display: "block" }}>
                Please enter a valid email address for shipping updates.
              </div>
            }
            {
              emaiExist &&
              <div className="invalid-feedback" style={{ display: "block" }}>
                This email already registered.
              </div>
            }
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="fw-bold" for="firstName">Phone</label>
              <input type="tel" className="form-control" id="firstName" name="phone" placeholder="" value={props.customerinfo.phone}  onChange={(e) =>/^[0-9]*$/g.test(e.target.value)&& props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />

              {
                error.phone === 3 &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Valid Phone number is required.
                </div>
              }
            </div>
            <div className="col-md-6 mb-3">
              <label className="fw-bold" for="lastName">Alternate Phone</label>
              <input type="tel" className="form-control" name="altphone" id="lastName" placeholder="" value={props.customerinfo.altphone}  onChange={(e) => props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />


            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="fw-bold" for="firstName">Password</label>
              <input type="password" class="form-control" id="firstName" placeholder="" name="password" value={props.customerinfo.password} onChange={(e) => props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />

              {
                error.pass === 4 &&
                <div class="invalid-feedback" style={{ display: "block" }}>
                  Must enter password
                </div>
              }
            </div>
            <div class="col-md-6 mb-3">
              <label className="fw-bold" for="lastName">Confirm Password</label>
              <input type="password" class="form-control" id="lastName" name="confirmpass" onChange={(e) => props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} placeholder="" value={props.customerinfo.confirmpass} required />
              {error.confirmPass === 5 &&
                <div class="invalid-feedback" style={{ display: "block" }}>
                  Confirm password mismatch
                </div>
              }

            </div>
          </div>
          <div class="mb-3">
            <label className="fw-bold" for="address2">Create a 4 digit pin <span class="text-muted">*</span></label>
            <input type="text" class="form-control" name="pin" value={props.customerinfo.pin} id="address2"  onChange={(e) => { e.target.value.length < 5 && props.setcustomerinfo((prev) => { return { ...prev, [e.target.name]: e.target.value } }) }} placeholder="0000" />
            {error.pin === 6 &&
              <div class="invalid-feedback" style={{ display: "block" }}>
                Enter 4 digit pin
              </div>
            }
          </div>
        </>

      }


      <div className="accordion" id="shippingAccordion">
        <div className="accordion-item border-0">
          <h2 className="accordion-header" id="shippingHeading">

            {
              !props.auth.isSignedIn ?
                (<button className="accordion-button border-0  mb-3 p-0 pb-3" type="button" data-bs-toggle="collapse" data-bs-target="#shippingCollapse" aria-expanded="true" aria-controls="shippingCollapse">
                  Shipping Info
                </button>)
                :
                (<button className="accordion-button border-0 collapsed mb-3 p-0 pb-3" type="button" data-bs-toggle="collapse" data-bs-target="#shippingCollapse" aria-expanded="false" aria-controls="shippingCollapse">
                  1.Shipping Info
                </button>)
            }
          </h2>
          <div id="shippingCollapse" className={`accordion-collapse collapse ${!props.auth.isSignedIn && "show"}`} aria-labelledby="shippingHeading" data-bs-parent="#shippingAccordion">
            <div className="accordion-body">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="fw-bold" for="firstName">First name</label>
                  <input type="text" className="form-control" name="firstname" id="firstName" placeholder="" value={props.shippinginfo.firstname} onChange={(e) => props.setshippinginfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />
                  {error.shipF_Name === 7 &&
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      Valid first name is required.
                    </div>
                  }

                </div>
                <div className="col-md-6 mb-3">
                  <label className="fw-bold" for="lastName">Last name</label>
                  <input type="text" className="form-control" name="lastname" id="lastName" placeholder="" value={props.shippinginfo.lastname} onChange={(e) => props.setshippinginfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />
                  {error.shipL_Name === 8 &&
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      Valid last name is required.
                    </div>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="fw-bold" for="firstName">Address1</label>
                  <input type="text" className="form-control" name="address1" placeholder="" value={props.shippinginfo.address1} onChange={(e) => props.setshippinginfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />
                  {
                    error.Add1 === 9 &&
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      Valid address is required.
                    </div>
                  }

                </div>
                <div className="col-md-6 mb-3">
                  <label className="fw-bold" for="lastName">Address2</label>
                  <input type="text" className="form-control" name="address2" id="lastName" placeholder="" value={props.shippinginfo.address2} onChange={(e) => props.setshippinginfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />

                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label for="country" className="form-label fw-bold">City</label>
                  <input type="text" className="form-control" name="city" id="lastName" placeholder="" value={props.shippinginfo.city} onKeyDown={(evt) => {
                    !(/^[a-zA-Z ]*$/.test(evt.key)) && evt.preventDefault();
                  }}
                    onChange={(e) => props.setshippinginfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} required />
                  {error.shipCity === 10 &&
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      Valid City name is required.
                    </div>
                  }
                </div>

                <div className="col-md-6 mb-3">
                  <label for="state" className="form-label fw-bold">State</label>

                  <select className="form-select" name="state" id="state" onChange={(e) => props.setshippinginfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} value={props.shippinginfo.state} required>
                    <option value="">Choose...</option>
                    {states.map((option) => {
                      return (
                        <option value={option.code}>{option.name}</option>
                      )
                    })
                    }
                  </select>
                  {error.shipState === 11 &&


                    <div className="invalid-feedback" style={{ display: "block" }}>
                      Please provide a valid state.
                    </div>

                  }
                </div>
                </div>
                <div classNameName="row">
                <div className="col-md-3">
                  <label for="zip" className="form-label fw-bold">Zip</label>
                  <input type="text" name="zipcode" className="form-control" id="zip" onKeyDown={handleNumType} onChange={(e) => props.setshippinginfo((prev) => { return { ...prev, [e.target.name]: e.target.value } })} value={props.shippinginfo.zipcode} placeholder="" required />

                  {error.shipZip === 12 &&
                    <div className="invalid-feedback" style={{ display: "block" }}>
                      Valid zip code required.
                    </div>
                  }

                </div>

              </div>
              {/* <div className="row">
              <div className="col-md-5 mb-3">
                <label className="fw-bold" for="country">Country</label>
                <select className="custom-select d-block w-100" id="country" required>
                  <option value="">Choose...</option>
                  <option>United States</option>
                </select>
                <div className="invalid-feedback">
                  Please select a valid country.
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <label className="fw-bold" for="state">State</label>
                <select className="custom-select d-block w-100" id="state" required>
                  <option value="">Choose...</option>
                  <option>California</option>
                </select>
                <div className="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>
              <div className="col-md-3 mb-3">
                <label className="fw-bold" for="zip">Zip</label>
                <input type="text" className="form-control" id="zip" placeholder="" required />
                <div className="invalid-feedback">
                  Zip code required.
                </div>
              </div>
            </div> */}

              {
                props.auth.isSignedIn ? <Button onClick={updateinfo} style={{ display: "block", margin: "20px 0" }}>Update</Button>
                  : <Button onClick={validate} style={{ display: "block", margin: "20px 0" }}>Save & Login</Button>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )

}


const mapStateToProps = (state) => {

  return {
    cart: state.cart,
    auth: state.authReducer
  }

}


export default connect(mapStateToProps, { signIn, Updateshipping })(Customerinfo);