import React from "react";
import img1 from "../assets/Group 278.png";
import Group86 from "../assets/Group86.svg";
import img29 from "../assets/Group29.svg";
import { useNavigate } from "react-router-dom";
const Section6 = () =>{
    const navigate = useNavigate();
return(

<>
   <div className="section6">

    <div className="left6">

        <img src={img1}  />
    </div>
    <div className="right6">
 
        <p className="ppa">
        Want To Stay Connected To Your <br></br> Loved Ones, For A Low Monthly Fee?
        </p>
        <img  className="img299" src={ img29} />
    <button onClick={() => navigate("/plans")}>Join Us Today</button>

    </div>

   </div>

</>
)

}

export default Section6