import { AddModerator, ArchitectureRounded, CardGiftcard } from "@mui/icons-material";
import { cardActionAreaClasses, Divider, List } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { addanything,emptymycart } from "../../actions";
import { getTaxrate } from "../../Service/Service";
import Navbar from "../Navbar";
import './Confirm.css'
import { Emptyorderhash } from "../../actions";

const Confirm = (props) =>{

    const [totalAmount,setTotalAmount] = useState(0);
    const [subTotalAmount,setSubTotalAmount] = useState(0);
    const [monthlyCharge,setMonthlyCharge] = useState(0);
    const [Tax,setTax]= useState(0);
    const [regulatoryFees,setRegulatoryfees]=useState(0);
    const [discount,setdiscount] = useState(0);
    const [shipping,setshipping] = useState(0);
    

    const location = useLocation();
    const[finaldata,setfinaldata]=useState(location.state.finaldata);
    // useEffect(()=>{
    //     // props.emptymycart();
    //     // props.Emptyorderhash();
    //     // setTotalAmount(1000);
    //     // setSubTotalAmount(subTotalPrice());
    //     // setMonthlyCharge(calMonthlyCharge());
    //     // setTax(calTaxes());
    //     // setRegulatoryfees(callRegulatory());
    //     // setdiscount(props.cart.couponAmount);
    //     // setshipping(getshippingfee());

    // },[])

    // const getshippingfee = () =>{
    //     const shippingfee=0;
    //     if(props.cart!=null)
    //         {
    //             if(props.cart.cart.order_groups.length>0)
    //             {
    //                         props.cart.cart.order_groups.forEach((cart)=>{
    //     if(cart.device)
    //         {
    //           if(cart.device.shipping_fee!=null)
    //           {
    //             shippingfee=shippingfee+cart.device.shipping_fee;
    //           }
    //           if(cart.sim)
    //           {
    //               if(cart.sim.shipping_fee)
    //               {
    //                 shippingfee=shippingfee+cart.device.shipping_fee;
    //               }
    //           }
    //         }
    //     })
    // }
    //     }
   
    //     return shippingfee;
    // }


    // const callRegulatory = () =>{

    //     const prices=0;
    // if(props.cart!=null)
    //     {
    //         if(props.cart.cart.order_groups.length>0)
    //         {
    //                     props.cart.cart.order_groups.forEach((cart)=>{

    //                         if(cart.plan!=null && cart.hasOwnProperty('status'))
    //                         {
    //                             if(cart.plan.regulatory_fee_type==1)
    //                             {
    //                                 prices=prices+cart.plan.regulatory_fee_type;
    //                             }
    //                             else if(cart.plan.regulatory_fee_type==2)
    //                             {
    //                                     if(cart.plan_prorated_amt!=null)
    //                                     {
    //                                         prices=prices+ (cart.plan.regulatory_fee_amount*cart.plan_prorated_amt/100)
    //                                     }
    //                                     else{

    //                                         prices=prices+ (cart.plan.regulatory_fee_amount*cart.plan.amount_recurring/100)
                              
    //                                     }

    //                             }
    //                         }

    //                     })
    //         }
    //     }

    //     return prices;
    // }

    // const calTaxes = (taxId=null) =>{

    //    let prices=0;
    //     if(props.cart!=null)
    //     {
    //         if(props.cart.cart.order_groups.length>0)
    //         {
    //                     props.cart.cart.order_groups.forEach((cart)=>{
    //                         prices=prices+calTaxableItems(cart,taxId,2)
    //                     })
    //                 }
    //     }

    //     taxId?props.addanything({tax_total:prices}):props.addanything({tax_total:0});
    
    //     return prices
                

    // }

    // const calTaxableItems = (cart,taxId) =>{

    //     var stateId="";
      
    //     if(!taxId)
    //     {
    //         if(props.cart.cart.business_verification && props.cart.cart.business_verification.billing_state_id)
    //         {
      
    //           props.addanything({'tax_id':props.cart.cart.business_verification.billing_state_id});
    //         }
    //         else if(props.cart.cart.customer && props.cart.cart.customer.billing_state_id){
      
    //             props.addanything({tax_id:props.cart.cart.customer.billing_state_id});
    //         }
      
    //     }
    //     else{
    //         props.addanything({'tax_id':taxId})
    //     }
      
    //     stateId=[{'tax_id':'AL'}];
      
    //     let taxrate;
    //     getTaxrate("AL").then(result=>{
    //         taxrate=result;
    //         if(!props.cart.taxrate || props.cart.taxrate && taxrate.tax_rate && props.cart.taxrate != taxrate.tax_rate  )   
    //         {
    //             getTaxrate("AL").then(result=>{
               
    //             props.addanything({taxrate:result.tax_rate?result.tax_rate:0});
    //             })
      
               
      
    //         }
    //         })
      
        
    //         var taxpercentage=props.cart.taxrate/100;
      
    //         if(props.cart.status && props.cart.status=="SamePlan")
    //         {
    //             var addons = addTaxesToAddons(cart,taxpercentage);
    //             return addons;
      
    //         }
    //         if(props.cart.status && props.cart.status=="Upgrade")
    //         {
    //             var plans =addTaxesToPlans(cart, cart.plan , taxpercentage);
    //             var addons= addTaxesToAddons(cart,taxpercentage);
    //             return plans + addons;
    //         }
      
      
    //         var devices = addTaxesDevices(cart,cart.device, taxpercentage);
      
    //         var sims = addTaxesSims(cart , cart.sim , taxpercentage);
      
    //         var plans = addTaxesToPlans(cart, cart.plan ,taxpercentage);
      
    //         var addons = addTaxesToAddons(cart, taxpercentage);
      
    //         return devices + sims + plans +addons;
            
      
    //   }
      
    // const subTotalPrice = () =>{

    //         return calDevicePrices()+getPlanPrices()+getSimPrices()+getAddonPrices()+0;
    // }


    // const calMonthlyCharge =()=>{

    //     let prices=0;
    //     if(props.cart!=null)
    //     {
    //         if(props.cart.cart.order_groups.length>0)
    //         {
    //                     props.cart.cart.order_groups.forEach((cart)=>{
    //                                 prices=prices+(cart.plan!=null?cart.plan.amount_recurring:0)

    //                                 if(cart.addons!=null)
    //                                 {

    //                                     cart.addons.forEach((addon)=>{
    //                                             if(addon.subscription_addon_id!=null)
    //                                             {
    //                                                 prices=prices+0;
    //                                             }

    //                                             else{

    //                                                 prices=prices+addon.amount_recurring;
    //                                             }
    //                                     })
    //                                 }
    //                     })

    //         }


    //     }


    //     if(props.cart.cart.hasOwnProperty('paid_invoice'))
    //     {
    //             prices=prices/2
    //     }
    //         return prices;
    // }

    // const getAddonPrices = () =>{
    //         let prices=0;
    //     if(props.cart!=null)
    //     {
    //         if(props.cart!=null)
    //         {
    //             if(props.cart.cart.order_groups.length>0)
    //             {

    //                 props.cart.cart.order_groups.forEach((cart)=>{

    //                     if(cart.addons !=null)
    //                     {

    //                         cart.addons.forEach((addon)=>{
    //                                 if(addon.prorated_amt!=null)
    //                                 {

    //                                     prices=prices+addon.prorated_amt;
    //                                 }
    //                                 else{
    //                                     prices=prices+addon.amount_recurring;
    //                                 }

    //                         })
    //                     }
    //                 })
    //             }
    //         }
    //     }
    //         return prices;
    // }

    // const getSimPrices = () =>{

    //     let prices=0;
    //     if(props.cart!=null)
    //     {
    //         if(props.cart!=null)
    //         {
    //             if(props.cart.cart.order_groups.length>0)
    //             {

    //                 props.cart.cart.order_groups.forEach((cart)=>{

    //                         if(cart.sim!=null && cart.plan!=null)
    //                         {

    //                                 prices=prices+cart.sim.amount_w_plan;
    //                         }
    //                         else if(cart.sim!=null && cart.plan==null)
    //                         {

    //                             prices=prices+cart.sim.amount_alone;
    //                         }
    //                 })
    //             }

    //         }

    //     }

    //     return prices;
    // }

    // const getPlanPrices=()=>{

    //     let prices=0;

    //     if(props.cart!=null)
    //     {
    //         if(props.cart.cart.order_groups.length>0)
    //         {

    //             props.cart.cart.order_groups.forEach(cart=>{

    //                 if(cart.plan.hasOwnProperty('amount_onetime') && cart.plan.amount_onetime>0)
    //                 {

    //                         prices=prices+cart.plan.amount_onetime;
    //                 }
    //                 if(cart.plan_prorated_amt)
    //                 {

    //                     prices=prices+cart.plan_prorated_amt;                  
    //                 }
    //                 else{

    //                     prices= cart.plan!=null?cart.plan.amount_recurring:0;
    //                 }
    //             })
    //         }

    //     }
    //         return prices;

    // }

    // const calDevicePrices =()=>{

    //     let prices =0 ;
    //     let active_group_id = props.cart.cart.hasOwnProperty('active_group_id')?props.cart.active_group_id:null;

    //     if(props.cart!=null)
    //     {
    //         if(props.cart.cart.order_groups.length>0)
    //         {

    //             props.cart.cart.order_groups.forEach(cart => {
                    
    //                     if(cart.device!=null)
    //                     {
    //                         if(cart.plan===null)
    //                         {

    //                             if(cart.id===active_group_id)
    //                             {
    //                                 prices=prices+cart.device.amount_w_plan;
    //                             }
    //                             else
    //                             {
    //                                 prices=prices+cart.device.amount;
    //                             }
    //                         }
    //                         else{

    //                                 prices=prices+cart.device.amount_w_plan;

    //                         }

    //                     }

    //             });
    //         }
            
    //     }


    //         return prices;

    // }

   

    
    // const addTaxesToAddons = (cart,taxpercentage) =>{
    //     var addonTax=0;
    //     if(props.cart.addons)
    //     {
    //         props.cart.addons.forEach((addon)=>{
              
    //             if(addon.taxable===1)
    //             {
    //                var amount = addon.prorated_amt !=null ?addon.prorated_amt:addon.amount_recurring;
    //                 if(props.cart.couponAmount)
    //                 {
        
    //                  var discounted = getCouponPrice(props.couponAmount,addon,4);
    //                  amount = amount>discounted ?amount-discounted:0;
    //                 }
    //                 addonTax = addonTax+(taxpercentage*amount);
    //             }
        
    //         })
        
    //     }
    //     return addonTax;
        
    //     }

    //     const addTaxesToPlans = (cart , item , taxpercentage) =>{

    //         var plantax=0;
    //         if(item!=null && item.taxable)
    //         {
    //             var amount = cart.plan_prorated_amt !=null ? cart.plan_prorated_amt : item.amount_recurring;
    //              amount = item.amount_onetime !=null ? amount + item.amount_onetime : amount;
          
    //             if(props.cart.couponAmount)
    //             {
    //               var discounted= getCouponPrice(props.cart.couponAmount,item,1);
    //                 amount= amount>discounted ? amount - discounted :0;
    //             }
    //               plantax = plantax + taxpercentage*amount;
    //         }
          
    //         return plantax;
    //       }
          
    //       const addTaxesDevices = (cart , item , taxpercentage) =>{

    //         var itemtax=0;
    //         if(item && item.taxable )
    //         {
    //           var amount = props.cart.plan != null ? item.amount_w_plan : item.amount;
          
    //           if(props.cart.couponAmount)
    //           {
    //             var discounted = getCouponPrice(props.cart.couponAmount,item,2);
          
    //             amount = amount > discounted ? amount - discounted :0;
    //           }
    //         itemtax=taxpercentage*amount;
          
    //         }
          
    //         return itemtax;
          
    //       }
          
    //       const addTaxesSims = (cart,item ,taxPercentage) =>{

    //         var itemTax=0;
            
    //         if(item && item.taxable)
    //         {
            
    //           var amount = cart.plan != null ? item.amount_w_plan : item.amount_alone;
            
    //           if(props.cart.couponAmount)
    //           {
            
    //             var discounted = getCouponPrice(props.cart.couponAmount,item,3);
    //             amount=amount > discounted?amount-discounted:0;
    //             itemTax=itemTax+taxPercentage*amount;
    //           }
            
    //         }
            
    //         return itemTax;
    //         }
            
    //         const getCouponPrice = (couponData , item ,itemType) =>{
    //             var productDiscount=0;
                
    //               couponData.forEach((coupon)=>{
                
    //                 var type = coupon.coupon_type;
    //                 if(type==1)
    //                 {
    //                     var appliedTo = coupon.applied_to.applied_to_all;
    //                 }
    //                 else if(type==2)
    //                 {
                
    //                   var appliedTo = coupon.applied_to.applied_to_types;
    //                 }
    //                 else if(type==3)
    //                 {
                
    //                   var appliedTo= coupon.applied_to.applied_to_products;
    //                 }
                
    //                 if(appliedTo.length>0)
    //                 {
                
    //                   appliedTo.forEach((product)=>{
    //                       if(product.order_product_type===itemType && product.order_product_id===item.id)
    //                       {
    //                           productDiscount=productDiscount+product.discount;
    //                       }
                
                
                
    //                   })
    //                 }
                
                
    //               })
                
    //               return productDiscount;
    //             }


                // const [total,settotal] = useState(props.cart.cart.order_groups.reduce((total,obj)=>total+=obj.plan.amount_recurring,0))
                const navigate = useNavigate();
                
                useEffect(()=>{
                    props.emptymycart();
                    props.Emptyorderhash();
                },[])

                  const clearfix = () =>{

                    
                    navigate("/");
                  }

                  useEffect(()=>{

                      console.log(finaldata)
                  })
return (

<>
<Navbar/>

<div style={{display:"flex" , justifyContent:"center" , alignItems:"center",flexDirection:"column", padding:"0 25px"}}>

    <h1 className="thanks mb-2">Thank You</h1>
    <p className="process">We are now processing your purchase!</p>
    <h4 className="clickhere mt-4">Click <a href={"https://britex.pw/api/invoice/download/"+finaldata.cart.company.id+"?order_hash="+finaldata.hash.order_hash}  >here</a> to download your invoice for order number {localStorage.getItem("order_num")}</h4>
    <p className="email">An email is also sent with attached invoice.pdf to your mail</p>
</div>
<div  className="cartitems">
{finaldata.cart.order_groups.map((obj)=>{
    return(
    <div style={{maxWidth:"436px", width:'100%', borderBottom:"1px solid rgba(0,0,0,.12)"}}>    
    {obj.plan&&
    <div style={{display:"flex",justifyContent:"space-between",margin:"20px 30px"}}>
    <div >Plan : {obj.plan?.name}</div>
    <div>${obj.plan_prorated_amt?obj.plan_prorated_amt:obj.plan?.amount_recurring.toFixed(2)}</div>
    </div>
    }
    {obj.sim&&
    <div style={{display:"flex",justifyContent:"space-between",margin:"20px 30px"}}>
    <div >Sims : {obj.sim.name}</div>
    <div>${obj.sim?.amount_w_plan.toFixed(2)}</div>
    </div>
    }     
    {obj.device&&
    <div style={{display:"flex",justifyContent:"space-between",margin:"20px 30px"}}>
    <div >Device : {obj.device.name}</div>
    <div>${obj.plan?obj.device.amount_w_plan.toFixed(2):obj.device.amount}</div>
    </div>
    } 
    {
       obj.plan!=null &&  obj.plan.amount_onetime!=0&&
      <div style={{display:"flex",justifyContent:"space-between",margin:"20px 30px"}}>
      <div >Activation fee:</div>
      <div>${obj.plan?.amount_onetime.toFixed(2)}</div>
      </div>
    }    
</div>
    )
})


}
</div>

<div style={{display:"flex", justifyContent:"center"}}>

{/* <div style={{flexBasis:"70%"}}>

</div> */}

<div className="finalinvoice">

<List>
{/* <div className='myflex'>
            <div>Monthlycharge:</div>
            <div>${finaldata?.cart?.monthlyCharge.toFixed(2)}</div>
          </div> */}
      <div className='myflex'>
            <div>Subtotal:</div>
            <div>${finaldata?.cart?.subtotalPrice.toFixed(2)}</div>
          </div>
          <div className='myflex'>
            <div>Shipping:</div>
            <div>+ ${finaldata?.cart?.shippingFee.toFixed(2)}</div>
          </div>
          <div className='myflex'>
            <div>Coupons:{finaldata.cart.couponDetails.map((obj)=><small>{obj.code}</small>)}</div>
            <div>- ${Math.abs(finaldata.cart.coupons)}</div>
          </div>
          <div className='myflex'>
            <div>State Tax:</div>
            <div>+ ${finaldata.cart.taxes.toFixed(2)}</div>
          </div>
          <div className='myflex'>
            <div>Regulatory Fee:</div>
            <div>+ ${finaldata?.cart?.regulatory.toFixed(2)}</div>
          </div>
      </List>
    
      <Divider />
      <div >
   
        <div className='myflex' >
        <div><h3>Total:</h3></div>
        <h3>${finaldata?.cart?.totalPrice.toFixed(2)}</h3>
        
     
      </div>
      <div style={{display:"flex",justifyContent:"center",marginBottom:20}}>
      <Button className="btn btn--primary btn--medium primary" style={{width:131}}variant="contained" color="success" onClick={clearfix}>Go Back</Button>
      </div>
      </div>


</div>
</div>
</>
)



}



const mapStateToProps = (state) =>{

    return{
    cart : state.cart,
    auth: state.authReducer
        }
  
  }
  

  export default connect(mapStateToProps,{addanything,emptymycart, Emptyorderhash})(Confirm);
