import React from "react";
import TextField from "@mui/material/TextField";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import states from '../../myData/states.json';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useImperativeHandle } from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const Billinginfo = React.forwardRef((props,ref) => {

  const [error,seterror] = useState(""); 
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  
  useImperativeHandle(ref, () => ({
    childFunction3() {
    
        return validate()
      
  },
  }));
  
  const validate = () =>{
  
    if(props.billinginfo.firstname==="")
    {
    setState({ open: true,vertical: 'bottom',horizontal: 'right'} );
    seterror("Enter firstname"); 
    setrederror(0)
    return false;
    }
    else if(props.billinginfo.lastname==="")
    {
      setState({ open: true,vertical: 'bottom',horizontal: 'right'} );
      seterror("Enter lastname"); 
      setrederror(1)
      return false;
  
    }
    else if(props.billinginfo.address1==="")
    {
      setState({ open: true,vertical: 'bottom',horizontal: 'right'} );
      seterror("Enter address1"); 
      setrederror(2)
      return false;
    }
    else if(props.billinginfo.city==="")
    {
      setState({ open: true,vertical: 'bottom',horizontal: 'right'} );
      seterror("Enter city"); 
      setrederror(3)
      return false;
    }
    else if(props.billinginfo.state==="")
    {
      setState({ open: true,vertical: 'bottom',horizontal: 'right'} );
      seterror("Select state"); 
      setrederror(4)
      return false;
    }
    else if(props.billinginfo.zipcode==="")
    {
      setState({ open: true,vertical: 'bottom',horizontal: 'right'} );
      seterror("Enter zipcode"); 
      setrederror(5)
      return false;
  
    }
  
    return true;
  
  
  }
    const [rederror,setrederror]=useState(9);
    const updateEvent =  (event) =>{

        setrederror(9);
        const {name,value} = event.target;
      
            props.setbillinginfo((preval)=>
                {return{
                  ...preval,
                    [name]:value,
                }
        
            }
            );
      
      }
      

  

      
    
  return (
    <>
      <form className="myform">
        <FormGroup>
         
          <FormControlLabel
            control={<Checkbox onClick={()=>props.setbillinginfo(props.Shippinginfo)}/>}
            label="Use same as shipping address"
          />
        </FormGroup>

        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            className="half"
            label="First Name"
            variant="outlined"
            value={props.billinginfo.firstname} 
            onChange={updateEvent}
            name="firstname"
            error={rederror===0}
          />
          <TextField   InputLabelProps={{
              shrink: true,
            }} name="lastname"  error={rederror===1} label="Last Name" onChange={updateEvent} value={props.billinginfo.lastname} className="half" variant="outlined" />
        </div>

        <div>
          <TextField   InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            className="half"
            label="Address1"
            variant="outlined"
            value={props.billinginfo.address1}
            onChange={updateEvent}
            name="address1"
            error={rederror===2}
          />
          <TextField   InputLabelProps={{
              shrink: true,
            }}  label="Address2"  name="address2" onChange={updateEvent} value={props.billinginfo.address2} className="half" variant="outlined" />
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }} 
            id="outlined-basic"
            className="half"
            label="City"
            variant="outlined"
            name="city"
            value={props.billinginfo.city}
            onChange={updateEvent}
            error={rederror===3}
          />
     
       
          <TextField    InputLabelProps={{
              shrink: true,
            }}  error={rederror==4} name="state" onChange={updateEvent} value={props.billinginfo.state}  className="half"  id="outlined-required" select label="State" required > 
         
            {states.map((option,i) => (
            <MenuItem key={i} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
          </TextField>
         
         
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            className="half"
            label="Zip Code"
            variant="outlined"
            value={props.billinginfo.zipcode}
            onChange={updateEvent}
            name="zipcode"
            error={rederror===5}
          />
          <div className="half"></div>
        </div>
      </form>
      <Snackbar
        sx={{top:400}}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="I love snacks"
        key={vertical + horizontal}
      
      ><Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
    {error}
  </Alert></Snackbar>
    </>
  );
});

export default Billinginfo;
