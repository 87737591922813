import storage from 'redux-persist/lib/storage';

const INTIAL_STATE = {
    isSignedIn: "",
    userInfo: ""
  };
  
  export default (state = INTIAL_STATE, action) => {

    switch (action.type) {
      case 'SIGN_IN':
        return { ...state, isSignedIn: true, userInfo: action.payload };
      case 'SIGN_OUT':
        localStorage.clear();
        storage.removeItem('persist:root');
        
        return { ...state, isSignedIn: "", userInfo: "" };
      case 'UPDATE_USER_INFO':
        const key = action.payload.key1
        const value=action.payload.val1
        if(key === "fname") { return {...state,userInfo:{...state.userInfo,fname:value}}}
        else if (key === "lname") {return {...state,userInfo:{...state.userInfo,lname:value}}}
        else if (key === "email") {return {...state,userInfo:{...state.userInfo,email:value}}}
        else if (key === "phone") {return {...state,userInfo:{...state.userInfo,phone:value}}}
        else if (key === "alternate_phone") {return {...state,userInfo:{...state.userInfo,alternate_phone:value}}}
        else if (key === "shipping_fname") {return {...state,userInfo:{...state.userInfo,shipping_fname:value}}}
        else if (key === "shipping_lname") {return {...state,userInfo:{...state.userInfo,shipping_lname:value}}}
        else if (key === "shipping_address1") {return {...state,userInfo:{...state.userInfo,shipping_address1:value}}}
        else if (key === "shipping_address2") {return {...state,userInfo:{...state.userInfo,shipping_address2:value}}}
        else if (key === "shipping_city") {return {...state,userInfo:{...state.userInfo,shipping_city:value}}}
        else if (key === "shipping_state_id") {return {...state,userInfo:{...state.userInfo,shipping_state_id:value}}}
        else if (key === "shipping_zip") {return {...state,userInfo:{...state.userInfo,shipping_zip:value}}}
        else if (key === "billing_fname") {return {...state,userInfo:{...state.userInfo,billing_fname:value}}}
        else if (key === "billing_lname") {return {...state,userInfo:{...state.userInfo,billing_lname:value}}}
        else if (key === "billing_address1") {return {...state,userInfo:{...state.userInfo,billing_address1:value}}}
        else if (key === "billing_address2") {return {...state,userInfo:{...state.userInfo,billing_address2:value}}}
        else if (key === "billing_city") {return {...state,userInfo:{...state.userInfo,billing_city:value}}}
        else if (key === "billing_state_id") {return {...state,userInfo:{...state.userInfo,billing_state_id:value}}}
        else if (key === "billing_zip") {return {...state,userInfo:{...state.userInfo,billing_zip:value}}}
        else if (key === "auto_pay") {return {...state,userInfo:{...state.userInfo,auto_pay:value}}};
      case "Update_Shipping":
        return  {...state, userInfo: action.payload }
      case "Update_Billing":
        return  {...state, userInfo: action.payload }
      
      default:
        return state;
    }
  };
  