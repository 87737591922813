import React, { useState, useEffect, useRef } from "react";
import { Button } from "./Button";
import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { connect } from "react-redux";
import Badge from "@mui/material/Badge";
import Cartlist from "./Cartlist";
import authReducer from "../reducers/authReducer";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import logo from "./assets/new_logo.svg";
import {
  addcoupon,
  closeordergroupapi,
  getCustomerCart,
  getStateTax,
  removecoupon,
  removeItem,
  signinWithApi,
} from "../Service/Service";
import {
  addanything,
  addtocart,
  emptymycart,
  Emptyorderhash,
  signIn,
  signOut,
  resetState,
  removefromcart,
  setLoading,
  updateCart,
  updateState,
} from "../actions";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import updateReducer from "../reducers/updateReducer";
function Navbar(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [isOverflowHidden, setIsOverflowHidden] = useState(false);
  const handleClick = () => {
    setClick(!click);
    setIsVisible(!isVisible);
    setIsOverflowHidden(!isOverflowHidden);
  };
  const handleSideNave = () => {
    // setClick(false);
    // setIsVisible(false);
    handleClick();
  };

  useEffect(() => {
    if (isOverflowHidden) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isOverflowHidden]);
  const closeMobileMenu = () => setClick(false);
  const currentPath = window.location.pathname;
  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);

    return window.removeEventListener("resize", showButton);
  }, []);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const [cart, setcart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [servererror, setservererror] = useState("");
  const [fieldError, setfieldError] = useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick3 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose3 = () => {
    setAnchorEl2(null);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const id = open ? "simple-popover" : undefined;
  const id2 = open ? "simple-popover" : undefined;

  const [formobj, setformobj] = useState({
    email: "",
    password: "",
  });

  const handleForm = (e) => {
    const { name, value } = e.target;
    setformobj((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const navigate = useNavigate();
  const submit = () => {
    setservererror("");
    if (formobj.email.length === 0) {
      setfieldError("Enter your email");
      return false;
    } else if (formobj.password.length === 0) {
      setfieldError("Enter your password");
      return false;
    } else {
      setIsLoading(true);
      signinWithApi(formobj.email, formobj.password).then((result) => {
        if (result.details) {
          setfieldError();
          setservererror(result.details);
          setIsLoading(false);
        } else {
          getCustomerCart(result.id).then((cart) => {
            props.addtocart(cart);
            if (cart.hasOwnProperty("order_hash")) {
              props.addanything({
                hash: { id: cart.id, order_hash: cart.order_hash },
              });
            }
          });
          handleClose();
          setservererror("");
          props.signIn(result);

          navigate("/dashboard");
          setIsLoading(false);
        }
      });
    }
  };

  const logout = () => {
    props.Emptyorderhash();
    props.emptymycart();
    props.signOut();
    props.resetState();
    navigate("/");
  };
  const [total, settotal] = useState();

  useEffect(() => {
    settotal(
      props.cart.cart.hasOwnProperty("order_groups")
        ? props.cart.cart.order_groups.plan &&
            props.cart.cart.order_groups.reduce(
              (total, obj) => (total += obj.plan.amount_recurring),
              0
            )
        : 0
    );
  });

  const checkout = () => {
    if (props.cart.cart.hasOwnProperty("order_groups")) {
      if (props.cart.cart.activeGroupId) {
        if (props.cart.cart.order_groups.find((obj) => obj.id).device) {
          Swal.fire({
            title: "Info",
            text: `Please choose Plan for your active Device ${
              props.cart.cart.order_groups.find((obj) => obj.id).device.name
            }`,

            icon: "info",
            confirmButtonText: "Add plan",
            allowOutsideClick: true,
            allowEscapeKey: false,

            preConfirm: () => {
              navigate("/plans");
            },
          });
        } else {
          Swal.fire({
            title: "Info",
            text: `Please choose device for your active plan ${
              props.cart.cart.order_groups.find((obj) => obj.id).plan.name
            }`,

            icon: "info",
            confirmButtonText: "Add device",
            allowOutsideClick: true,
            allowEscapeKey: false,

            preConfirm: () => {
              navigate("/devicespage");
            },
          });
        }
      } else if (props.cart.cart.order_groups.length > 0) {
        processCoupon();
        getCartOrder();
        navigate("/checkout");
      } else {
        alert("Please add some item to cart");
      }
    } else {
      alert("Please add some item to cart");
    }
  };

  const getCartOrder = () => {
    if (props.cart.hash.hasOwnProperty("order_hash")) {
      closeordergroupapi(props.cart.hash.order_hash, null).then((getOrder) => {
        if (!getOrder.order_groups.length > 0) {
          alert(
            "Oops! Something is incorrect, please check your mail again or contact us if the problem still persists."
          );
        } else {
          // props.addanything({hash:})\\
          console.log("order");
          console.log(getOrder);
          props.addtocart(getOrder);

          if (getOrder.couponDetails) {
            props.addanything({ couponAmount: getOrder.couponDetails });

            getOrder.couponDetails.forEach((coupon) => {
              var couponCodes = props.cart.couponCode
                ? props.cart.couponCode
                : [];
              if (coupon.error && !couponCodes.includes(coupon.code)) {
                props.addanything({ couponCodes: coupon.code });
              }
            });
          }

          if (props.cart.status == 1) {
            alert("you have already placed your order");
          }
        }
      });
    } else {
      alert(
        "Oops! Something is incorrect, please <b>Verify Your Business</b> first."
      );
    }
  };

  const processCoupon = () => {
    var couponCodes = props.cart.couponCodes ? props.cart.couponCodes : [];

    if (couponCodes.length > 0) {
      couponCodes.map((couponCode) => {
        if (couponCode) {
          removecoupon(couponCode, props.auth.userInfo.id).then();

          addcoupon(
            couponCode,
            props.auth.userInfo.id,
            props.cart.order_id
          ).then((result) => {
            if (!result.error) props.addanything({ couponAmount: result });
          });
        }
      });
    }
  };

  const removeitemwrapper = (id) => {
    removeItem(id, props.cart.hash.order_hash).then((result) => {
      props.addtocart(result);

      
    });
  };

  
  useEffect(()=>{
  
    setTimeout(() => {
      
      if(props.cart.cart.order_groups.some((obj)=>obj.device))
      {
        getStateTax(props.auth.userInfo.billing_state_id).then((result)=>{

          console.log(result.tax_rate/100*props.cart.cart.subtotalPrice);
          props.updateCart(result.tax_rate/100*props.cart.cart.subtotalPrice)

        })

      }
    }, 1000);

  },[props.updateReducer])
  return (
    <>
      <IconContext.Provider value={{ color: "#1c2237" }}>
        {isVisible && (
          <div className="mobile-nav-overly" onClick={handleSideNave}></div>
        )}

        {props.cart.loading == true && (
          <progress class="pure-material-progress-linear" />
        )}

        <nav className="navbarnav">
          <div className="navbar-container containernav">
            {/* <button onClick={()=>props.setLoading(!props.cart.loading)}>Loading</button> */}
            <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
              <img
                src={logo}
                alt="logo"
                className="img-fluid"
                style={{ width: "16rem" }}
              />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item side-menu-header d-none">
                <div
                  className="pb-1"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <Link
                    to="/"
                    onClick={closeMobileMenu}
                    style={{ width: "150px" }}
                  >
                    <img
                      src={logo}
                      alt="Surecell-logo"
                      style={{ width: "100%" }}
                    />
                  </Link>
                  <div onClick={handleClick}>
                    <CloseIcon
                      style={{ fontSize: "1.8rem", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <Link
                  to="/plans"
                  onClick={closeMobileMenu}
                  // className="nav-linknav"
                  className={
                    currentPath === "/plans"
                      ? "active nav-linknav"
                      : "nav-linknav"
                  }
                >
                  Plans
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/devicespage"
                  onClick={closeMobileMenu}
                  // className="nav-linknav"
                  className={
                    currentPath === "/devicespage"
                      ? "active nav-linknav"
                      : "nav-linknav"
                  }
                >
                  Devices
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/whyus"
                  onClick={closeMobileMenu}
                  // className="nav-linknav"
                  className={
                    currentPath === "/whyus"
                      ? "active nav-linknav"
                      : "nav-linknav"
                  }
                >
                  Why Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/support"
                  onClick={closeMobileMenu}
                  // className="nav-linknav"
                  className={
                    currentPath === "/support"
                      ? "active nav-linknav"
                      : "nav-linknav"
                  }
                >
                  Support
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/myaccount"
                  onClick={closeMobileMenu}
                  // className="nav-linknav"
                  className={
                    currentPath === "/myaccount"
                      ? "active nav-linknav"
                      : "nav-linknav"
                  }
                >
                  Contact
                </Link>
              </li>

              <li className="btn1 d-none mbl-btn">
                {props.auth.isSignedIn == true ? (
                  <>
                    <Link
                      to="/dashboard"
                      onClick={closeMobileMenu}
                      // className="nav-linknav"
                      className={
                        currentPath === "/dashboard"
                          ? "active nav-linknav"
                          : "nav-linknav"
                      }
                    >
                      My Account
                    </Link>
                    <Button onClick={logout}>Logout</Button>{" "}
                    {/* <button
                      className="myaccount-btn"
                      onClick={() => navigate("/dashboard")}
                      style={{ width: 116, border: "none" }}
                    >
                      My Account
                    </button> */}
                  </>
                ) : (
                  <Link
                    aria-describedby={id}
                    onClick={handleClick2}
                    className={"nav-linknav"}
                  >
                    <PersonIcon />
                    Login
                  </Link>
                )}

                {/* 
                {
                  props.auth.isSignedIn==true&&<h2>{props.auth.userInfo.fname+" "+props.auth.userInfo.lname}</h2>
                } */}

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{ marginTop: 3 }}
                >
                  <Box
                    sx={{
                      width: 300,
                      height: 318,
                      backgroundColor: "#262626",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                    className="popup"
                  >
                    <h5 style={{ color: "#fff" }} className="">
                      Sign into your account
                    </h5>

                    <input
                      type="text"
                      placeholder="Email or Customer ID"
                      className="signinput"
                      value={formobj.email}
                      onChange={handleForm}
                      name="email"
                    />

                    <input
                      type="password"
                      placeholder="Password"
                      className="signinput"
                      value={formobj.password}
                      onChange={handleForm}
                      name="password"
                    />
                    <p style={{ color: "#fff" }}>{fieldError || servererror}</p>
                    <button onClick={submit}>
                      {isLoading ? <Spinner size="sm" /> : <span>SIGN IN</span>}
                    </button>
                  </Box>
                </Popover>
              </li>
              <li className="nav-item cart-parent">
                <div
                  className="d-none side-nav-cart"
                  aria-describedby={id2}
                  onClick={handleClick3}
                >
                  <ShoppingCartIcon />
                  <span>
                    {`Your Cart(${
                      props.cart.cart.hasOwnProperty("order_groups")
                        ? props.cart.cart.order_groups.length
                        : 0
                    })`}
                  </span>
                </div>
              </li>
            </ul>
            <ul className="nav-menu nav-menu-desktop">
              <li
                // onClick={() => setcart(!cart)}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTtop: "13px",
                  listStyle: "none",
                  marginTop: "25px",
                }}
              >
                <button
                  aria-describedby={id2}
                  onClick={handleClick3}
                  className="nav-cart "
                  style={{ border: "none" }}
                ></button>
                {/* <Cartlist /> */}
                <Badge
                  badgeContent={
                    props.cart.cart.hasOwnProperty("order_groups")
                      ? props.cart.cart.order_groups.length
                      : 0
                  }
                  color="primary"
                ></Badge>

                <Popover
                  id={id2}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose3}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: 50, left: 1000 }}
                  sx={{ marginTop: 3, overflow: "hidden" }}
                >
                  <Box className="itemscart">
                    <List className="cartlist">
                      {/* <button className="cross">
                        <CloseIcon />
                      </button> */}
                      <div style={{ marginBottom: 30 }}>
                        <h2
                          style={{ marginBottom: 30, marginTop: 10 }}
                          className="fs-4 fw-bold mx-0 text-center"
                        >
                          Your Cart
                        </h2>
                        <strong
                          style={{ color: "#333", fontSize: "16px" }}
                          className="px-4"
                        >
                          Selected Items
                        </strong>
                      </div>
                      {props.cart.cart.hasOwnProperty("order_groups") &&
                      props.cart.cart.order_groups.length == 0 ? (
                        <span
                          style={{
                            display: "flex",
                            margin: "auto",
                            width: 100,
                            fontStyle: "italic",
                          }}
                        >
                          {/* No item yet */}
                        </span>
                      ) : (
                        props.cart.cart.hasOwnProperty("order_groups") &&
                        props.cart.cart.order_groups.map((obj, index) => (
                          <div key={obj.id}>
                            <div className="cart-items">
                              {obj.plan && (
                                <div className="justify-content-between align-items-center">
                                  <div className="items-item-1">
                                    {/* {" "}
                                    <h4> */}
                                    {obj.plan.name}
                                    {/* </h4> */}
                                  </div>
                                  <div>
                                    <p>
                                      $
                                      {obj.plan_prorated_amt
                                        ? obj.plan_prorated_amt
                                        : obj.plan.amount_recurring.toFixed(2)}
                                    </p>
                                  </div>
                                  {/* <div
                                  role="button" 
                                  className="close-btn-cart"
                                    onClick={()=>removeitemwrapper(obj.id)}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </div> */}
                                </div>
                              )}
                              {obj.sim && (
                                <div className="justify-content-between align-items-center">
                                  <div className="items-item-1">
                                    {/* {" "}
                                    <h4> */}
                                    {obj.sim.name}
                                    {/* </h4> */}
                                  </div>
                                  <div>
                                    <p>${obj.sim.amount_w_plan.toFixed(2)}</p>
                                  </div>
                                  {/* <div>
                                  
                                  </div> */}
                                </div>
                              )}
                              {obj.device && (
                                <div className="justify-content-between align-items-center">
                                  <div className="items-item-1">
                                    {/* {" "}
                                    <h4> */}
                                    {obj.device.name}
                                    {/* </h4> */}
                                  </div>
                                  <div>
                                    <p>
                                      $
                                      {props.cart.cart.activeGroupId == obj.id
                                        ? obj.device.amount_w_plan.toFixed(2)
                                        : obj.plan?obj.device.amount_w_plan.toFixed(2):obj.device.amount.toFixed(2)}
                                    </p>
                                  </div>
                                  {/* <div>
                                  
                                  </div> */}
                                </div>
                              )}
                              {obj.plan != null &&
                                obj.plan.amount_onetime != 0 && (
                                  <div className="justify-content-between align-items-center">
                                    <div className="items-item-1">
                                      {/* {" "}
        <h4> */}
                                      Activation fee
                                      {/* </h4> */}
                                    </div>
                                    <div>
                                      <p>
                                        ${obj.plan?.amount_onetime.toFixed(2)}
                                      </p>
                                    </div>
                                    {/* <div>
      
      </div> */}
                                  </div>
                                )}

                              <div
                                className="remove-item-action"
                                onClick={() => removeitemwrapper(obj.id)}
                              >
                                Remove
                              </div>
                            </div>
                            {/* <Divider /> */}
                          </div>
                        ))
                      )}
                    </List>

                    <List>
                      {/* <div className="myflex">
                        <div>Monthly Charge:</div>
                        <div>+ ${props.cart.cart.monthlyCharge?props.cart.cart.monthlyCharge:"0.00"}</div>
                      </div> */}
                      <div className="myflex">
                        <div>Subtotal:</div>
                        <div>
                          $
                          {props.cart.cart.subtotalPrice
                            ? props.cart.cart.subtotalPrice.toFixed(2)
                            : "0.00"}
                        </div>
                      </div>
                      <div className="myflex">
                        <div>Shipping:</div>
                        <div>
                          + $
                          {props.cart.cart.shippingFee
                            ? props.cart.cart.shippingFee
                            : "0.00"}
                        </div>
                      </div>
                      <div className="myflex">
                        <div>
                          Coupons:
                          {props.cart.coupon.map((obj) => (
                            <small>{obj.code}</small>
                          ))}
                        </div>
                        <div>
                          - $
                          {props.cart.coupon.reduce(
                            (total, obj) => (total += obj.amount),
                            0
                          )}
                          .00
                        </div>
                      </div>
                      <div className="myflex">
                        <div>State Tax:</div>
                        <div>+ ${props.cart.cart.taxes?props.cart.cart.taxes.toFixed(2):"0.00"}</div>
                      </div>
                      <div className="myflex">
                        <div>Regulatory Fee:</div>
                        <div>
                          + $
                          {props.cart.cart.regulatory
                            ? props.cart.cart.regulatory.toFixed(2)
                            : "0.00"}
                        </div>
                      </div>
                    </List>
                    <Divider />
                    <div className="myflex">
                      <div>
                        <strong>Total:</strong>
                      </div>
                      <div>
                        <strong>
                          ${console.log(props.cart)}
                          {props.cart.cart.totalPrice
                            ? props.cart.cart.totalPrice.toFixed(2)
                            : "0.00"}
                        </strong>{" "}
                      </div>
                    </div>

                    <div> </div>

                    <div>
                      {" "}
                      <button
                        className="checkout"
                        variant="contained"
                        color="success"
                        onClick={checkout}
                      >
                        Checkout
                      </button>
                    </div>
                  </Box>
                </Popover>
              </li>
              <li></li>
              <li className="nav-btn btn1">
                {props.auth.isSignedIn == true ? (
                  <>
                    <Button onClick={logout} buttonstyle="btn--outline">
                      Logout
                    </Button>{" "}
                    <button
                      className="myaccount-btn"
                      onClick={() => navigate("/dashboard")}
                      style={{ width: 116, marginLeft: 10, border: "none" }}
                    >
                      My Account
                    </button>
                  </>
                ) : button ? (
                  <Button
                    aria-describedby={id}
                    onClick={handleClick2}
                    buttonstyle="btn--outline"
                  >
                    Login
                  </Button>
                ) : (
                  <Link
                    to="/login"
                    onClick={closeMobileMenu}
                    className="btn-link"
                  >
                    <Button buttonstyle="btn--outline" buttonsize="btn--mobile">
                      Login
                    </Button>
                  </Link>
                )}

                {/* 
                {
                  props.auth.isSignedIn==true&&<h2>{props.auth.userInfo.fname+" "+props.auth.userInfo.lname}</h2>
                } */}

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{ marginTop: 3 }}
                >
                  <Box
                    sx={{
                      width: 300,
                      height: 318,
                      backgroundColor: "#262626",

                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                    }}
                    className="popup"
                  >
                    <h5 style={{ color: "#fff" }} className="">
                      Sign into your account
                    </h5>

                    <input
                      type="text"
                      placeholder="Email or Customer ID"
                      className="signinput"
                      value={formobj.email}
                      onChange={handleForm}
                      name="email"
                    />

                    <input
                      type="password"
                      placeholder="Password"
                      className="signinput"
                      value={formobj.password}
                      onChange={handleForm}
                      name="password"
                    />
                    <p style={{ color: "#fff" }}>{fieldError || servererror}</p>
                    <button onClick={submit}>
                      {isLoading ? <Spinner size="sm" /> : <span>SIGN IN</span>}
                    </button>
                  </Box>
                </Popover>
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
      {/* <Home /> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    auth: state.authReducer,
    updateReducer:state.updateReducer,
  };
};


export default connect(mapStateToProps, {
  signIn,
  signOut,
  removefromcart,
  addanything,
  addtocart,
  emptymycart,
  Emptyorderhash,
  resetState,
  setLoading,
  updateCart
})(Navbar);
