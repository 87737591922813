import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
// import footer from '../assets/footer.png';
import logo2 from '../assets/logo2.png';
import logo from "../assets/home away.svg"

function Footer() {
  return (
    <>

    <div className="footer-container">
        <div className="social-media-wrap" >
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img className="footerlogo" src={logo} />
           </Link>
           <div className="detail">
            <h3 className="number">1-877-331-4255</h3>
            <h3  className="email">Support@Homeawaysecure.Com</h3>
            </div>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-link-wrapper">
            <div className="footer-link-items">
              <h2 className="hq1">Plans</h2>
              {/* <h3 className="hq2">Talk, Text, Data</h3>
              <h3 className="hq2">TTD + Alert</h3> */}
              <Link to="/plans" 
              className="hq2">
              Talk, Text, Data
           </Link><Link to="/plans" 
              className="hq2">
              TTD + Alert
           </Link>
            </div>
            <div className="footer-link-items">
              <h2 className="hq1">Products</h2>
              <Link to="/support" 
              className="hq2">
              Simple Smartphone
           </Link>
           <Link to="/support" 
              className="hq2">
              DOT Wearable
           </Link>
            </div>
            <div className="footer-link-items">
              <h2 className="hq1">Support</h2>
              <Link to="/myaccount" 
              className="hq2">
              Activate Device
           </Link>
              <Link to="/myaccount" 
              className="hq2">
              FAQ
           </Link>
            </div>
          </div>
        </div>
    </div>


    <div className="ft1">
      <p className="ftl">Powered By</p>
      <img src={logo2 } />
    </div>
 
    </>
  );
}

export default Footer;
