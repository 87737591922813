import React from "react";
import TextField from "@mui/material/TextField";
import states from "../../myData/states.json";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useImperativeHandle } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Shippinginfo = React.forwardRef((props, ref) => {
  const [rederror, setrederror] = useState(9);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  useImperativeHandle(ref, () => ({
    childFunction2() {
      return validate();
    },
  }));

  const handleEvent = (e) => {
    setrederror(9);
    console.log("triggered");
    props.setshippinginfo({
      ...props.shippinginfo,
      [e.target.name]: e.target.value,
    });
  };

  const [error, seterror] = useState("");

  const validate = () => {
    if (props.shippinginfo.firstname === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter firstname");
      setrederror(0);
      return false;
    } else if (props.shippinginfo.lastname === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter lastname");
      setrederror(1);
      return false;
    } else if (props.shippinginfo.address1 === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter address1");
      setrederror(2);
      return false;
    } else if (props.shippinginfo.city === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter city");
      setrederror(3);
      return false;
    } else if (props.shippinginfo.state === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Select state");
      setrederror(4);
      return false;
    } else if (props.shippinginfo.zipcode === "") {
      setState({ open: true, vertical: "bottom", horizontal: "right" });
      seterror("Enter zipcode");
      setrederror(5);
      return false;
    }

    return true;
  };
  return (
    <>
      <form className="myform">
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            error={rederror === 0}
            value={props.shippinginfo.firstname}
            className="half"
            name="firstname"
            onChange={handleEvent}
            label="First Name"
            variant="outlined"
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Last Name"
            error={rederror === 1}
            value={props.shippinginfo.lastname}
            className="half"
            name="lastname"
            onChange={handleEvent}
            variant="outlined"
          />
        </div>

        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            error={rederror === 2}
            value={props.shippinginfo.address1}
            className="half"
            name="address1"
            onChange={handleEvent}
            label="Address1"
            variant="outlined"
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Address2"
            className="half"
            value={props.shippinginfo.address2}
            name="address2"
            onChange={handleEvent}
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}

            id="outlined-basic"
            error={rederror === 3}
            value={props.shippinginfo.city}
            name="city"
            className="half"
            onChange={handleEvent}
            label="City"
            variant="outlined"
          />

          <TextField
            InputLabelProps={{
              shrink: true,
            }}

            name="state"
            error={rederror === 4}
            value={props.shippinginfo.state}
            className="half"
            onChange={handleEvent}
            id="outlined-required"
            select
            label="State"
            required
          >
            {states.map((option, i) => (
              <MenuItem key={i} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="outlined-basic"
            error={rederror === 5}
            name="zipcode"
            className="half"
            value={props.shippinginfo.zipcode}
            onChange={handleEvent}
            label="Zip Code"
            variant="outlined"
          />
          <div className="half"></div>
        </div>
      </form>

      <Snackbar
        sx={{ top: 400 }}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="I love snacks"
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
});

export default Shippinginfo;
