import { json } from "react-router-dom";

const BaseUrl="https://britex.pw/api";

export function getPlans() {
  return new Promise((resolve, reject) => {
    fetch(BaseUrl+"/plans", {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Authorization: "alar324r23423",
        AccessToken: "key",
        "Cache-Control" :"no-cache",
        Pragma: "no-cache",        
        Expires: "0"
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        resolve(
          responseJson.filter((obj) => {
            if (obj.show == 1 && obj.type == 1) {
              return true;
            }
          })
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
}

export function getDevices(planId,carrier) {

  return new Promise((resolve, reject) => {
    fetch(BaseUrl+`/devices?plan_id=${planId}&carrier_id=${carrier}`, {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Authorization: "alar324r23423",
        AccessToken: "key",
        "Cache-Control" :"no-cache",
        Pragma: "no-cache",        
        Expires: "0"
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        resolve(
          responseJson.devices
          // .filter((obj) => {
          //   if ((obj.show == 1 || obj.show==2) && obj.type == 2) {
          //     return true;
          //   }
          // })
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
}

export function getalldevices(){

  return new Promise((resolve, reject) => {
    fetch(BaseUrl+`/devices`, {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Authorization: "alar324r23423",
        AccessToken: "key",
        "Cache-Control" :"no-cache",
        Pragma: "no-cache",        
        Expires: "0"
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
        resolve(
          responseJson.devices
          // .filter((obj) => {
          //   if ((obj.show == 1 || obj.show==2) && obj.type == 2) {
          //     return true;
          //   }
          // })
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  });

}

export function getHash(planid, paidmonthlyinvoice) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    let mybody = {
      plan_id: planid,
      paid_monthly_invoice: paidmonthlyinvoice,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: mybody,
      redirect: "follow",
    };

    fetch(BaseUrl+"/order", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function getSims(planid, orderhash) {
  return new Promise((resolve, reject) => {
    fetch(
      `${BaseUrl}/sims?plan_id=${planid}&order_hash=${orderhash}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: "alar324r23423",
          AccessToken: "key",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
}

export function getAddons(planid, orderhash) {
  return new Promise((resolve, reject) => {
    fetch(
      `${BaseUrl}/addons?plan_id=${planid}&order_hash=${orderhash}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: "alar324r23423",
          AccessToken: "key",
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
}

export function postwithplanid(obj) {
  if (!localStorage.getItem("hash")) {
    alert("Hello");
    getHash(obj.id).then((result) => {});
  }

  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var mybody = {
      plan_id: obj.id,
      order_hash: localStorage.getItem("hash"),
      paid_monthly_invoice: localStorage.getItem("session"),
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(mybody),
    };

    fetch(BaseUrl+"/order", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function postorder(obj) {
  
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
   
    if(localStorage.getItem("user_token"))
    {
    
      obj.customer_hash=  JSON.parse(localStorage.getItem("user_token")).hash;
      
    }
    const formData = new FormData();
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if(key==="order_hash" || key==="plan_id" || key==="sim_id" || key==="device_id" || key=="customer_hash" )
        formData.append(key, obj[key]);
      }
    }

 
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formData,
    };

    fetch(BaseUrl+"/order", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function ordergroup(action, orderhash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");

    var formdata = new FormData();
    formdata.append("action", action);
    formdata.append("order_hash", orderhash);

    var g = { action: 1, order_hash: orderhash };

    // const res = Object.entries(g).map(([name, obj]) => ( name:obj ))

    // console.log(res);
    console.log(JSON.stringify(g));
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      // body: formdata,
    };

    fetch(BaseUrl+"/order-group?action="+action+"&order_hash="+orderhash, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
        console.log(result)
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function closeordergroupapi(orderhash, paid_monthly_invoice) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", "no-cache");
    myHeaders.append("Pragma", "no-cache");
    myHeaders.append("Expires", "0");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BaseUrl+"/order?order_hash=" +
        orderhash +
        "&paid_monthly_invoice=" +
        paid_monthly_invoice,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {


        
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function checkemail(email) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var formdata = new FormData();
    formdata.append("email", email);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BaseUrl+"/check-email?newEmail=" + encodeURIComponent(email), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function createcustomer(customerinfo, shippinginfo, hash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var mybody = {
      fname: customerinfo.firstname,
      lname: customerinfo.lastname,
      email: customerinfo.email,
      phone: customerinfo.phone,
      alternate_phone: customerinfo.altphone,
      password: customerinfo.password,
      shipping_fname: shippinginfo.firstname,
      shipping_lname: shippinginfo.lastname,
      shipping_address1: shippinginfo.address1,
      shipping_address2: shippinginfo.address2,
      shipping_city: shippinginfo.city,
      shipping_state_id: shippinginfo.state,
      shipping_zip: shippinginfo.zipcode,
      pin: customerinfo.pin,
      order_hash: hash,
      customer_id: "",
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(mybody),
    };

    fetch(BaseUrl+"/create-customer", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function RemoveCustomerCardService(customerid) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var user = JSON.parse(localStorage.getItem("user_token"));
    var formdata = new FormData();
    formdata.append("customer_credit_card_id", customerid);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${BaseUrl}/remove-card?customer_credit_card_id=${customerid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}
export function MakePrimaryCardService(creditCardId) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var user = JSON.parse(localStorage.getItem("user_token"));
    var formdata = new FormData();
    formdata.append("customer_credit_card_id", creditCardId);
    formdata.append("id", user.id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      // body: formdata,
      redirect: "follow",
    };
    fetch(
      `${BaseUrl}/primary-card?customer_credit_card_id=${creditCardId}&id=${user.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function orderupdateshipping(shippinginfo, hash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var mybody = {
      shipping_fname: shippinginfo.firstname,
      shipping_lname: shippinginfo.lastname,
      shipping_address1: shippinginfo.address1,
      shipping_address2: shippinginfo.address2,
      shipping_city: shippinginfo.city,
      shipping_state_id: shippinginfo.state,
      shipping_zip: shippinginfo.zipcode,
      hash: hash,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(mybody),
    };

    fetch(BaseUrl+"/order/update-shipping", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function customer(customerinfo, shippinginfo) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var mybody = {
      fname: customerinfo.firstname,
      lname: customerinfo.lastname,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(mybody),
    };

    fetch(BaseUrl+"/customer", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function savebilling(billinginfo, id, hash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var mybody = {
      billing_state_id: billinginfo.state,
      billing_fname: billinginfo.firstname,
      billing_lname: billinginfo.lastname,
      billing_address1: billinginfo.address1,
      billing_address2: billinginfo.address2,
      billing_city: billinginfo.city,
      billing_zip: billinginfo.zipcode,
      id: id,
      order_hash: hash,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(mybody),
    };

    fetch(BaseUrl+"/create-customer", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function addcoupon(code, customerid, orderid , hash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var mybody = {
      code: code,
      order_id: orderid,
      hash: hash,

      code: code,
      order_id: orderid,
      hash: hash,

      customer_id: customerid,
      only_detail: false,
      for_plans: false,
      data_for_plans: false,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(mybody),
    };

    fetch(BaseUrl+"/coupon/add-coupon", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function chargenewcard(data) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("Content-Type", "application/json");
    console.log("=======>charge new card");
    console.log(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    fetch(BaseUrl+"/charge-new-card", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function removecoupon(code, orderid) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var mybody = {
      coupon_code: code,
      order_id: orderid,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(mybody),
    };

    fetch(BaseUrl+"/coupon/remove-coupon", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function signinWithApi(email, password) {
  return new Promise((resolve, reject) => {
    let body = {
      identifier: email,
      password: password,
    };
    fetch(BaseUrl+"/sign-on", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "alar324r23423",
        AccessToken: "key",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.id) {
          localStorage.setItem("user_token", JSON.stringify(responseJson));

          getcustomerdetail(responseJson.hash).then((result) => {
            resolve(result);
          });
        } else {
          resolve(responseJson);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
}

export function getcustomerdetail(hash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("Cache-Control", "no-cache");
    myHeaders.append("Pragma", "no-cache");
    myHeaders.append("Expires", "0");
    var formdata = new FormData();
    formdata.append("hash", hash);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(BaseUrl+"/customer?hash="+hash, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function customerinvoices() {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", "no-cache");
    myHeaders.append("Pragma", "no-cache");
    myHeaders.append("Expires", "0");
    
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var user = JSON.parse(localStorage.getItem("user_token"));

    fetch(
      BaseUrl+"/customer-current-invoice?hash=" +
        user.hash +
        "&id=" +
        user.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}
export function MakePayment(id, amount) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    var user = JSON.parse(localStorage.getItem("user_token"));
    const formdata = new FormData();
    formdata.append("credit_card_id", id);
    formdata.append("customer_id", user.id);
    formdata.append("amount", amount);
    formdata.append("without_order", true);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/charge-card", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function customerorders() {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", "no-cache");
    myHeaders.append("Pragma", "no-cache");
    myHeaders.append("Expires", "0");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var user = JSON.parse(localStorage.getItem("user_token"));

    fetch(
      BaseUrl+"/customer-orders?hash=" +
        user.hash +
        "&id=" +
        user.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function GetCustomerCardsServices() {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    var user = JSON.parse(localStorage.getItem("user_token"));
    var formdata = new FormData();
    formdata.append("hash", user.hash);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/customer-cards", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function addbillingcard(
  cardHolderName,
  cardNumber,
  mmyy,
  cvv,
  userInfo,
  // newDate[0],
  // newDate[1],

  city,
  state,
  address,
  zip,
  newDate
) {
  return new Promise((resolve, reject) => {
    console.log(mmyy);
    const newDateUp = mmyy.split("/");

    // console.log(newDate[1]);
    var myHeaders = new Headers();
    // var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    const formdata = new FormData();
    formdata.append("payment_card_holder", cardHolderName);
    formdata.append("expires_mmyy", mmyy);
    formdata.append("payment_cvc", cvv);
    formdata.append("new_card", 1);
    formdata.append("api_key", "alar324r23423");
    formdata.append("month", newDateUp[0]);
    formdata.append("year", newDateUp[1]);
    formdata.append("payment_card_no", cardNumber);
    formdata.append("customer_id", userInfo.id);
    formdata.append("billing_fname", userInfo.billing_fname);
    formdata.append("billing_lname", userInfo.billing_lname);
    formdata.append("billing_address1", address);
    formdata.append("billing_city", city);
    formdata.append("billing_zip", zip);
    formdata.append("billing_state_id", state);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/add-card", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function updatecustomershipping(updateinfo,userInfo) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    // var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    var formdata = new FormData();
    formdata.append("shipping_fname", updateinfo.firstname);
    formdata.append("shipping_lname", updateinfo.lastname);
    formdata.append("shipping_address1", updateinfo.address1);
    formdata.append("shipping_address2", updateinfo.address2);
    formdata.append("shipping_city", updateinfo.city);
    formdata.append("shipping_state_id", updateinfo.state);
    formdata.append("shipping_zip", updateinfo.zipcode);
    formdata.append("id", userInfo.id);
    formdata.append("hash", userInfo.hash);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/update-customer", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function UpdateCustomerInformation(
  key1,
  value1,
  key2,
  value2,
  key3,
  value3
) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var formdata = new FormData();
    formdata.append(key1, value1);
    formdata.append("id", user.id);
    formdata.append("hash", user.hash);
    if (key2) {
      formdata.append(key2, value2);
    }
    if (key3) {
      formdata.append(key3, value3);
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/update-customer", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function checkmonthlyinvoices(id, hash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BaseUrl+"/check-monthly-invoice?customer_hash=" +
        hash +
        "&id=" +
        id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function updatesubscription(updateinfo) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: updateinfo,
      redirect: "follow",
    };

    fetch(BaseUrl+"/update-subscription", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function generateOneTimeInvoice(updateinfo) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
      console.log("invoice api info ",updateinfo);
      console.log("Stringify " ,JSON.stringify(updateinfo));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(updateinfo),
      redirect: "follow",
    };

    fetch(BaseUrl+"/generate-one-time-invoice", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function paymentfailed(cart) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var data = [{ cart: cart }];

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(BaseUrl+"/payment-failed", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function createsubscription(obj) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    console.log("Json data");
    console.log(obj);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(obj),
      redirect: "follow",
    };

    fetch(BaseUrl+"/create-subscription", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function createsubscriptionaddon(obj) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: obj,
      redirect: "follow",
    };

    fetch(BaseUrl+"/create-subscription-addon", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function createDeviceRecord(obj) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(obj),
      redirect: "follow",
    };

    fetch(BaseUrl+"/create-device-record", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function createSimRecord(obj) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: obj,
      redirect: "follow",
    };

    fetch(BaseUrl+"/create-sim-record", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function createCustomerAPI(customerid, orderhash) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    const formdata = new FormData();
    formdata.append("customer_id", customerid);
    formdata.append("order_hash", orderhash);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/create-customer", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function getTaxrate(stateid) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var user = JSON.parse(localStorage.getItem("user_token"));

    fetch(BaseUrl+"/customer?tax_id="+"AL", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function customerData() {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var user = JSON.parse(localStorage.getItem("user_token"));

    fetch(
      BaseUrl+"/customer?hash=" + user.hash + "&id=" + user.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}
export function customerSubscriptions(auth) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", "no-cache");
    myHeaders.append("Pragma", "no-cache");
    myHeaders.append("Expires", "0");
    var user = JSON.parse(localStorage.getItem("user_token"));
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    var user = JSON.parse(localStorage.getItem("user_token"));

    fetch(
      `${BaseUrl}/customer-subscriptions?hash=${auth.userInfo.hash}&id=${auth.userInfo.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function requestConnection(arr) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
  
    const formdata = new FormData();
    formdata.append("sim_card_num", arr);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/check2", requestOptions)
      .then((response) => response)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error); 
      });
  });
}

export function getcustomercards(id) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");

    var formdata = new FormData();
    formdata.append("customer_id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(BaseUrl+"/customer-cards", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function removeItem(id, hash, paid_monthly_invoice = null) {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    var user = JSON.parse(localStorage.getItem("user_token"));
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Content-Type", "application/json");
    var formdata = new FormData();
    formdata.append("customer_id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(BaseUrl+"/order/remove?order_hash="+hash+"&order_group_id="+id+"&paid_monthly_invoice="+paid_monthly_invoice, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        // reject(error);
        closeordergroupapi(hash, null).then((result) => {
          resolve(result);
        });
        console.log("error");
      });
  });
}



export function getCustomerCart(customerid, paid_monthly_invoice) {
  return new Promise((resolve, reject) => {

    console.log("customer card =======>")
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", 'no-cache');
    myHeaders.append('Pragma', 'no-cache');
    myHeaders.append('Expires', '0');
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      BaseUrl+"/order?customer_id=" +
        customerid +
        "&paid_monthly_invoice=" +
        paid_monthly_invoice,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}




export function supportemail(obj) {
  return new Promise((resolve, reject) => {
    
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", 'no-cache');
    myHeaders.append('Pragma', 'no-cache');
    myHeaders.append('Expires', '0');
   
    
    var formdata = new FormData();
    formdata.append("name", obj.name);
    formdata.append("email", obj.email);
    formdata.append("subject", "asdsad");
    formdata.append("message", obj.message);
    
        

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body:formdata
    };

    fetch(
      BaseUrl+"/support/email",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}




export function checkAssociatePlans(obj) {
  return new Promise((resolve, reject) => {
    
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", 'no-cache');
    myHeaders.append('Pragma', 'no-cache');
    myHeaders.append('Expires', '0');
   
    
    var formdata = new FormData();
    formdata.append("name", obj.name);
    formdata.append("email", obj.email);
    formdata.append("subject", "asdsad");
    formdata.append("message", obj.message);
    
        

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
   
    };

    fetch(
      BaseUrl+"/plans?device_id="+obj,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}

export function getStateTax(obj) {
  return new Promise((resolve, reject) => {
    
    var myHeaders = new Headers();
    myHeaders.append("AccessToken", "key");
    myHeaders.append("Authorization", "alar324r23423");
    myHeaders.append("Cache-Control", 'no-cache');
    myHeaders.append('Pragma', 'no-cache');
    myHeaders.append('Expires', '0');
   
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
   
    };

    fetch(
      BaseUrl+"/customer?tax_id="+obj,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
        console.log("error");
      });
  });
}





